import React, { Component } from 'react';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';
import { Link } from 'react-router-dom';

class BusinessCard extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const businessList = this.props.businessList || [];
        const userId = this.props.userId || '';
        const chatList = this.props.chatList || [];
        if (businessList.length > 0) {
            return businessList.filter(business => { return userId !== business.userId._id }).map((business, index) => {
                let shortName = business.businessName.charAt(0);
                let newchatListId = AllFunction.getChatListId(chatList, business.userId._id);
                return (
                    <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xxl-3 col-12">
                        {/* <div className="card card-custom gutter-b card-stretch BusinessCard">
                            <div className="card-body text-center pt-4">
                                <div className="mt-2 pb-2">
                                    {
                                        business.companyLogo ?
                                            <div className="symbol symbol-lg-75">
                                                <img src={Constants.CompanyLogoUrl + business.companyLogo} alt="image" />
                                            </div>
                                            :
                                            <div className="symbol symbol-lg-75 symbol-circle symbol-light-success">
                                                <span className="font-size-h3 symbol-label font-weight-boldest">{shortName}</span>
                                            </div>
                                    }
                                </div>
                                <div className="my-2">
                                    <Link to={'/Company/' + business._id} className="Usercard-header__name text-hover-primary">{business.businessName}</Link>
                                </div>
                                <div className="Usercard-header__job" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{business.city}{business.state ? ',' + business.state : ''}{business.country ? ',' + business.country : ''}</div>
                                <div className="mt-9 mb-6 text-left" style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', height: 40, textOverflow: 'ellipsis' }}>
                                    <span className="text-dark font-weight-bolder mr-1">Services:</span>
                                    <span data-toggle="tooltip" title={business.services.join(",")}>{business.services.join(",")}</span>
                                </div>
                                {
                                    userId == business.userId._id ? <Link to={'/Profile?tab=Business'} className="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block">Profile</Link> :
                                        AllFunction.includesMultiDimension(chatList, business.userId._id) ?
                                            <Link to={'/Chat?receiverId=' + business.userId._id + '&Name=' + business.userId.firstName} className="businessBtn">Message</Link>
                                            :
                                            <Link to={'/Chat?receiverId=' + business.userId._id + '&Name=' + business.userId.firstName} className="businessBtn">Connect</Link>
                                }
                            </div>
                        </div> */}

                        <div className="BusinessCard col-md-12 pl-0 pr-0">
                            <div className="banner-img" style={{ backgroundImage: 'url("/assets/media/bg/bg-10.jpg")', backgroundSize: "cover" }}></div>
                            {
                                business.companyLogo ?
                                    <div className="symbol symbol-lg-100 symbol-circle symbol-100 bg-light" style={{ marginTop: '-47px' }}>
                                        <img src={Constants.CompanyLogoUrl + business.companyLogo} alt="image" />
                                    </div>
                                    :
                                    <div className="symbol symbol-lg-100 symbol-circle symbol-100 bg-light" style={{ marginTop: '-55px' }}>
                                        <span className="font-size-h3 symbol-label font-weight-boldest">{shortName}</span>
                                    </div>
                            }

                            <Link to={'/Company/' + business._id} className="Usercard-header__name text-hover-primary mt-2"style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', paddingLeft: 20, paddingRight: 20, }} >{business.businessName}</Link>
                            <div className="Usercard-header__job" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{business.city}{business.state ? ',' + business.state : ''}{business.country ? ',' + business.country : ''}</div>
                            <div className="Business__description">
                                <div className="mt-9 mb-6 text-left" style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', height: 40, textOverflow: 'ellipsis' }}>
                                    <span className="text-dark font-weight-bolder mr-1">Services:</span>
                                    <span data-toggle="tooltip" title={business.services.join(",")}>{business.services.join(",")}</span>
                                </div>
                            </div>
                            {
                                userId == business.userId._id ? <Link to={'/Profile?tab=Business'} className="Business__btn">Profile</Link> :
                                    AllFunction.includesMultiDimension(chatList, business._id) ?
                                        <Link to={'/Chat?receiverId=' + business.userId._id + '&Name=' + business.businessName + '&type=BusinessChat' + '&businessId=' + business._id + '&chatListId=' + newchatListId} className=" btn Business__btn">Message</Link>
                                        :
                                        <Link to={'/Chat?receiverId=' + business.userId._id + '&Name=' + business.businessName + '&type=BusinessChat' + '&businessId=' + business._id + '&chatListId=' + newchatListId} className="btn Business__btn">Connect</Link>
                            }
                            {/* <button className="Business__btn">Follow</button> */}
                        </div>

                    </div>
                )
            })
        }
        else {
            return <div></div>
        }
    }
}

export default BusinessCard;