import React, { Component } from 'react';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';
import { Link } from 'react-router-dom';

class UserProfileCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: ''
        }
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
    }

    getUserCredintials = async () => {
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            const userId = user._id;
            await this.setState({ userId })
        }
    }

    render() {
        const userList = this.props.userList || [];
        const chatList = this.props.chatList || [];
        const connectionList = this.props.connectionList || [];

        if (userList.length > 0) {
            return userList.map((user, index) => {
                let connectionStatus = AllFunction.includesMultiDimension(connectionList, user._id) ? AllFunction.getConnectionStatus(connectionList, user._id) : 'connect';
                let connectionObj = connectionStatus != "connect" && AllFunction.getConnectionObj(connectionList, user?._id);
                let newchatListId = AllFunction.getChatListId(chatList, user._id);
                // console.log(newchatListId, user.firstName)
                return (
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xxl-3 col-12 pb-2" key={index}>
                        <div className="Usercard-item col-md-12 pl-0 pr-0">
                            <div className="Usercard-header">
                                <div className="pt-4 pb-4 text-center mb-6" style={{ backgroundImage: 'url("/assets/media/bg/bg-10.jpg")', backgroundSize: "cover" }}>
                                    {
                                        user.profileImage ?
                                            <div className="symbol symbol-lg-90 symbol-circle symbol-80">
                                                <img src={Constants.ProfileImageUrl + user.profileImage} alt="image" />
                                            </div>
                                            :
                                            <div className="symbol symbol-lg-90 symbol-circle symbol-90">
                                                <img src={"/assets/media/users/default.jpg"} alt="image" />
                                            </div>
                                    }
                                </div>
                                <div className="Usercard-header__text my-2 pl-4 pr-4">
                                    <Link to={'/User/' + user._id} className="Usercard-header__name">
                                        {
                                            !user.firstName && !user.lastName ? <span>&nbsp;</span> : user.firstName +' '+user.lastName
                                        }
                                        
                                    </Link>
                                    <div className="Usercard-header__job" style={{ minHeight : 25, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                        {
                                            !user.occupationType ? <span>&nbsp;</span> :
                                            user.occupationType === 'employee' ?
                                                <span>{user.company ? user.company.designation + ' at ' + user.company.companyName : ''}</span>
                                                :
                                                user.occupationType === 'student' ? <span>{user.occupationType + ' at ' + user.collegeName}</span> :
                                                    user.occupationType === 'business' ?
                                                    user.businesses.length <= 0 ? <span>&nbsp;</span> :
                                                        user.businesses.map((business, indx) => {
                                                            return (<span key={indx}>{business.businessName ?? ''}</span>)
                                                        })
                                                        : <span>{user.otherType}</span>
                                        }
                                    </div>
                                    <span className="Usercard-header__job"  style={{ minHeight : 25, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                        <span>{user.city}{user.state ? ',' + user.state : ''}{user.country ? ',' + user.country : ''}</span>
                                    </span>
                                </div>
                            </div>
                            <ul className="Usercard-detail pl-4 pr-4">
                                <li className="Usercard-detail__li">
                                    <p className="Usercard-detail__txt mt-3 mb-3">
                                        <span className="Usercard-detail__icon post">
                                            <i className="fas fa-clone"></i>
                                        </span>
                                        Post
                                    </p>
                                    <p className="Usercard-detail__str mt-3 mb-3">{user.posts.length}</p>
                                </li>
                                <li className="Usercard-detail__li">
                                    <p className="Usercard-detail__txt mt-3 mb-3">
                                        <span className="Usercard-detail__icon follow">
                                            <i className="fas fa-user-plus"></i>
                                        </span>
                                        Connections
                                    </p>
                                    <p className="Usercard-detail__str mt-3 mb-3">{user.allconections.length}</p>
                                </li>

                                {/* {
                                    AllFunction.includesMultiDimension(connectionList, user._id) ?
                                        AllFunction.CheckConnectionStatus(connectionList, user._id).length > 0 ?
                                            <li className="Usercard-detail__li" style={{ background: '#1288b8', opacity: "0.7" }}>
                                                <button className="btn btn-text-light font-weight-bold mr-3 btn-block" style={{ cursor: 'not-allowed' }} disabled>
                                                    <i className="fas fa-hourglass-half" style={{ color: "#fff" }}></i> Pending
                                                </button>
                                            </li>
                                            :
                                            <li className="Usercard-detail__li" style={{ background: '#1288b8' }}>
                                                <Link to={'/Chat?receiverId=' + user._id + '&Name=' + user.firstName} className="btn btn-text-light font-weight-bold mr-3 btn-block">
                                                    <i className="fas fa-comments" style={{ color: "#fff" }}></i> Message
                                                </Link>
                                            </li>
                                        :
                                        <li className="Usercard-detail__li" style={{ background: '#1288b8' }}>
                                            <button className="btn btn-text-light font-weight-bold mr-3 btn-block" onClick={() => { this.props.onConnectUser && this.props.onConnectUser(user._id) }}>
                                                <i className="fas fa-comments" style={{ color: "#fff" }}></i> Connect
                                            </button>
                                        </li>
                                } */}

                                {
                                    connectionStatus !== "connect" ?
                                        connectionStatus == "Pending" ?
                                            connectionObj?.conectUserId?._id == this.state.userId ?
                                                <li className="Usercard-detail__li" style={{ background: '#1288b8' }}>
                                                    <Link to={'/InvitationManager'} className="btn btn-text-light font-weight-bold mr-3 btn-block">
                                                        <i className="fas fa-comments" style={{ color: "#fff" }}></i> Manage Request
                                                    </Link>
                                                </li>
                                                :
                                                <li className="Usercard-detail__li" style={{ background: '#1288b8', opacity: "0.7" }}>
                                                    <button className="btn btn-text-light font-weight-bold mr-3 btn-block" style={{ cursor: 'not-allowed' }} disabled>
                                                        <i className="fas fa-hourglass-half" style={{ color: "#fff" }}></i> Pending
                                                    </button>
                                                </li>
                                            :
                                            <li className="Usercard-detail__li" style={{ background: '#1288b8' }}>
                                                <Link to={'/Chat?receiverId=' + user._id + '&Name=' + user.firstName + '&chatListId=' + newchatListId} className="btn btn-text-light font-weight-bold mr-3 btn-block">
                                                    <i className="fas fa-comments" style={{ color: "#fff" }}></i> Message
                                                </Link>
                                            </li>
                                        :
                                        <li className="Usercard-detail__li" style={{ background: '#1288b8' }}>
                                            <button className="btn btn-text-light font-weight-bold mr-3 btn-block" onClick={() => { this.props.onConnectUser && this.props.onConnectUser(user._id) }}>
                                                <i className="fas fa-comments" style={{ color: "#fff" }}></i> Connect
                                            </button>
                                        </li>
                                }

                            </ul>
                        </div>
                    </div>
                )
            })
        }
        else {
            return (
                <>
                    <div></div>
                </>
            );
        }

    }
}

export default UserProfileCard;