import React, { Component } from 'react';
import Store from './store';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import UserProfileCard from '../Components/UserProfileCard';
import PaginationFunction from '../Components/Pagination';
import AllFunction from '../Utilities/Functions';
class MyNetwork extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            listLoading: false,
            userId: '',
            connectionList: [],
            errors: {},
            invitationList: [],
            allInvitationList: [],
            chatList: [],
            alert: false,
            conectId: '',
            success: false,
            successMsg: '',
            allconnectionList: [],
            users: [],
            listCount: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 5,
            currentPage: 1,
            listPerPage: 12,
            upperPageBound: 5,
            lowerPageBound: 0,
        }
        this.store = Store;
    }
    componentDidMount = async () => {
        await this.store.setSubHeader("My Network", ["My Network", "Manage Network"])
        await this.getUserCredintials();
    }

    /** Open sweet Alert */
    onConfirmAlert = async (conectId) => {
        // console.log(conectId)
        await this.setState({ alert: true, conectId: conectId });
    }

    /** Get Token and User Details from local storage */
    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            const userId = user._id;
            await this.setState({ userId })
        }
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            await this.getChatList();
            await this.getPendingConnectionList();
            // await this.getAllPendingConnectionList();
            await this.getData();
            await this.getConnectionList();
        }
    }

    /** Get Chatlist */
    getChatList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'chat/chatList',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        chatList: responseData.ChatList
                    });
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     * This function get All Pending Connection List
     */
    getPendingConnectionList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'connection/pending',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        invitationList: responseData.connections
                    });
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     * 
     * @param {*} conectId 
     * @param {*} conectionStatus "Accepted or Rejected"
     * @description This function accept or reject connection request
     */
    onChangeConnectionStatus = (conectId, conectionStatus) => {
        this.setState({ loading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);
        let errors = {};
        let body = JSON.stringify({
            id: conectId,
            status: conectionStatus
        })
        fetch(Constants.url + 'connection/changeStatus',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    let list = this.state.invitationList.filter(conect => conect._id != conectId)
                    this.setState({
                        loading: false,
                        invitationList: list
                    });
                    this.getData();
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    /**
     * Get All Accepted Connected users
     */
    getData = () => {
        this.setState({ listLoading: true })

        let errors = {};
        let urlpath = `connection/accepted?page=${this.state.currentPage}&limit=${this.state.listPerPage}`;
        fetch(Constants.url + urlpath,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        connectionList: responseData.connections,
                        listCount: responseData.count
                    });
                    if (responseData.connections.length <= 0) {
                        this.getUserList();
                    }
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    // This function remove connection from request
    onRemoveConnection = () => {
        this.setState({ loading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);
        let errors = {};
        let body = JSON.stringify({
            id: this.state.conectId,
        })
        // console.log(body)
        fetch(Constants.url + 'connection/remove',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    let list = this.state.connectionList.filter(conect => conect._id != this.state.conectId)
                    this.setState({
                        loading: false,
                        connectionList: list
                    });
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                    this.setState({ connectionList: list, alert: false, success: true, successMsg: 'Connection removed successfully.' });
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    /**
     * Hide Success Alert
     */
    hideSuccessAlert = async () => {
        await this.setState({ success: false, successMsg: '' });
    }

    /**
     * Get not connected users same as dashboard User
     */
    getUserList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + '/dashboardUser',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        users: responseData.users,
                        dataLoading: false,
                    });
                    setTimeout(() => {
                        window.loadPopover()
                    }, 300);
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                    // console.log(this.state.users)
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     * 
     * @param {*} conectUserId 
     * @description This function send conction request to selected user
     */
    sendConnectionRequest = (conectUserId) => {
        this.setState({ loading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);

        let errors = {};
        let body = JSON.stringify({
            conectUserId: conectUserId,
        });

        fetch(Constants.url + 'connect',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.getConnectionList();
                    this.getUserList();
                    this.setState({ loading: false })
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    /**
     * get All connceted Users
     */
    getConnectionList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'connection',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        allconnectionList: responseData.connections
                    });
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
       * pagination handle click
       * @param {event} event 
       */
    handleClick = async (event) => {
        let listid = Number(event.target.id);
        // console.log(listid)
        await this.setState({
            currentPage: listid
        });
        await this.getData();
        PaginationFunction.setPrevAndNextBtnClass(listid, this);
    }

    render() {
        const { listCount, listPerPage } = this.state;
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        {
                            this.state.invitationList.length > 0 &&
                            <div className="card card-custom gutter-b mb-8">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label">
                                            Invitations
                                        </h3>
                                    </div>

                                    <div className="card-toolbar">
                                        <Link to={'/InvitationManager'} className="btn btn-sm btn-success font-weight-bold">
                                            <i className="flaticon2-list-3"></i> See All
                                        </Link>
                                    </div>

                                </div>
                                <div className="card-body">
                                    {
                                        this.state.listLoading ? '' :
                                            this.state.invitationList.map((conect, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0 mr-5">
                                                                <div className="symbol symbol-85 symbol-circle">
                                                                    {
                                                                        conect.userId.profileImage ?
                                                                            <img alt="Pic" src={Constants.ProfileImageUrl + conect.userId.profileImage} />
                                                                            :
                                                                            <img alt="Pic" src="assets/media/users/default.jpg" />
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 mt-2">
                                                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                    <div className="mr-3">
                                                                        <div className="d-flex align-items-center mr-3">
                                                                            <span className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">{conect.userId.firstName} {conect.userId.lastName}</span>
                                                                        </div>
                                                                        <div className="d-flex flex-wrap my-2">
                                                                            <div className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                                            <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                                            <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>{conect.userId.email}
                                                                            </div>
                                                                            <div className="text-muted font-weight-bold">
                                                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                                            <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>{conect.userId.city} {conect.userId.state ? ', ' + conect.userId.state : ''} - {conect.userId.country}
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                                                                            <div className="flex-grow-1 font-weight-bold text-dark-50 mr-5">
                                                                                {
                                                                                    conect.userId.occupationType === 'employee' ?
                                                                                        <span>{conect.userId.company ? conect.userId.company.designation + ' at ' + conect.userId.company.companyName : ''}</span>
                                                                                        :
                                                                                        conect.userId.occupationType === 'student' ? <span>{conect.userId.occupationType + ' at ' + conect.userId.collegeName}</span> :
                                                                                            conect.userId.occupationType === 'business' ? <span>{conect.userId.occupationType}</span> : ''

                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="mb-0">
                                                                        {
                                                                            this.state.loading ?
                                                                                <button className="btn btn-light-danger mr-2 font-weight-bolder" disabled>
                                                                                    Reject
                                                                                </button>
                                                                                :
                                                                                <button className="btn btn-light-danger mr-2 font-weight-bolder" onClick={() => { this.onChangeConnectionStatus(conect._id, 'Rejected') }}>
                                                                                    Reject
                                                                                </button>
                                                                        }

                                                                        {
                                                                            this.state.loading ?
                                                                                <button className="btn btn-light-success font-weight-bolder" disabled>
                                                                                    Accept
                                                                                </button>
                                                                                :
                                                                                <button className="btn btn-light-success font-weight-bolder" onClick={() => { this.onChangeConnectionStatus(conect._id, 'Accepted') }}>
                                                                                    Accept
                                                                                </button>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            (this.state.invitationList.length - 1 !== index) &&
                                                            <div className="separator separator-solid mt-5 mb-5"></div>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                    }

                                </div>
                            </div>
                        }

                        {
                            this.state.connectionList.length <= 0 && this.state.invitationList.length <= 0 &&
                            <div className="mb-2 card card-custom overflow-hidden position-relative ">
                                <div className="card-body">
                                    <h3 className="text-center">No Connections</h3>
                                    <div className="card-body" style={{ height: 665, backgroundImage: 'url("assets/EmptyScreen/Network.png")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 450, objectFit: 'contain' }}></div>
                                </div>
                            </div>

                        }
                        <div className="card card-custom overflow-hidden position-relative ">
                            {
                                this.state.connectionList.length <= 0 ?
                                    this.state.dataLoading ? '' : this.state.users.length > 0 && <div className="d-flex justify-content-between align-items-center mb-0 card-header">
                                        <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Grow Your Network</h2>
                                        <div><Link to={'/Search'} className="btn btn-light-primary btn-sm font-weight-bolder">View All</Link>
                                            {
                                                this.state.invitationList.length <= 0 &&
                                                <Link to={'/InvitationManager'} className="btn btn-light-primary btn-sm font-weight-bolder ml-5">Manage Invitation</Link>
                                            }
                                        </div>
                                    </div> :
                                    <div className="d-flex justify-content-between align-items-center mb-7 card-header">
                                        <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">My Connections</h2>
                                        {
                                            this.state.invitationList.length <= 0 &&
                                            <Link to={'/InvitationManager'} className="btn btn-light-primary btn-sm font-weight-bolder">Manage Invitation</Link>
                                        }
                                    </div>
                            }
                            <div className="card-body">

                                {
                                    this.state.connectionList.length <= 0 ?
                                        this.state.dataLoading ? '' : this.state.users.length > 0 &&
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {
                                                        this.state.dataLoading ? '' :
                                                            <UserProfileCard userList={this.state.users} connectionList={this.state.allconnectionList} onConnectUser={(id) => { this.sendConnectionRequest(id) }} chatList={this.state.chatList} />
                                                    }
                                                </div>
                                            </div>
                                        :
                                        <div className="row">
                                            {
                                                this.state.connectionList.map((conection, index) => {

                                                    let conect = '';
                                                    if (conection.userId._id === this.state.userId) {
                                                        conect = conection.conectUserId
                                                    } else {
                                                        conect = conection.userId;
                                                    }
                                                    let shortName = conect.firstName.charAt(0);
                                                    shortName += conect.lastName ? conect.lastName.charAt(0) : '';

                                                    let newchatListId = AllFunction.getChatListId(this.state.chatList, conect._id);
                                                    
                                                    return (
                                                        <div key={index} className="col-xl-4 col-xxl-3 col-lg-4 col-md-6 col-sm-6">
                                                            <div className="card card-custom gutter-b card-stretch shadow-sm">
                                                                <div className="card-body text-center pt-4">
                                                                    <div className="mt-2 pb-2">
                                                                        {
                                                                            conect.profileImage ?
                                                                                <div className="symbol symbol-lg-75 symbol-circle">
                                                                                    <img src={Constants.ProfileImageUrl + conect.profileImage} alt="image" />
                                                                                </div>
                                                                                :
                                                                                <div className="symbol symbol-lg-75 symbol-circle symbol-light-success">
                                                                                    <span className="font-size-h3 symbol-label font-weight-boldest">{shortName}</span>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    <div className="my-2">
                                                                        <span className="text-dark font-weight-bold font-size-h4">{conect.firstName} {conect.lastName}</span>
                                                                    </div>
                                                                    <div className="text-muted" style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', height: 40, textOverflow: 'ellipsis' }}>
                                                                        {
                                                                            conect.occupationType === 'employee' ?
                                                                                <span>{conect.company ? conect.company.designation + ' at ' + conect.company.companyName : ''}</span>
                                                                                :
                                                                                conect.occupationType === 'student' ? <span>{conect.occupationType + ' at ' + conect.collegeName}</span> :
                                                                                    conect.occupationType === 'business' ? <span>{conect.occupationType}</span> : ''

                                                                        }
                                                                    </div>
                                                                    <div className="text-muted" >
                                                                        <span>{conect.city}{conect.state ? ',' + conect.state : ''}{conect.country ? ',' + conect.country : ''}</span>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            <div className="d-flex justify-content-between">
                                                                                <Link to={'/Chat?receiverId=' + conect._id + '&Name=' + conect.firstName + '&chatListId=' + newchatListId} className="btn btn-light-success mt-5"><i className="flaticon2-chat-1"></i> Message</Link>
                                                                                <button className="btn btn-light-danger mt-5" onClick={() => this.onConfirmAlert(conection._id)}><i className="flaticon2-trash"></i> Remove</button>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                                {
                                    listCount > listPerPage &&
                                    <div className="row justify-content-end">
                                        {PaginationFunction.pagination("renderPrevBtn", this)}
                                        {PaginationFunction.pagination("pageDecrementBtn", this)}
                                        {PaginationFunction.pagination("renderPageNumbers", this)}
                                        {PaginationFunction.pagination("pageIncrementBtn", this)}
                                        {PaginationFunction.pagination("renderNextBtn", this)}
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.alert &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        title="Are you sure?"
                        onConfirm={() => this.onRemoveConnection()}
                        onCancel={() => this.setState({ alert: false })}
                        focusCancelBtn
                    >
                        You want to remove connection
                    </SweetAlert>
                }
                {
                    this.state.success &&
                    <SweetAlert success title='' onConfirm={() => this.hideSuccessAlert()} onCancel={() => this.setState({ success: false })}>
                        {this.state.successMsg}
                    </SweetAlert>
                }

            </>
        );
    }
}

export default MyNetwork;