import React, { Component } from 'react';
import Keycloak from 'keycloak-js';
import Constants from '../Utilities/Constants';

class Secured extends Component {

  constructor(props) {
    super(props);
    this.state = { keycloak: null, authenticated: false };
  }

  componentDidMount() {
    const keycloak = Keycloak('./keycloak.json');
    keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
      console.log("kc: authenticated", authenticated)
      this.setState({ keycloak: keycloak, authenticated: authenticated })
      if (authenticated) {
        window.accessToken = keycloak.token;
        // window.location.href = '/';
        keycloak.loadUserInfo()
          .then((profile) => {
            console.log("prof: ",profile)
            this._verifyUser(profile)
          })
          .catch(function (e) {
            console.log('Failed to load user profile: ', e);
          });
      }
      else {
        // keycloak.init({ onLoad: 'login-required' })
      }
    })
  }

  _verifyUser = (userInfo) => {

    let body = JSON.stringify({
      email: userInfo.email
    });

    console.log("body: ", body);

    fetch(Constants.url + "verify/keycloak-user",
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("res: ", responseData)
        if (responseData?.status) {
          console.log("log: ", responseData)
          this.callBack(responseData)
          // window.location.href = '/';
        } else {
          console.log("Error")
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  callBack = async (res) => {
    try {

      if (res.status) {
        if (res.body === "SignUp") {
          await this.setState({
            tab: 'YourSelf',
            email: res.datas.email,
            name: res.datas.firstName,
            lastName: res.datas.lastName,
            withGoogle: true
          })
        } else {
          await localStorage.setItem('user', JSON.stringify(res.body.user));
          await localStorage.setItem('token', "Bearer " + res.body.token);
          await this.setState({ loginLoading: false });
          console.log(" user:  ", res.body);
          window.location = '/';
        }
      }
    }
    catch (e) {
      console.log("callback err: ", e);
    }
  }


  render() {
    if (this.state.keycloak) {
      if (this.state.authenticated) return (

        <div>
          <p>Loading...</p>
        </div>
      ); else return (<div>Unable to authenticate!</div>)
    }
    return (
      <div>Initializing Keycloak...</div>
    );
  }
}
export default Secured;
