import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import { ReactSVG } from 'react-svg'
class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: '',
			User: '',
			firstChar: '',
			selectedMenu: '/',
			current_url: '',
			businesses: [],
			redirect: false,
			search: '',
			unSeenChatList: []
		};

	}

	getUserCredintials = async () => {

		let token = localStorage.getItem("token");
		let user = localStorage.getItem("user");
		let User = JSON.parse(user);
		if (!User) {
			return Auth.Logout();
		} else {
			await this.setState({ token, User });
			let firstChar = this.state.User.firstName.charAt(0);
			await this.setState({ firstChar, loading: false })
			await this.getUserDetail()
		}
	}

	getUserDetail = async () => {
		this.setState({ dataLoading: true })
		let errors = {};
		fetch(Constants.url + 'user/' + this.state.User._id,
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': this.state.token
				},

			}).then((response) => response.json())
			.then((responseData) => {
				if (responseData.status) {
					this.setState({
						dataLoading: false, user: responseData.users,
						businesses: responseData.businesses,
						unSeenChatList: responseData.unSeenChatList
					});

				}else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
				else {
					errors.error = responseData.error;
					this.setState({ errors: errors, dataLoading: false })
				}
			})
			.catch(err => {
				this.setState({ dataLoading: false })
				errors.error = "Something went wrong, Please try again after sometime.";
				this.setState({ errors })
				console.log(err);
			});
	}

	async componentDidMount() {
		await this.getUserCredintials();
		// let current_url = window.location.hash.replace("/", "");
		let pathNames = window.location.pathname.split('/') || [];
    	let current_url = pathNames.length > 0 ? pathNames[1] : '';
		await this.setState({ selectedMenu: current_url ? current_url : '/', current_url })
	}

	onChangeMenu = async (tab) => {
		return this.setState({ selectedMenu: tab, current_url: '' });
	}

	async UNSAFE_componentWillReceiveProps(nextProps) {
		// let current_url = window.location.hash.replace("/", "");
		let pathNames = window.location.pathname.split('/') || [];
    	let current_url = pathNames.length > 0 ? pathNames[1] : '';
		
		this.setState({ selectedMenu: current_url ? current_url : '/', current_url })
		await this.getUserCredintials();
	}


	onSearchChange = async (event) => {
		await this.setState({ search: event.target.value, redirect: true })
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			return (<Redirect to={'/search/' + '?search=' + this.state.search} />
			)
		}
	}

	render() {
		return (
			<>
				{this.renderRedirect()}
				<div id="kt_header" className="header header-fixed">
					<div className="container d-flex align-items-stretch justify-content-between">
						<div className="d-flex align-items-stretch mr-3">
							<div className="header-logo">
								<Link to={'/'}>
									<img alt="Logo" src={"/assets/logo/Logo-white.png"} className="logo-default max-h-40px" />
									<img alt="Logo" src="/assets/logo/Logo.png" className="logo-sticky max-h-30px" />
									{/* <ReactSVG src={"assets/logo/Logo.svg"} className="logo-sticky max-h-40px" /> */}
								</Link>
							</div>
							<div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
								<div id="kt_header_menu" className="header-menu header-menu-left header-menu-mobile header-menu-layout-default">
									<ul className="menu-nav">
										<li className={`menu-item menu-item-submenu menu-item-rel ${this.state.selectedMenu === '/' ? 'menu-item-here' : ''}`}>
											<Link to={'/'} className="menu-link" onClick={() => { this.onChangeMenu('/') }}>
												<span className="menu-text">Home</span>
												<i className="menu-arrow"></i>
											</Link>
										</li>
										{/* <li className={`menu-item menu-item-submenu menu-item-rel ${this.state.selectedMenu === 'Services' ? 'menu-item-here' : ''}`}>
											<Link to={'/Services'} className="menu-link" onClick={() => { this.onChangeMenu('Services') }}>
												<span className="menu-text">Services</span>
												<i className="menu-arrow"></i>
											</Link>
										</li> */}
										<li className={`menu-item menu-item-submenu menu-item-rel ${this.state.selectedMenu === 'Chat' ? 'menu-item-here' : ''}`}>
											<Link to={'/Chat'} className="menu-link" onClick={() => { this.onChangeMenu('Chat') }}>
												<span className="menu-text">Chat</span>
												{this.state.unSeenChatList.length > 0 && <span className="label label-danger ml-2">{this.state.unSeenChatList.length}</span>}
												<i className="menu-arrow"></i>
											</Link>
										</li>
										<li className={`menu-item menu-item-submenu menu-item-rel ${['InvitationManager', 'MyNetwork'].includes(this.state.selectedMenu) ? 'menu-item-here' : ''}`}>
											<Link to={'/MyNetwork'} className="menu-link" onClick={() => { this.onChangeMenu('MyNetwork') }}>
												<span className="menu-text">My Network</span>
												<i className="menu-arrow"></i>
											</Link>
										</li>
										<li className={`menu-item menu-item-submenu menu-item-rel ${this.state.selectedMenu === 'Posts' ? 'menu-item-here' : ''}`}>
											<Link to={'/Posts'} className="menu-link" onClick={() => { this.onChangeMenu('Posts') }}>
												<span className="menu-text">Post</span>
												<i className="menu-arrow"></i>
											</Link>
										</li>
										<li className={`menu-item menu-item-submenu menu-item-rel  ${['JobArticles', 'Job'].includes(this.state.selectedMenu) ? 'menu-item-here' : ''}`}>
											<Link to={'/JobArticles'} className="menu-link" onClick={() => { this.onChangeMenu('JobArticles') }}>
												<span className="menu-text">Job Posting</span>
												<i className="menu-arrow"></i>
											</Link>
										</li>

									</ul>
								</div>
							</div>
						</div>

						<div className="topbar align-items-center">
							<div className="dropdown dropdown-inline align-items-center">
								<a href="#" className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-1">Hi,</span>
									<span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">{this.state.User.firstName}</span>
									<span className="symbol symbol-30 ml-2">
										{
											this.state.User.profileImage ?
												<img alt="Pic" src={Constants.ProfileImageUrl + this.state.User.profileImage} />
												:
												<img alt="Pic" src="/assets/media/users/default.jpg" />
										}
									</span>
								</a>
								<div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
									<ul className="navi navi-hover">
										<li className="navi-header font-weight-bold py-4">
											<Link to={'/Profile'} className="d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<div className="symbol symbol-success mr-2">
													{
														this.state.User.profileImage ?
															<img alt="Pic" src={Constants.ProfileImageUrl + this.state.User.profileImage} />
															:
															<img alt="Pic" src="/assets/media/users/default.jpg" />
													}

												</div>
												<div className="d-flex flex-column text-left">
													<span className="text-dark-75 font-weight-bold">{this.state.User.firstName} {this.state.User.lastName}</span>
													{
														this.state.User.company &&
														<span className="text-dark-75 font-weight-bold">{this.state.User.company?.designation} at {this.state.User.company?.companyName}</span>
													}
												</div>
											</Link>
										</li>
										<li className="navi-separator mb-1 opacity-70"></li>
										<li className="navi-item">
											<div className="navi-link pt-0 pb-0">
												<span className="navi-text">
													<Link to={'/Profile'} className="btn btn-clean font-weight-bold btn-sm d-flex align-items-center">
														<i className="fas fa-user"></i>
														<span className="ml-2">View Profile</span>
													</Link>
												</span>
											</div>
										</li>
										{
											this.state.dataLoading ? '' : this.state.businesses.length > 0 &&
												<li className="navi-item">
													<div className="navi-link pt-0 pb-0">
														<span className="navi-text">
															<Link to={'/CreatePost'} className="btn btn-clean font-weight-bold btn-sm d-flex align-items-center">
																<i className="fas fa-plus-circle"></i>
																<span className="ml-2">Create Post</span>
															</Link>
														</span>
													</div>
												</li>
										}
										{
											this.state.dataLoading ? '' : this.state.businesses.length > 0 &&
												<li className="navi-item">
													<div className="navi-link pt-0 pb-0">
														<span className="navi-text">
															<Link to={'/CreateJob'} className="btn btn-clean font-weight-bold btn-sm d-flex align-items-center">
																<i className="fas fa-plus-circle"></i>
																<span className="ml-2">Create Job</span>
															</Link>
														</span>
													</div>
												</li>
										}
										<li className="navi-item">
											<div className="navi-link pt-0 pb-0">
												<span className="navi-text">
													<Link to={'/CreateCompany'} className="btn btn-clean font-weight-bold btn-sm d-flex align-items-center">
														<i className="fas fa-plus-circle"></i>
														<span className="ml-2">Add Business</span>
													</Link>
												</span>
											</div>
										</li>

										<li className="navi-item">
											<div className="navi-link pt-0 pb-0">
												<span className="navi-text">
													<Link to={'/FeedBack'} className="btn btn-clean font-weight-bold btn-sm d-flex align-items-center">
														<i className="fas fa-comments"></i>
														<span className="ml-2">FeedBack</span>
													</Link>
												</span>
											</div>
										</li>

										<li className="navi-separator mt-1 opacity-70"></li>
										<li className="navi-footer py-4">
											<button className="btn btn-clean font-weight-bold btn-sm" onClick={() => { Auth.Logout() }}>
												<i className="fas fa-sign-out-alt"></i>Sign Out
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default Header;