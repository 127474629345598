import React, { Component } from 'react';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import store from './store';
import { Link } from 'react-router-dom';
import AllFunction from '../Utilities/Functions';
import JobCard from '../Components/JobCard';
import Autocomplete from "react-google-autocomplete";
import LoadMoreBtn from '../Components/LoadMoreBtn';
class JobList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: '',
            postList: [],
            loading: false,
            userId: '',
            listLoading: false,
            chatList: [],
            appList: [],
            searchName: '',
            category: 'Job_Opening',
            currentPage: 1,
            listPerPage: 20,
            isMoreData: false,
            domain: '',
            domainList: [],
            city: '',
            country: '',
            state: '',
            street_address: ''
        }
        this.store = store;
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
        await this.store.setSubHeader("Jobs", ["Job Articles"])
    }

    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            const userId = user._id;
            await this.setState({ userId })
        }
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            await this.getData();
            await this.getChatList();
            await this.getJobAppList();
            await this.getDomainList();
        }
    }

    getDomainList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'dropdown/Domain',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                //console.log(responseData)
                if (responseData.status) {
                    this.setState({ dataLoading: false, domainList: responseData.dropdown.options });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getChatList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'chat/chatList',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        chatList: responseData.ChatList
                    });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getJobAppList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'jobApplications',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData, "Applications")
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        appList: responseData.JobApps
                    });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getData = () => {
        this.setState({ loading: true })
        let errors = {};
        let domain = this.state.domain
        let urlpath = `job?page=${this.state.currentPage}&limit=${this.state.listPerPage}&name=${this.state.searchName}&domain=${domain}&country=${this.state.country}&city=${this.state.city}&state=${this.state.state}`;
        fetch(Constants.url + urlpath,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    let isMoreData = (!!responseData.jobs && responseData.jobs.length > (this.state.listPerPage - 1))
                    const psList = this.state.postList?.concat(responseData.jobs || []) || [];
                    this.setState({ postList: psList, isMoreData });
                    this.setState({
                        loading: false,
                    });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onChangeCountry = async (event) => {
        await this.setState({ country: event.target.value, cityList: [], city: '' });
        await this.getCityList();
    }

    getAddressComponentData = (place) => {
        this.setState({ country: '', state: '', city: '' })
        let address_components = place.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name })
            }
        })

        this.setState({ street_address: place.formatted_address });
    }

    onChangeAutoComplete = (event) => {
        if (event.target.value === "") {
            this.setState({ country: '', state: '', city: '', street_address: '' })
        }
    }

    render() {

        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom overflow-hidden position-relative mb-8">
                            <div className="mb-0 mt-10 card-header">
                                <div className="col-lg-12 col-xl-12">
                                    <div className="row align-items-center">
                                        <div className="col-md-4 ">
                                            <div className="input-icon">
                                                <input type="text" className="form-control form-control-solid" placeholder="Search..." id="kt_datatable_search_query" name="searchName" onChange={(event) => { AllFunction.onChange(event, this) }} value={this.state.searchName} />
                                                <span>
                                                    <i className="flaticon2-search-1 text-muted"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 ">

                                            <select className="form-control form-control-lg" name="domain" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.domain}>
                                                <option value="">Select Domain</option>
                                                {
                                                    this.state.domainList.map((list, index) => {
                                                        return (
                                                            <option value={list.value} key={index}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                        </div>
                                        {/* <div className="col-lg-3 col-xl-4 mt-5 mt-lg-0">
                                            <button className="btn btn-light-primary px-6 font-weight-bold" onClick={() => { this.getData() }}>Search</button>
                                        </div> */}
                                    </div>
                                    <div className="row align-items-center mt-2 mb-2">
                                        <div className="col-md-4 ">
                                            <Autocomplete
                                                apiKey={Constants.GOOGLE_MAPS_API_KEY}
                                                style={{ width: "100%" }}
                                                onPlaceSelected={(place) => {
                                                    this.getAddressComponentData(place)
                                                }}
                                                options={{
                                                    types: ["(regions)"],
                                                }}
                                                className="form-control form-control-solid h-auto py-5 px-6"
                                                name="street_address"
                                                autoComplete="off"
                                                onChange={(event) => { this.onChangeAutoComplete(event) }}
                                            />
                                        </div>

                                        <div className="col-lg-3 col-xl-4 mt-5 mt-lg-0">
                                            <button className="btn btn-light-primary px-6 font-weight-bold"
                                                onClick={async () => {
                                                    await this.setState({ currentPage: 1, postList: [] })
                                                    await this.getData()
                                                }}>Advance Search</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    {
                                        this.state.dataLoading ? '' :
                                            this.state.postList.length > 0 ?
                                                <JobCard postList={this.state.postList} userId={this.state.userId} chatList={this.state.chatList} appList={this.state.appList} />
                                                : 'No Job Articles'
                                    }
                                </div>
                                <LoadMoreBtn
                                    isMoreData={this.state.isMoreData}
                                    disabled={this.state.loading}
                                    currentPage={this.state.currentPage}
                                    onClick={async () => {
                                        await this.setState({ isLoadMore: true, currentPage: !!this.state.currentPage ? (parseInt(this.state.currentPage) + 1) : 1 });
                                        await this.getData(false, false);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default JobList;