import React, { Component } from 'react';
import AllFunction from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import Axios from 'axios';
import MessageAlert from '../Components/MessageAlert';
import { Link } from 'react-router-dom';

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loading: false,
            errors: {},
            password: '',
            confirmPassword: '',
            resetPasswordToken: '',
            success: false,
            successMsg: ''
        }
    }

    componentDidMount = async () => {
        console.log("Support");
        const params = new URLSearchParams(this.props.location.search);
        const resetPasswordToken = params.get('resetPasswordToken');
        if (resetPasswordToken && resetPasswordToken !== '') {
            await this.setState({ resetPasswordToken: resetPasswordToken })
        }
    }

    passwordValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.password && this.state.password.trim() === '') {
            formIsValid = false;
            errors.password = "Please enter password.";
        } else if (this.state.password.length < 6) {
            formIsValid = false;
            errors.password = "Password must be greater than 6.";
        }

        if (!this.state.confirmPassword && this.state.confirmPassword.trim() === '') {
            formIsValid = false;
            errors.confirmPassword = "Please enter confirm password.";
        }

        if (this.state.confirmPassword != this.state.password) {
            errors.password = 'Password and Confirm password Must Be Same';
            formIsValid = false;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onResetPassword = () => {
        if (this.passwordValidation()) {
            this.setState({ loading: true })
            setTimeout(() => {
                window.blockPage()
            }, 300);

            const body = JSON.stringify({
                password: this.state.password,
                resetPasswordToken: this.state.resetPasswordToken
            })

            let errors = {};
            let method = 'POST';
            let successMsg = 'Password Reset Successfully.';

            Axios({
                method: method,
                url: Constants.url + 'resetpassword',
                data: body,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                console.log(response)
                let responseData = response.data;
                // console.log("Add Business", responseData)
                if (responseData.status) {
                    this.setState({ loading: false, success: true, successMsg: responseData.message || successMsg, password: '', confirmPassword: '' });
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);

                } else {
                    errors.error = responseData.error;
                    console.log(responseData.error)
                    this.setState({ errors: errors, loading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.log(error.response);
                if (!error.response.data.status) {
                    errors.error = error.response.data.error;
                    this.setState({ errors: errors })
                }
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
        }
    }

    render() {
        return (
            <>
                <div id="kt_header_mobile" className="header-mobile header-mobile-fixed" style={{ backgroundColor: '#07a398' }}>
                    {/* <!--begin::Logo--> */}
                    <Link to={'/'}>
                        <img alt="Logo" src="/assets/logo/Logo-white.png" className="max-h-25px" />
                    </Link>
                    {/* <!--end::Logo--> */}                    
                </div>
                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-row flex-column-fluid page">
                        <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                            <div id="kt_header" className="header header-fixed">
                                <div className="container d-flex align-items-stretch justify-content-between">
                                    <div className="d-flex align-items-stretch mr-3">
                                        <div className="header-logo">
                                            <Link to={'/'}>
                                                <img alt="Logo" src="/assets/logo/Logo-white.png" className="logo-default max-h-40px" />
                                                <img alt="Logo" src="/assets/logo/Logo.png" className="logo-sticky max-h-30px" />
                                                {/* <ReactSVG src={"assets/logo/Logo.svg"} className="logo-sticky max-h-40px" /> */}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content d-flex flex-column flex-column-fluid mb-20" id="kt_content">
                                <div className="subheader py-2 py-lg-7 subheader-transparent" id="kt_subheader">
                                    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                        <div className="d-flex align-items-center flex-wrap mr-1">
                                            <div className="d-flex flex-column">
                                                <h2 className="text-white font-weight-bold my-2 mr-5">Reset Password</h2>
                                                <div className="d-flex align-items-center font-weight-bold my-2">
                                                    <a href="#" className="opacity-75 hover-opacity-100">
                                                        <i className="flaticon2-shelter text-white icon-1x"></i>
                                                    </a>
                                                    <span className="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                                                    <span>
                                                        <a href="" className="text-white text-hover-white opacity-75 hover-opacity-100">Reset Password</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column-fluid">
                                    <div className="container">
                                        <div className="card card-custom gutter-b">
                                            <div className="card-header">
                                                <h3 className="card-title">Reset your password</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="col-md-12">
                                                    {
                                                        this.state.success &&
                                                        <MessageAlert children={this.state.successMsg} variant="success" />
                                                    }
                                                    {this.state.errors.error &&
                                                        <MessageAlert children={this.state.errors.error} variant="danger" />
                                                    }
                                                </div>
                                                {
                                                    this.state.resetPasswordToken == '' ?
                                                        <div className={`alert alert-custom alert-otice fade show p-5 shadow-sm alert-light-danger`}>
                                                            <div className="alert-text">Invalid Link</div>                                                            
                                                        </div>
                                                        :
                                                        <div className="form-group row">
                                                            <div className="col-lg-6">
                                                                <label>Password <span className="text-danger">*</span></label>
                                                                <input type="password" className="form-control form-control-lg" name="password" placeholder="Enter Password" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.password} />
                                                                {this.state.errors.password && <span className="form-text text-danger pl-2">{this.state.errors.password}</span>}
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>Confirm Password <span className="text-danger">*</span></label>
                                                                <input type="password" className="form-control form-control-lg" name="confirmPassword" placeholder="Enter Confirm Password" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.confirmPassword} />
                                                                {this.state.errors.confirmPassword && <span className="form-text text-danger pl-2">{this.state.errors.confirmPassword}</span>}
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            {
                                                this.state.resetPasswordToken &&
                                                <div className="card-footer">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            {
                                                                this.state.loading ?
                                                                    <button type="button" className="btn btn-primary mr-2 spinner spinner-white spinner-right">Save</button>

                                                                    :
                                                                    <button type="button" className="btn btn-primary mr-2" onClick={() => { this.onResetPassword() }}>Submit</button>
                                                            }
                                                            <button type="reset" className="btn btn-secondary" onClick={() => { window.location = '/' }}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ResetPassword;