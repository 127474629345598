import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Store from './store';
class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.store = Store;        
    }

    componentDidMount = async () => {
        await this.store.setSubHeader("Services",["Services","Search your services"])
    }

    render() {
        return (
            <>              
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom overflow-hidden position-relative mb-8">
                            <div className="container">
                                <div className="d-flex align-items-stretch text-center flex-column py-10">
                                    {/* <!--begin::Heading--> */}
                                    <h1 className="text-dark font-weight-bolder mb-12">Search your services</h1>
                                    {/* <!--end::Heading--> */}
                                    {/* <!--begin::Form--> */}
                                    <form className="d-flex position-relative w-75 px-lg-40 m-auto">
                                        <div className="input-group shadow-sm">
                                            {/* <!--begin::Icon--> */}
                                            <div className="input-group-prepend">
                                                <span className="input-group-text bg-white border-0 py-7 px-8">
                                                    <span className="svg-icon svg-icon-xl">
                                                        {/* <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg--> */}
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                                            </g>
                                                        </svg>
                                                        {/* <!--end::Svg Icon--> */}
                                                    </span>
                                                </span>
                                            </div>
                                            {/* <!--end::Icon--> */}
                                            {/* <!--begin::Input--> */}
                                            <input type="text" className="form-control h-auto border-0 py-7 px-1 font-size-h6" placeholder="Web Design" />
                                            {/* <!--end::Input--> */}
                                        </div>
                                    </form>
                                    {/* <!--end::Form--> */}
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                        {/* <!--begin::Card--> */}
                                        <div className="card card-custom gutter-b card-stretch shadow-sm">
                                            {/* <!--begin::Body--> */}
                                            <div className="card-body text-center pt-4">
                                                {/* <!--begin::User--> */}
                                                <div className="mt-2 pb-2">
                                                    <div className="symbol symbol-circle symbol-lg-75">
                                                        <img src="assets/media/users/300_1.jpg" alt="image" />
                                                    </div>
                                                    <div className="symbol symbol-lg-75 symbol-circle symbol-light-success d-none">
                                                        <span className="font-size-h3 symbol-label font-weight-boldest">MP</span>
                                                    </div>
                                                </div>
                                                {/* <!--end::User--> */}
                                                {/* <!--begin::Name--> */}
                                                <div className="my-2">
                                                    <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4">Antonio Pastore</a>
                                                </div>
                                                {/* <!--end::Name--> */}
                                                {/* <!--begin::Label--> */}
                                                <div className="text-muted">Application Developer</div>
                                                {/* <!--end::Label--> */}
                                                {/* <!--begin::Buttons--> */}
                                                <div className="mt-9 mb-6 text-left" style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                                                    <span className="text-dark font-weight-bolder mr-1">Services:</span>
                                                    Mobile Application Development, Web Design,Digital Marketing,Logo Design
                                                </div>
                                                <Link to={'/Chat'} className="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block">Connect</Link>
                                                {/* <!--end::Buttons--> */}
                                            </div>
                                            {/* <!--end::Body--> */}
                                        </div>
                                        {/* <!--end::Card--> */}
                                    </div>

                                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                        {/* <!--begin::Card--> */}
                                        <div className="card card-custom gutter-b card-stretch shadow-sm">
                                            {/* <!--begin::Body--> */}
                                            <div className="card-body text-center pt-4">
                                                {/* <!--begin::User--> */}
                                                <div className="mt-2 pb-2">
                                                    <div className="symbol symbol-circle symbol-lg-5 d-none">
                                                        <img src="assets/media/users/300_1.jpg" alt="image" />
                                                    </div>
                                                    <div className="symbol symbol-lg-75 symbol-circle symbol-light-success">
                                                        <span className="font-size-h3 symbol-label font-weight-boldest">MP</span>
                                                    </div>
                                                </div>
                                                {/* <!--end::User--> */}
                                                {/* <!--begin::Name--> */}
                                                <div className="my-2">
                                                    <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4">Matt Pears</a>
                                                </div>
                                                {/* <!--end::Name--> */}
                                                {/* <!--begin::Label--> */}
                                                <div className="text-muted">Application Developer</div>
                                                {/* <!--end::Label--> */}
                                                {/* <!--begin::Buttons--> */}
                                                <div className="mt-9 mb-6 text-left" style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                                                    <span className="text-dark font-weight-bolder mr-1">Services:</span>
                                                    Mobile Application Development, Web Design,Digital Marketing,Logo Design
                                                </div>
                                                <Link to={'/Chat'} className="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block">Connect</Link>
                                                {/* <!--end::Buttons--> */}
                                            </div>
                                            {/* <!--end::Body--> */}
                                        </div>
                                        {/* <!--end::Card--> */}
                                    </div>

                                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                        {/* <!--begin::Card--> */}
                                        <div className="card card-custom gutter-b card-stretch shadow-sm">
                                            {/* <!--begin::Body--> */}
                                            <div className="card-body text-center pt-4">
                                                {/* <!--begin::User--> */}
                                                <div className="mt-2 pb-2">
                                                    <div className="symbol symbol-circle symbol-lg-75">
                                                        <img src="assets/media/users/300_3.jpg" alt="image" />
                                                    </div>
                                                    <div className="symbol symbol-lg-75 symbol-circle symbol-light-success d-none">
                                                        <span className="font-size-h3 symbol-label font-weight-boldest">MP</span>
                                                    </div>
                                                </div>
                                                {/* <!--end::User--> */}
                                                {/* <!--begin::Name--> */}
                                                <div className="my-2">
                                                    <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4">Luca Doncic</a>
                                                </div>
                                                {/* <!--end::Name--> */}
                                                {/* <!--begin::Label--> */}
                                                <div className="text-muted">CEO and Founder</div>
                                                {/* <!--end::Label--> */}
                                                {/* <!--begin::Buttons--> */}
                                                <div className="mt-9 mb-6 text-left" style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                                                    <span className="text-dark font-weight-bolder mr-1">Services:</span>
                                                    Mobile Application Development, Web Design,Digital Marketing,Logo Design
                                                </div>
                                                <Link to={'/Chat'} className="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block">Connect</Link>
                                                {/* <!--end::Buttons--> */}
                                            </div>
                                            {/* <!--end::Body--> */}
                                        </div>
                                        {/* <!--end::Card--> */}
                                    </div>

                                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                        {/* <!--begin::Card--> */}
                                        <div className="card card-custom gutter-b card-stretch shadow-sm">
                                            {/* <!--begin::Body--> */}
                                            <div className="card-body text-center pt-4">
                                                {/* <!--begin::User--> */}
                                                <div className="mt-2 pb-2">
                                                    <div className="symbol symbol-circle symbol-lg-5 d-none">
                                                        <img src="assets/media/users/300_1.jpg" alt="image" />
                                                    </div>
                                                    <div className="symbol symbol-lg-75 symbol-circle symbol-light-success">
                                                        <span className="font-size-h3 symbol-label font-weight-boldest">SM</span>
                                                    </div>
                                                </div>
                                                {/* <!--end::User--> */}
                                                {/* <!--begin::Name--> */}
                                                <div className="my-2">
                                                    <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4">Sarah May</a>
                                                </div>
                                                {/* <!--end::Name--> */}
                                                {/* <!--begin::Label--> */}
                                                <div className="text-muted">Marketing Manager</div>
                                                {/* <!--end::Label--> */}
                                                {/* <!--begin::Buttons--> */}
                                                <div className="mt-9 mb-6 text-left" style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                                                    <span className="text-dark font-weight-bolder mr-1">Services:</span>
                                                    Social Media Marketing, Brand Marketing, Advertising, Marketing Strategy
                                                </div>
                                                <Link to={'/Chat'} className="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block">Connect</Link>
                                                {/* <!--end::Buttons--> */}
                                            </div>
                                            {/* <!--end::Body--> */}
                                        </div>
                                        {/* <!--end::Card--> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Services;