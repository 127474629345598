import React, { Component } from 'react';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions'
import Store from './store';
import Auth from '../Utilities/Auth';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';

class CreateProduct extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            productImage: '',
            image_url: '',
            uploadedImage: '',
            errors: {},
            title: '',
            description: '',
            loading: false,
            businessId: this.props.match.params.businessId ? this.props.match.params.businessId : '',
            id: this.props.match.params.id ? this.props.match.params.id : '',
            token: '',
            redirect: false
        }
        this.store = Store;
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
        if (this.state.id && this.state.id !== '') {
            await this.getProduct();
        }
        await this.store.setSubHeader("Business", ["Product", "Create Product"])        
    }

    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
        }
    }

    onImageChange = async (e) => {
        let errors = {};

        if (e.target.files[0]['size'] > 2097152) {
            errors.productImage = "File size exceeded 2 MB";
            this.setState({ errors: errors });
        }
        else {
            const file = this.myRef.current.files[0];
            const reader = new FileReader();

            reader.onloadend = async () => {
                await this.setState({
                    image_url: reader.result
                })
            }
            await this.setState({
                productImage: e.target.files[0]
            })
            e.target.value = '';
            if (file) {
                reader.readAsDataURL(file);
            }
            else {
                this.setState({
                    image_url: ""
                })
            }
        }
    }

    _onRemoveImage = async (e) => {
        await this.setState({ image_url: '', productImage: '' })
    }

    productValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.title) {
            formIsValid = false;
            errors.title = "Please enter Product Title.";
        }

        if (!this.state.description) {
            formIsValid = false;
            errors.description = "Please enter Product Description.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onAddProduct = async () => {
        if (this.productValidation()) {
            this.setState({ loading: true })
            let formData = new FormData();
            formData.append('title', this.state.title);
            formData.append('description', this.state.description);
            formData.append('businessId', this.state.businessId);

            if (this.state.productImage !== '') {
                formData.append('productImage', this.state.productImage);
            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(key, value);
            // }
            // return
            let errors = {};
            let method = 'POST';
            if (this.state.id && this.state.id !== '') {
                method = 'PUT';
                formData.append('_id', this.state.id);
            }

            Axios({
                method: method,
                url: Constants.url + 'product',
                data: formData,
                headers: {
                    'Accept': 'application/json,multipart/form-data',
                    'Content-Type': 'application/json,multipart/form-data',
                    'Authorization': this.state.token
                }
            }).then(response => {
                let responseData = response.data;
                // console.log("Add Product", responseData)
                if (responseData.status) {
                    this.setState({ loading: false, redirect: true });
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.log(error);
            });
        }
    }

    renderRedirect = () => {

        if (this.state.redirect) {
            return (<Redirect to={'/Company/'+this.state.businessId+'?tab=Product'} />
            )
        }
    }

    getProduct = () => {
        this.setState({ dataLoading: true })
        let errors = {};
        fetch(Constants.url + 'product/' + this.state.id,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        title: responseData.Product.title,
                        description: responseData.Product.description,
                        uploadedImage: responseData.Product.image,
                        businessId: responseData.Product.businessId._id,
                        dataLoading: false,
                    });
                    setTimeout(() => {
                        window.loadPopover()
                    }, 300);
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }
    render() {
        return (

            <>
                {this.renderRedirect()}
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom gutter-b example example-compact">
                            <div className="card-header">
                                <h3 className="card-title">Product Details</h3>
                            </div>
                            <div className="card-body">
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Product Title <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-lg" name="title" placeholder="Enter Product Titile" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.title} />
                                        {this.state.errors.country && <span className="form-text text-danger pl-2">{this.state.errors.country}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Description <span className="text-danger">*</span></label>
                                        <textarea rows={5} className="form-control form-control-lg" name="description" placeholder="Enter Product Description" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.description} />
                                        {this.state.errors.country && <span className="form-text text-danger pl-2">{this.state.errors.country}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Product Image</label><br />
                                        <div className="image-input image-input-outline" id="kt_profile_avatar">
                                            {this.state.image_url ?
                                                <div className="image-input-wrapper" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.state.image_url})`, backgroundPosition: 'center' }}></div>
                                                :
                                                this.state.dataLoading ? '....' : this.state.uploadedImage ?
                                                    <div className="image-input-wrapper" style={{ backgroundImage: `url(${Constants.ProductImageUrl}${this.state.uploadedImage})`, backgroundPosition: 'center', backgroundSize: 'contain' }}></div>
                                                    :
                                                    <div className="image-input-wrapper" style={{ backgroundImage: 'url(assets/noImage.png)', backgroundPosition: 'center' }}></div>
                                            }
                                            <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                                                <i className="fa fa-pen icon-sm text-muted"></i>
                                                <input type="file" name="profileImage" accept=".png, .jpg, .jpeg" ref={this.myRef} onChange={(e) => this.onImageChange(e)} />
                                            </label>
                                            {
                                                this.state.image_url &&

                                                <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-toggle="tooltip" title="Cancel Image" onClick={(e) => this._onRemoveImage(e)} style={{ position: 'absolute', right: '-10px', bottom: '-5px' }}>
                                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            {
                                                this.state.loading ?
                                                    <button type="button" className="btn btn-primary mr-2 spinner spinner-white spinner-right">Save</button>

                                                    :
                                                    <button type="button" className="btn btn-primary mr-2" onClick={() => { this.onAddProduct() }}>{(this.state.id && this.state.id !== '') ? 'Edit' : 'Save'}</button>
                                            }
                                            <button type="reset" className="btn btn-secondary" onClick={() => { window.location = '/' }}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CreateProduct;