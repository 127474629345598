import React, { Component } from 'react';
import InputTag from '../Components/InputTag';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AllFunction from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import LoginHooks from '../Components/LoginHooks';
import AutoSuggestion from '../Components/AutoSuggestion';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Redirect } from 'react-router-dom';
import Autocomplete from "react-google-autocomplete";
import Select from 'react-select';
import MyAppleButton from '../Components/MyAppleSigninButton';
import KeycloakSigninButton from '../Components/KeycloakSigninButton';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from '../keycloak';
import { Spinner } from 'react-activity';
import 'react-activity/dist/Spinner.css';
import firebase from '../Utilities/firebase'

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "Login",
            occupationType: 'employee',
            employee: true,
            business: false,
            student: false,
            other: false,
            height: 600,
            errors: {},
            isOTP: false,
            loginLoading: false,
            signupLoading: false,
            name: '',
            lastName: '',
            email: '',
            password: '',
            cpassword: '',
            city: '',
            country: '',
            companyName: '',
            designation: '',
            domain: '',
            businessName: '',
            website: '',
            services: [],
            collegeName: '',
            study: '',
            otherType: '',
            withGoogle: false,
            searchCities: [],
            searchCompanies: [],
            countryList: [],
            stateList: [],
            cityList: [],
            state: '',
            company: true,
            indivisual_service: false,
            businessType: 'Service',
            redirect: false,
            redirectType: '',
            businessId: '',
            alert: false,
            domianList: [],
            businessTypeList: [],
            redirectDashboard: false,
            street_address: '',
            userLoading: false,
            companyType: 'new',
            companyTypeList: [{
                label: 'New Company',
                value: "new"
            },
            {
                label: 'Existing Company',
                value: 'existing'
            }],
            businessList: [],
            selectedCompany: '',
            selectedCompanyId: '',
            selectedCompanyObj: {},
            success: false,
            successMsg: '',
            otp : '',
            timer: 60,
			resend: true,
            country_code : '+91',
            mobile_no : '',
            loginBntLoader: false,
        }
		this.Timer = {};
    }

    async componentDidMount() {
        await this.setState({ height: window.screen.height - 111 })
        await this.getBusinessList();
        await this.getDomainList();
        await this.getBusinessTypeList();
    }

    onCancelProcess = async () => {
        await this.setState({
            tab: 'Login',
            name: '',
            lastName: '',
            email: '',
            password: '',
            cpassword: '',
            city: '',
            country: '',
        })
    }

    getBusinessList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'get-business',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {

                    const optionList = responseData.Businesses.map((business, index) => {
                        return {
                            value: business._id,
                            label: business.businessName
                        }
                    })

                    this.setState({ dataLoading: false, businessList: optionList || [] });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onChangeCountry = async (event) => {
        await this.setState({ country: event.target.value, stateList: [], cityList: [] });
        await this.getStateList();
    }

    getStateList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'places?type=STATE&name=' + this.state.country,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({ dataLoading: false, stateList: responseData.places });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onChangeState = async (event) => {
        await this.setState({ state: event.target.value, cityList: [] });
        await this.getCityList();
    }

    getCityList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'places?type=CITY&name=' + this.state.state,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({ dataLoading: false, cityList: responseData.places });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getBusinessTypeList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'dropdown/BusinessType',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({ dataLoading: false, businessTypeList: responseData.dropdown.options });
                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getDomainList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'dropdown/Domain',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({ dataLoading: false, domainList: responseData.dropdown.options });
                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    UNSAFE_componentWillReceiveProps() {
        this.setState({ height: window.screen.height - 111 })
    }

    loginValidation = async () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.email) {
            formIsValid = false;
            errors["error"] = "Fill all inputs";
        }

        if (!this.state.password) {
            // formIsValid = false;
            errors["error"] = "Fill all inputs";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    signUpValidation = async () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.name) {
            formIsValid = false;
            errors.name = "Please enter your Name.";
        }

        if (!this.state.lastName) {
            formIsValid = false;
            errors.lastName = "Please enter your last  name.";
        }

        if (!this.state.email) {
            formIsValid = false;
            errors.email = "Please enter your email.";
        }

        if (this.state.email.length != 0) {
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
                errors.email = 'Please enter valid Email.';
                formIsValid = false;
            }
        } else if (!this.state.email || this.state.email.trim().length == 0) {
            errors.email = 'Please enter valid Email.';
            formIsValid = false;
        }
        if (!this.state.withGoogle) {
            if (!this.state.password) {
                formIsValid = false;
                errors["password"] = "Please enter your password.";
            }

            if (!this.state.cpassword) {
                formIsValid = false;
                errors["cpassword"] = "Please enter your confirm password.";
            }

            if (this.state.password !== this.state.cpassword) {
                formIsValid = false;
                errors["cpassword"] = "Retype your password.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onSignUpUser = async (event) => {
        if (await this.signUpValidation()) {
            await this.checkUserExist();
        }
    }

    checkUserExist = () => {
        let { email } = this.state;
        let errors = {}
        this.setState({ userLoading: true })
        fetch(Constants.url + 'userByEmail/' + email,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    if (responseData.users.length > 0) {
                        errors.error = 'User Already Exist!';
                        this.setState({ errors: errors, userLoading: false })
                    } else {
                        this.setState({ tab: 'YourSelf' })
                    }
                } else {
                    errors.error = responseData.error;
                    // errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, userLoading: false })
                }
            })
            .catch(err => {
                this.setState({ userLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    yourSelfValidation = async () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.city) {
            formIsValid = false;
            errors.city = "Please enter City.";
        }

        if (!this.state.country) {
            formIsValid = false;
            errors.country = "Please select Country.";
        }

        if (!this.state.state) {
            formIsValid = false;
            errors.state = "Please select State.";
        }

        if (this.state.mobileNo && this.state.mobileNo != "") {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(this.state.mobileNo)) {
                formIsValid = false;
                errors.mobileNo = "Please enter only number.";
            } else if (this.state.mobileNo.length != 10) {
                formIsValid = false;
                errors.mobileNo = "Please enter valid phone number.";
            }
        }

        if (!this.state.street_address) {
            formIsValid = false;
            errors.street_address = "Please enter your location.";
        }

        await this.setState({ errors: errors });
        return formIsValid;
    }

    onChangeNext = async () => {
        const { tab } = this.state
        if (tab === 'YourSelf') {
            if (await this.yourSelfValidation()) {
                return this.setState({ tab: 'YourBusiness' })
            } else {
                return false;
            }
        }
    }

    businessTypeChange = async (event) => {
        const target = event.target;
        var value = target.type === "radio" ? target.checked : target.value;
        const name = target.name;

        await this.setState({
            [name]: value,
            businessType: name,
        });
        // console.log(this.state.businessType)
    }

    companyTypeChange = async (event) => {
        const target = event.target;
        var value = target.type === "radio" ? target.checked : target.value;
        const name = target.name;

        await this.setState({
            [name]: value,
            companyType: name,
        });
        // console.log(this.state.companyType)
    }

    occupationTypeChange = async (event) => {
        const target = event.target;
        var value = target.type === "radio" ? target.checked : target.value;
        const name = target.name;

        await this.setState({
            [name]: value,
            occupationType: name,
        });

        if (this.state.occupationType === 'employee') {
            await this.setState({ businessName: '', website: '', services: [], collegeName: '', study: '', otherType: '' })
        } else if (this.state.occupationType === 'student') {
            await this.setState({ businessName: '', website: '', domain: '', services: [], otherType: '', companyName: '', designation: '' })
        } else if (this.state.occupationType === 'business') {
            await this.setState({ companyName: '', collegeName: '', study: '', otherType: '' })
        }
        else {
            await this.setState({ companyName: '', designation: '', domain: '', businessName: '', website: '', services: [], collegeName: '', study: '' })
        }
    }

    onEmployeeValidation = async () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.companyName) {
            formIsValid = false;
            errors.companyName = "Please enter Company Name.";
        }

        if (!this.state.designation) {
            formIsValid = false;
            errors.designation = "Please enter Designation.";
        }

        if (!this.state.domain) {
            formIsValid = false;
            errors.domain = "Please select Domain.";
        }

        await this.setState({ errors: errors });
        return formIsValid;
    }

    onBusinessValidation = async () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.businessName) {
            formIsValid = false;
            errors.businessName = "Please enter Business Name.";
        }

        if (!this.state.designation) {
            formIsValid = false;
            errors.designation = "Please enter Designation.";
        }

        if (!this.state.domain) {
            formIsValid = false;
            errors.domain = "Please select Domain.";
        }

        await this.setState({ errors: errors });
        return formIsValid;
    }

    onExistingBusinessValidation = async () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.selectedCompany) {
            formIsValid = false;
            errors.selectedCompany = "Please select business.";
        }

        if (!this.state.designation) {
            formIsValid = false;
            errors.designation = "Please enter Designation.";
        }

        await this.setState({ errors: errors });
        return formIsValid;
    }

    onStudentValidation = async () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.collegeName) {
            formIsValid = false;
            errors.collegeName = "Please enter College Name.";
        }

        if (!this.state.study) {
            formIsValid = false;
            errors.study = "Please enter your education.";
        }

        await this.setState({ errors: errors });
        return formIsValid;
    }

    onOtherValidation = async () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.otherType) {
            formIsValid = false;
            errors.otherType = "Please enter Other Type.";
        }

        await this.setState({ errors: errors });
        return formIsValid;
    }

    onSubmitValidate = async () => {
        if (this.state.occupationType === 'employee') {
            if (await this.onEmployeeValidation()) {
                this.onSubmitCompleteProfile();
            }
        } else if (this.state.occupationType === 'student') {
            if (await this.onStudentValidation()) {
                this.onSubmitCompleteProfile();
            }
        } else if (this.state.occupationType === 'other') {
            if (await this.onOtherValidation()) {
                this.onSubmitCompleteProfile();
            }
        } else if (this.state.occupationType === 'business' && this.state.companyType == 'existing') {
            if (await this.onExistingBusinessValidation()) {
                this.onSubmitCompleteProfile();
            }
        }
        else {
            if (await this.onBusinessValidation()) {
                this.onSubmitCompleteProfile();
            }
        }

    }

    onSubmitCompleteProfile = () => {
        this.setState({ signupLoading: true })
        let company = {
            companyName: this.state.companyName,
            designation: this.state.designation,
            domain: this.state.domain,
        }
        let existingCompany = {
            companyName: this.state.selectedCompany,
            designation: this.state.designation,
            businessId: this.state.selectedCompanyId,
        }
        let body = '';
        if (this.state.occupationType === "employee") {
            body = {
                email: this.state.email,
                password: this.state.password,
                firstName: this.state.name,
                lastName: this.state.lastName,
                mobileNo: this.state.mobileNo,
                city: this.state.city,
                country: this.state.country,
                state: this.state.state,
                occupationType: this.state.occupationType,
                company: company,
                street_address: this.state.street_address
            };
        } else {
            body = {
                email: this.state.email,
                password: this.state.password,
                firstName: this.state.name,
                lastName: this.state.lastName,
                mobileNo: this.state.mobileNo,
                city: this.state.city,
                country: this.state.country,
                state: this.state.state,
                occupationType: this.state.occupationType,
                domain: this.state.domain,
                businessName: this.state.businessName,
                designation: this.state.designation,
                website: this.state.website,
                services: this.state.services,
                collegeName: this.state.collegeName,
                study: this.state.study,
                otherType: this.state.otherType,
                businessType: this.state.businessType,
                street_address: this.state.street_address,
                companyType: this.state.companyType
            };
        }

        if (this.state.companyType == 'existing') {
            body.existingCompany = existingCompany
        }

        body = JSON.stringify(body)
        // console.log(Constants.url + 'user')
        // console.log(body)
        //return false
        let errors = {};
        fetch(Constants.url + 'user',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({ signupLoading: false });
                    localStorage.setItem('user', JSON.stringify(responseData.body.user));
                    localStorage.setItem('token', "Bearer " + responseData.body.token);
                    localStorage.setItem('isRegister', true);
                    this.setState({ loginLoading: false, businessId: responseData.body.businessId });
                    if (this.state.occupationType === 'business' && this.state.companyType == 'new') {
                        this.setState({ alert: true })
                    } else {
                        
                        this.setState({ redirect: true })
                    }
                    window.location = '/';
                } else {
                    errors.error = responseData.error;
                    // errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, signupLoading: false })
                }
            })
            .catch(err => {
                this.setState({ signupLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onLogin = async () => {
        if (await this.loginValidation()) {
            this.setState({ loginLoading: true })
            let body = JSON.stringify({
                email: this.state.email,
                password: this.state.password,
            });
            let errors = {};
            fetch(Constants.url + 'login',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: body
                }).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.status) {
                        localStorage.setItem('user', JSON.stringify(responseData.body.user));
                        localStorage.setItem('token', "Bearer " + responseData.body.token);
                        this.setState({ loginLoading: false });
                        let lastPage = localStorage.getItem('lastPage');
                        if (lastPage && lastPage != "") {
                            window.location = lastPage;
                        } else {
                            window.location = '/';
                        }
                    } else {
                        if (Array.isArray(responseData.error) && "message" in responseData.error[0]) {
                            errors.error = responseData.error[0]['message'];
                        } else {
                            errors.error = responseData.error;
                        }
                        this.setState({ errors: errors, loginLoading: false })
                    }
                    setTimeout(() => {
                        this.setState({ errors: {} })
                    }, 3000);
                })
                .catch(err => {
                    this.setState({ loginLoading: false })
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors })
                    console.log(err);
                });
        }
    }

    onSendForgetPasswordMail = () => {
        let errors = {};
        if (this.state.email.length != 0) {
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
                errors.email = 'Please enter valid Email.';
            }
        } else if (!this.state.email || this.state.email.trim().length == 0) {
            errors.email = 'Please enter valid Email.';
        }
        this.setState({ errors })
        if (errors && Object.keys(errors).length > 0) {
            this.setState({ errors })
        }
        else {
            this.setState({ errors: {} })
            let errors = {};
            this.setState({ loginLoading: true })
            let body = JSON.stringify({
                email: this.state.email,
            });
            fetch(Constants.url + 'resetPasswordLink',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: body
                })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.status) {
                        this.setState({ loginLoading: false, success: true, successMsg: responseData.message || 'Reset mail sended successfully.', email: '' });
                    } else {
                        if (Array.isArray(responseData.error) && "message" in responseData.error[0]) {
                            errors.error = responseData.error[0]['message'];
                        } else {
                            errors.error = responseData.error;
                        }
                        this.setState({ errors: errors, loginLoading: false })
                    }
                    setTimeout(() => {
                        this.setState({ errors: {}, successMsg: '', success: false })
                    }, 5000);
                })
                .catch(err => {
                    this.setState({ loginLoading: false })
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors })
                    console.log(err);
                });
        }
    }

    onGetTags = async (services) => {
        await this.setState({ services })
    }

    onChangeCompanyName = async (event) => {
        await this.setState({ companyName: event.target.value })
        if (this.state.companyName !== '') {
            await this.getSerachCompanyName();
        } else {
            this.setState({ showSuggestions: false, searchCompanies: [] })
        }

    }

    getSerachCompanyName = () => {
        let errors = {};
        setTimeout(() => {
            window.blockPage()
        }, 300);
        fetch(Constants.url + 'company/' + this.state.companyName,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({ showSuggestions: true, searchCompanies: responseData.Businesses || [] });
                    setTimeout(() => {
                        window.loadPopover()
                        window.unblockPage()
                    }, 300);
                } else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }
    onOptionClick = (e, suggestion, keyName) => {
        // console.log(suggestion, keyName)
        this.setState({
            activeSuggestion: 0,
            searchCities: [],
            searchCompanies: [],
            showSuggestions: false,
            [keyName]: e.currentTarget.innerText,
        });
    }

    onChangeCity = async (event) => {
        await this.setState({ city: event.target.value })
        if (this.state.city !== '') {
            await this.getSerachCity();
        } else {
            this.setState({ showSuggestions: false, searchCities: [] })
        }
    }

    getSerachCity = () => {
        let errors = {};
        setTimeout(() => {
            window.blockPage()
        }, 300);
        fetch(Constants.url + 'userCity/' + this.state.city,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({ showSuggestions: true, searchCities: responseData.users });

                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors, dataLoading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    callBack = async (res) => {
        if (res.status) {
            if (res.body === "SignUp") {
                await this.setState({
                    tab: 'YourSelf',
                    email: res.datas.email,
                    name: res.datas.firstName,
                    lastName: res.datas.lastName,
                    withGoogle: true
                })
            } else {
                await localStorage.setItem('user', JSON.stringify(res.body.user));
                await localStorage.setItem('token', "Bearer " + res.body.token);
                await this.setState({ loginLoading: false });
                window.location = '/';
            }
        }
    }


    onRedirectCompanyEdit = async () => {
        this.setState({ redirect: true, redirectType: 'Company' })
    }

    onRedirectDashboard = async () => {
        this.setState({ redirect: true, redirectType: 'Dashboard' })
    }

    renderRedirect = () => {

        if (this.state.redirect && this.state.redirectType === 'Company') {
            return (<Redirect to={'/EditCompany/' + this.state.businessId} />
            )
        } else if (this.state.redirect) {
            return (<Redirect to={{ pathname: '/', state: { Regsuccess: true } }} />
            )
        }
    }

    getAddressComponentData = (place) => {
        let address_components = place.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name })
            }
        })
        this.setState({ street_address: place.formatted_address });

    }

    handleChange = async (selectedOption) => {
        await this.setState({ selectedCompany: selectedOption.label, selectedCompanyId: selectedOption.value, selectedCompanyObj: selectedOption });
    };

    numberHandler(event) {
		let phone = event.target.value;
		this.setState({ mobile_no: phone.replace(/[^0-9]/g, '') });
	}

    /**
	 * This is validation function
	 * @param null
	 * @returns boolean
	 * @description validate country code and mobile number
	 */
	handleValidation = () => {
		let errors = {};
		let formIsValid = true;

		if (!this.state.country_code) {
			formIsValid = false;
			errors["error"] = "Fill all inputs";
		}

		if (!this.state.mobile_no) {
			formIsValid = false;
			errors["error"] = "Fill all inputs";
		}

		if (this.state.mobile_no.length != 10) {
			formIsValid = false;
			errors["error"] = "Please enter valid mobile number";
		}

		this.setState({ errors: errors });
		return formIsValid;
	}


  _onVerifyMobileNumber = async () => {
    await this.setState({ loginBntLoader: true })
    const { navigation } = this.props;
    const { mobile_no, country_code } = this.state;

    // let urlPath = "auth/check-mobile";
    let urlPath = "check-mobile-registered";
    // console.log(CONSTANTS.AUTH_URL + urlPath)
    fetch(Constants.AUTH_URL + urlPath, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mobileNo: mobile_no,
        countryCode: "+" + country_code,
        client: Constants.AUTH_APP_NAME,
        client_secret: Constants.AUTH_SECRET
      }),

    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
        if (responseData.status) {
            console.log("RES: ",responseData)
          if (!responseData?.auth && !!responseData?.error) {
            console.log("is True Register")
            this.sendOTP({ mobile_no, country_code, isVerifyMobileNumber: false })
          } else {
            console.log("is False Register")
            this.sendOTP({ mobile_no, country_code, isVerifyMobileNumber: true })
          }
          // navigation.navigate('OTPScreen', { mobile_no, country_code: "+" + country_code, type: "Login" })
        } else if (!responseData.status && responseData.error) {
          alert(
            responseData.error,
            { cancelable: false })
          this.setState({ loginBntLoader: false });
        } else {
          setTimeout(() => {
            alert(
              'Something went wrong, Please try again after sometime.',
              { cancelable: false }
            )
            this.setState({ loginBntLoader: false });
          }, 1000);
        }
      }).catch(error => {
        console.log("err", error);
        alert(
          'Something went wrong, Please try again after sometime. this',
          { cancelable: false }
        )
        this.setState({ loginBntLoader: false })
      }).finally(final => {
        // return this.setState({ isLogin: false })
      });
  }

    /**
	 * This function send otp to given mobile number
	 * @param {*} event
	 * @returns otp
	 */
	sendOTP = async (event) => {
		let errors = {};

		!this.state.captchaLoaded && this.setUpRecaptcha();

		if (this.handleValidation()) {
			this.setState({ loginBntLoader: true, });
			const phoneNumber = this.state.country_code + this.state.mobile_no;
			const appVerifier = window.recaptchaVerifier;

			firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
				.then((confirmationResult) => {
					// SMS sent. Prompt user to type the code from the message, then sign the
					// user in with confirmationResult.confirm(code).
					this.setState({ isOTP: true, loginBntLoader: false, confirmationResult })
					window.confirmationResult = confirmationResult;
					// console.log('Now Its called');
					this.Timer = setInterval(() => this.timer(), 1000);
				}).catch((error) => {
					// Error; SMS not sent
					console.log(error)
					this.setState({ loginBntLoader: false, });
					errors["error"] = JSON.stringify(error?.message?.split('.')[0]);
					this.setState({ errors: errors });
				});
		}
	}

    /**
	 * This function verify OTP
	 * @param null
	 * @returns return firebase user
	 */
	onOTPVerify = () => {
		const { otp: code, confirmationResult } = this.state;
		this.setState({ loginBntLoader: true });
		var errors = {};

		if (code !== '') {
			return confirmationResult.confirm(code).then((result) => {
				// User signed in successfully.
				const user = result.user;
				this.setState({ firebaseUser: user });
                if (this.state.isVerifyMobileNumber) {
                    this._onGetUserCredential(false);
                } else {
                    this._onGetUserCredential(true)
                }
				// ...
			})
				.catch((error) => {
					console.log(error)
					// User couldn't sign in (bad verification code?)
					errors["error"] = "Please enter valid OTP";
					this.setState({ errors: errors, loginBntLoader: false });
				});
		}
		else {
			errors["error"] = "Please enter valid OTP";
			return this.setState({ errors: errors, loginBntLoader: false });
		}
	}

    /**
   * this api is use to verified mobile number and email using get user token 
   */
//   _getAuthenticationDetails = async () => {

//     await this.setState({ isOTPSubmit: true });
//     const { navigation } = this.props;
//     const { mobileNo, firebaseToken, isEmailVerify, isMobileVerify, email, type } = this.state;
//     const { signin, } = this.context;

//     let urlPath = "update-verification-flag";
//     const Body = JSON.stringify({
//       firebaseToken: firebaseToken,
//       mailVerificationFlag: false,
//       mobileVerificationFlag: isMobileVerify,
//       mobileNo: mobileNo,
//       email: email,
//       client: CONSTANTS.AUTH_APP_NAME,
//       client_secret: CONSTANTS.AUTH_SECRET
//     })
//     // console.log("Mobile and Email Verify after Submit Api =>", CONSTANTS.AUTH_URL + urlPath, Body);

//     fetch(CONSTANTS.AUTH_URL + urlPath, {
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//       },
//       body: Body
//     }).then((response) => response.json())
//       .then((responseData) => {
//         // console.log("res: ", responseData);
//         // console.log("verify email and mobile responseData", responseData)
//         if (responseData.status) {
//           const RoleName = responseData.response?.User?.role || "MEMBER";
//           const vufId = responseData.response?.User?.vufId || "";
//           const RegisterRes = {
//             email,
//             password: "",
//             user: responseData.response.User,
//             userToken: "Bearer " + responseData.response.token,
//             role: RoleName
//           }
//           if (type == "Login") {
//             signin(RegisterRes)
//           }
//           this.setState({ RegisterRes, isRegisterModel: true, vufId })
//         } else if (!responseData.status && responseData.error && typeof responseData.error != "object") {
//           Alert.alert(
//             'Error',
//             responseData.error,
//             [{ text: 'Okay', onPress: () => { this.setState({ isOTPSubmit: false, otp: "" }); } }],
//             { cancelable: false })
//         } else {
//           Alert.alert(
//             'Oops!',
//             'Something went wrong, Please try again after sometime.',
//             [{ text: 'Okay', onPress: () => { } }],
//             { cancelable: false }
//           );
//         }
//       }).catch(error => {
//         console.log("Error this: ", error);
//         Alert.alert(
//           'Oops!',
//           'Something went wrong, Please try again after sometime.',
//           [{ text: 'Okay', onPress: () => { this.setState({ isOTPSubmit: false }); } }],
//           { cancelable: false }
//         );
//       }).finally((err) => {
//         // this.setState({ isOTPSubmit: false });
//       });
//   }
     
  /**
   * this api is use to get user login credential
   */
  _onGetUserCredential = async (isUpdateFlag = false) => {
    const { mobile_no, } = this.state;
    const firebaseToken = await this.getGoogleAuthToken();

    await this.setState({ loginBntLoader: true });
    // let urlPath = "auth/login";
    let urlPath = "login-with-mobile";

    let bodyParams = JSON.stringify({
        firebaseToken: firebaseToken,
        mobileNo: mobile_no,
        client: Constants.AUTH_APP_NAME,
        client_secret: Constants.AUTH_SECRET,
      })

    if(isUpdateFlag) {
        urlPath = 'update-verification-flag'
        bodyParams = JSON.stringify({
            firebaseToken: firebaseToken,
            mobileVerificationFlag: true,
            mobileNo: mobile_no,
            client: Constants.AUTH_APP_NAME,
            client_secret: Constants.AUTH_SECRET,
          })
    }


    console.log(Constants.AUTH_URL + urlPath, bodyParams);

    fetch(Constants.AUTH_URL + urlPath, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: bodyParams
    }).then((response) => response.json())
      .then((responseData) => {
        // console.log("Login responseData", responseData);
        if (responseData.status && "response" in responseData) {
            const RoleName = responseData.response.User?.role || "MEMBER";

            let email = responseData?.response?.email || "";
            let userData = responseData?.response?.User || {};
            let userToken = responseData?.response?.token || {};

            localStorage.setItem('user', JSON.stringify(userData));
            localStorage.setItem('GoogleAuthToken', firebaseToken);
            localStorage.setItem('token', "Bearer " + userToken);
            localStorage.setItem('role', RoleName);

            let lastPage = localStorage.getItem('lastPage');
            if (lastPage && lastPage != "") {
                window.location = lastPage;
            } else {
                window.location = '/';
            }

            // GBN LOGIN CODE FOR REF
            // localStorage.setItem('user', JSON.stringify(responseData.body.user));
            // localStorage.setItem('token', "Bearer " + responseData.body.token);
            // this.setState({ loginLoading: false });
            // let lastPage = localStorage.getItem('lastPage');
            // if (lastPage && lastPage != "") {
            //     window.location = lastPage;
            // } else {
            //     window.location = '/';
            // }

            this.setState({ loginBntLoader: false, isInformationPageLoading: false });

        //   return signin({
        //     email: responseData?.response?.User?.email || "",
        //     user: responseData?.response?.User || {},
        //     userToken: "Bearer " + responseData.response.token,
        //     role: RoleName
        //   });
        } else if (!responseData.status && responseData.error && typeof responseData.error != "object") {
            alert(
            responseData.error,
            { cancelable: false })
            this.setState({ loginBntLoader: false, otp: "" }); 
        } else if (!responseData.status && responseData.error && typeof responseData.error == "string" && responseData.error == "Invalid client.") {
            alert(
            responseData.error,
            { cancelable: false })
            this.setState({ loginBntLoader: false, otp: "" }); 
        } else if (responseData.status && responseData.error && typeof responseData.error == "string" && !responseData.auth) {
            alert(
            responseData.error,
            { cancelable: false })
            this.setState({ loginBntLoader: false, otp: "" }); 
        } else {
            alert(
            'Something went wrong, Please try again after sometime.',
            { cancelable: false });
            this.setState({ loginBntLoader: false, otp: "" }); 
        }
      }).catch(error => {
        console.log("Error : ", error);
            alert(
          'Oops!',
          'Something went wrong, Please try again after sometime.',
          { cancelable: false });
          this.setState({ loginBntLoader: false, otp: "" }); 
      }).finally((err) => {
        // this.setState({ isOTPSubmit: false });
      });
  }

    /**
	 * @param null
	 * @returns token
	 * @description Get token from firebase usre
	 */
	getGoogleAuthToken = async () => {
		const { firebaseUser: user } = this.state;

		return await user.getIdToken().then(async function (idToken) {
			return await idToken;
		});
	}

    /**
	 * @param null
	 * @description reCAPTCHA solved, allow signInWithPhoneNumber
	 */
	setUpRecaptcha = () => {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			'size': 'invisible',
			'callback': (response) => {
				// reCAPTCHA solved, allow signInWithPhoneNumber.
				// this.sendOTP();
				this.setState({ captchaLoaded: true });
			}
		});
	}

    async timer() {
		let t = this.state.timer - 1;
		if (t == 0) {
			this.setState({
				resend: false,
				timer: 60,
			})
			clearInterval(this.Timer);
		} else {
			this.setState({ timer: t });
		}
	}

    


    render() {
        return (
            <>
                {this.renderRedirect()}
                <div className="d-flex flex-column flex-root" >
                    <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                        <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style={{ backgroundImage: 'url(assets/media/bg/bg-9.jpg)' }}>
                            <div className="d-flex flex-row-fluid flex-column justify-content-between">
                                <a href="#" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                                    <img src="/assets/logo/gbn_logo.png" className="loginLogo" alt="Logo" />
                                </a>
                                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                                    <h3 className="font-size-h1 mb-5 loginHeader" style={{ color: '#536375' }}>Welcome to Global Business Connect</h3>
                                    {/* <p className="font-weight-lighter text-white opacity-80">The ultimate Bootstrap, Angular 8, React &amp; VueJS admin theme framework for next generation web apps.</p> */}
                                </div>
                                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                                    <div className="opacity-70 font-weight-bold" style={{ color: '#536375' }}>© {moment().format('YYYY')} Global Business Connect</div>
                                    {/* <div className="d-flex">
                                        <a href="#" className="text-white">Privacy</a>
                                        <a href="#" className="text-white ml-10">Legal</a>
                                        <a href="#" className="text-white ml-10">Contact</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                            <div className="d-flex flex-column-fluid justify-content-center mt-20 align-items-center">

                                {
                                    this.state.tab === 'Login' ?
                                        <>
                                            <div className="login-form login-signin">
                                                {/* <div className="d-flex justify-content-between align-item-center mt-7">
                                                    <div className="pl-5" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <h4 style={{ fontSize: '1.100rem' }}>Initiative By</h4>
                                                        <img src="assets/logo/Final-Logo.png" className="max-h-120px" alt="" height={50} style={{ marginLeft: 10, }} />
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <h4 style={{ fontSize: '1.100rem' }}>Powered By</h4>
                                                        <img src="assets/logo/xpertnest-logo.png" className="max-h-120px" alt="" height={50} style={{ marginLeft: 10, }} />
                                                    </div>
                                                </div> */}
                                                <div className="d-flex justify-content-between align-item-center">
                                                    <div className="mb-8 mb-lg-9">
                                                        <h3 className="" style={{ color: '#3e8e49', fontSize: '4vh' }}>Welcome to VUF GBN</h3>
                                                        <p className="font-weight-bold">Sign in to continue</p>
                                                    </div>
                                                    <div>
                                                        <img src="assets/logo/vuf_logo.png" className="max-h-120px" alt="" height={50} style={{ marginLeft: 10, }} />
                                                    </div>
                                                </div>
                                                {this.state.errors.error &&
                                                    <div className="alert alert-danger text-left" role="alert">
                                                        <span className='pl-3'>{this.state.errors.error}</span>
                                                    </div>
                                                }

                                                <form className="form" id="kt_login_signin_form">

                                                    {/* UNCOMMENT TO RUN OLD LOGIN WITH USERNAME AND PASS
                                                    <div className="form-group">
                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Username" name="email" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.email} />
                                                    </div>
                                                    <div className="form-group">
                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="password" placeholder="Password" name="password" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.password} />
                                                    </div> */}

                                                    <>
                                                        <div id="recaptcha-container" ref={ref => this.recaptchaWrapperRef = ref} ></div>
                                                        {
                                                            this.state.isOTP ?

                                                                <>
                                                                    {this.state.errors.error &&
                                                                        <div className="alert alert-danger text-left" role="alert">
                                                                            <span className='pl-3'>{this.state.errors.error}</span>
                                                                        </div>
                                                                    }
                                                                    <div className="form-group mb-2">
                                                                        <input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Enter OTP" name="otp" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.otp} />
                                                                        
                                                                    </div>
                                                                    {
                                                                        !this.state.verified && 
                                                                            <div style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', display: 'flex' }}>
                                                                                <div className='px-2 py-0'>{this.state.timer} Seconds</div>
                                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                    <button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-1" style={{ backgroundColor : '#3e8e49' }} onClick={(event) => this.resendOTP(event)} disabled={this.state.resend}>Resend</button>
                                                                                    
                                                                                </div>
                                                                                {/* Removed from here and set to send otp button position */}
                                                                            </div>

                                                                    }

                                                                </>
                                                                : <>
                                                                    {this.state.errors.error &&
                                                                        <div className="alert alert-danger text-left" role="alert">
                                                                            <span className='pl-3'>{this.state.errors.error}</span>
                                                                        </div>
                                                                    }

                                                                    <div className="form-inline">
                                                                        <div className="form-group mb-5" style={{ width: '20%', marginRight: '2%' }}>
                                                                            <input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="+91" name="country_code" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.country_code} style={{ width: '100%' }} />
                                                                        </div>
                                                                        <div className="form-group mb-5" style={{ width: '78%' }}>
                                                                            <input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Mobile no" name="mobile_no" autoComplete="off" onChange={(event) => this.numberHandler(event)} value={this.state.mobile_no} style={{ width: '100%' }} />
                                                                        </div>
                                                                    </div>


                                                                    {/* <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                                                        <div className="checkbox-inline">
                                                                            <label className="checkbox m-0 text-muted">
                                                                                <input type="checkbox" name="remember" checked={this.state.RememberMe} onChange={e => this.onChangeCheckbox(e, 'RememberMe')} />
                                                                                <span></span>Remember me</label>
                                                                        </div>

                                                                    </div> */}
                                                                    {/* {
                                                                        this.state.loginBntLoader ? <button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"><Spinner color={'#fff'} /></button> :
                                                                            <button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-0" onClick={(event) => this.sendOTP(event)}>Send OTP</button>
                                                                    } */}
                                                                </>
                                                        }
                                                    </>


                                                    <div className='mb-2'>
                                                        <Link to={"/forgotpassword"} className="text-hover-primary my-3 mr-2" id="kt_login_forgot" style={{ color: '#3e8e49', cursor: 'pointer' }} >Forgot Password ?</Link>
                                                    </div>
                                                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">

                                                        {/* {
                                                            this.state.loginLoading ?
                                                                <button type="button" id="kt_login_signin_submit" className="btn btn-green font-weight-bold py-4 my-3 spinner spinner-white spinner-right">Login</button>
                                                                :
                                                                <button type="button" id="kt_login_signin_submit" className="btn btn-green font-weight-bold px-9 py-4 my-3" onClick={() => { this.onLogin() }}>Login</button>
                                                        } */}

                                                        {
                                                            this.state.loginBntLoader ? <button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" style={{ backgroundColor : '#3e8e49' }}><Spinner color={'#fff'} /></button> :

                                                            this.state.isOTP ? !this.state.verified &&
                                                                <div>
                                                                    <button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3" style={{ backgroundColor : '#3e8e49' }} onClick={(event) => this.onOTPVerify(event)}>Verify</button>
                                                                </div>
                                                                :
                                                                <button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-0" style={{ backgroundColor : '#3e8e49' }} onClick={(event) => this._onVerifyMobileNumber(event)}>Send OTP</button>
                                                        }
                                                        
                                                        <LoginHooks callBack={(res) => { this.callBack(res) }} />
                                                        <MyAppleButton />
                                                        {/* <ReactKeycloakProvider authClient={keycloak}> */}
                                                        {/* <KeycloakSigninButton callBack={(res) => { this.callBack(res) }} /> */}

                                                        {/* </ReactKeycloakProvider> */}
                                                    </div>
                                                </form>
                                                {this.state.tab === 'Login' &&
                                                    <div className="text-center mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                                                        <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
                                                        <span className="font-weight-bold ml-2" id="kt_login_signup" style={{ color: '#3e8e49', cursor: 'pointer' }} onClick={() => { this.setState({ tab: 'SignUp', errors: {} }) }}>Sign Up!</span>
                                                    </div>
                                                }
                                            </div>
                                        </> :

                                        <div className="login-form login-signup" style={{ display: 'block' }}>
                                            <div className="d-flex justify-content-between align-item-center">
                                                <div className="mb-8 mb-lg-9">
                                                    <h3 className="" style={{ color: '#3e8e49', fontSize: '4vh' }}>Welcome to VUF GBN</h3>
                                                    {this.state.tab !== 'ForgotPassword' ? <p className="font-weight-bold">Sign up to continue</p> : <p className="font-weight-bold">Enter email to continue</p>}
                                                </div>
                                                <div>
                                                    <img src="assets/logo/icon_transparent.png" className="max-h-120px" alt="" height={50} style={{ marginLeft: 10, }} />
                                                </div>
                                            </div>

                                            {this.state.errors.error &&
                                                <div className="alert alert-danger text-left" role="alert">
                                                    <span className='pl-3'>{this.state.errors.error}</span>
                                                </div>
                                            }
                                            {this.state.success &&
                                                <div className="alert alert-success text-left" role="alert">
                                                    <span className='pl-3'>{this.state.successMsg}</span>
                                                </div>
                                            }
                                            {
                                                this.state.tab === 'SignUp' &&
                                                <form className="form" id="kt_login_signup_form">
                                                    <div className="form-group">
                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Name" name="name" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.name} />
                                                        {this.state.errors.name && <span className="text-danger pl-2">{this.state.errors.name}</span>}
                                                    </div>

                                                    <div className="form-group">
                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Last Name" name="lastName" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.lastName} />
                                                        {this.state.errors.lastName && <span className="text-danger pl-2">{this.state.errors.lastName}</span>}
                                                    </div>

                                                    <div className="form-group">
                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="email" placeholder="Email" name="email" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.email} />
                                                        {this.state.errors.email && <span className="text-danger pl-2">{this.state.errors.email}</span>}
                                                    </div>
                                                    <div className="form-group">
                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="password" placeholder="Password" name="password" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.password} />
                                                        {this.state.errors.password && <span className="text-danger pl-2">{this.state.errors.password}</span>}
                                                    </div>
                                                    <div className="form-group">
                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="password" placeholder="Confirm password" name="cpassword" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.cpassword} />
                                                        {this.state.errors.cpassword && <span className="text-danger pl-2">{this.state.errors.cpassword}</span>}
                                                    </div>
                                                    <div className="form-group d-flex flex-wrap" style={{ justifyContent: 'space-between' }}>
                                                        {
                                                            this.state.userLoading ? <button type="button" id="kt_login_signup_submit" className="btn btn-green font-weight-bold py-3 spinner spinner-white spinner-right">Submit</button>
                                                                :
                                                                <button type="button" id="kt_login_signup_submit" className="btn btn-green font-weight-bold px-9 py-4 my-3 mx-4" onClick={(event) => { this.onSignUpUser(event) }}>Submit</button>
                                                        }
                                                        <button type="button" id="kt_login_signup_cancel" className="btn btn-light-green font-weight-bold px-9 py-4 my-3 mx-4" onClick={() => { this.onCancelProcess() }}>Cancel</button>
                                                    </div>
                                                </form>
                                            }
                                            {
                                                this.state.tab === 'YourSelf' &&
                                                <>
                                                    <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                                        <h4 className="mb-5 font-weight-bold text-black-50">Tell us more about your self</h4>

                                                        <div className="form-group mb-3">
                                                            <label>Enter Location</label>
                                                            <Autocomplete
                                                                apiKey={Constants.GOOGLE_MAPS_API_KEY}
                                                                style={{ width: "100%" }}
                                                                onPlaceSelected={(place) => {
                                                                    this.getAddressComponentData(place)
                                                                }}
                                                                className="form-control form-control-solid h-auto py-5 px-6"
                                                                name="street_address"
                                                            />
                                                            {this.state.errors.street_address && <span className="text-danger pl-2">{this.state.errors.street_address}</span>}
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label>Mobile No <span className="text-muted">(Optional)</span></label>
                                                            <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Mobile No" name="mobileNo" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this, 'Number')} value={this.state.mobileNo} />
                                                            {this.state.errors.mobileNo && <span className="text-danger pl-2">{this.state.errors.mobileNo}</span>}
                                                        </div>


                                                        {/* <div className="form-group mb-3">
                                                            <label>City Name</label>
                                                            <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="City Name" name="city" autoComplete={"new-password"} onChange={(event) => this.onChangeCity(event)} value={this.state.city} />
                                                            {this.state.errors.city && <span className="text-danger pl-2">{this.state.errors.city}</span>}
                                                            <AutoSuggestion suggestions={this.state.searchCities} schema={{ name: '_id' }} onOptionClick={(e, suggestion) => { this.onOptionClick(e, suggestion, "city") }} />
                                                        </div> */}
                                                        {/* <div className="form-group mb-3">
                                                            <label>Country Name</label>
                                                            <select className="form-control form-control-solid h-auto py-5 px-6" name="country" autoComplete="off" onChange={(event) => this.onChangeCountry(event)} value={this.state.country}>
                                                                <option value="">Select Country</option>
                                                                {
                                                                    this.state.countryList.map((list, index) => {
                                                                        return (
                                                                            <option value={list.value} key={index}>{list.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            {this.state.errors.country && <span className="text-danger pl-2">{this.state.errors.country}</span>}
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label>State Name</label>
                                                            <select className="form-control form-control-solid h-auto py-5 px-6" name="state" autoComplete="off" onChange={(event) => this.onChangeState(event)} value={this.state.state}>
                                                                <option value="">Select State</option>
                                                                {
                                                                    this.state.stateList.map((list, index) => {
                                                                        return (
                                                                            <option value={list.name} key={index}>{list.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            {this.state.errors.state && <span className="text-danger pl-2">{this.state.errors.state}</span>}
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label>City Name</label>
                                                            <select className="form-control form-control-solid h-auto py-5 px-6" name="city" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.city}>
                                                                <option value="">Select City</option>
                                                                {
                                                                    this.state.cityList.map((list, index) => {
                                                                        return (
                                                                            <option value={list.name} key={index}>{list.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            {this.state.errors.city && <span className="form-text text-danger pl-2">{this.state.errors.city}</span>}
                                                        </div> */}
                                                    </div>
                                                    <div className="d-flex justify-content-between pt-1">
                                                        <div className="col-md-12 p-0">
                                                            <div className="row justify-content-between">
                                                                <div className="col-md-6 p-0 text-left">
                                                                    <button type="button" className="btn btn-light-green font-weight-bolder text-uppercase px-8 py-3" data-wizard-type="action-prev" onClick={() => { this.setState({ tab: 'SignUp' }) }}>Previous</button>
                                                                </div>
                                                                <div className='col-md-6 p-0 text-right'>
                                                                    <button type="button" className="btn btn-green font-weight-bolder text-uppercase px-8 py-3" data-wizard-type="action-next" onClick={() => { this.onChangeNext() }}>Next</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.state.tab === 'YourBusiness' &&
                                                <>
                                                    <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                                        <h4 className="mb-5 font-weight-bold text-black-50">Tell us more about your Business</h4>

                                                        <div className="form-group">
                                                            <label>Are you salarised employee or own a business ?</label>
                                                            <div className="radio-inline">
                                                                <label className="radio radio-rounded">
                                                                    <input type="radio" checked={this.state.occupationType === "employee" ? "checked" : ''} name="employee" onChange={(event) => this.occupationTypeChange(event)} value={this.state.employee} />
                                                                    <span></span>
                                                                    Employee
                                                                </label>
                                                                <label className="radio radio-rounded">
                                                                    <input type="radio" name="business" checked={this.state.occupationType === "business" ? "checked" : ''} onChange={(event) => this.occupationTypeChange(event)} value={this.state.business} />
                                                                    <span></span>
                                                                    Business
                                                                </label>
                                                                <label className="radio radio-rounded">
                                                                    <input type="radio" name="student" checked={this.state.occupationType === "student" ? "checked" : ''} onChange={(event) => this.occupationTypeChange(event)} value={this.state.student} />
                                                                    <span></span>
                                                                    Student
                                                                </label>
                                                                <label className="radio radio-rounded">
                                                                    <input type="radio" name="other" checked={this.state.occupationType === "other" ? "checked" : ''} onChange={(event) => this.occupationTypeChange(event)} value={this.state.other} />
                                                                    <span></span>
                                                                    Other
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.occupationType === 'employee' ?
                                                                <>
                                                                    <div className="form-group mb-3">
                                                                        <label>Company Name</label>
                                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Company Name" name="companyName" autoComplete="off" onChange={(event) => this.onChangeCompanyName(event)} value={this.state.companyName} />
                                                                        {this.state.errors.companyName && <span className="text-danger pl-2">{this.state.errors.companyName}</span>}
                                                                        <AutoSuggestion suggestions={this.state.searchCompanies} schema={{ name: 'businessName' }} onOptionClick={(e, suggestion) => { this.onOptionClick(e, suggestion, "companyName") }} />
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label>Designation</label>
                                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Designation" name="designation" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.designation} />
                                                                        {this.state.errors.designation && <span className="text-danger pl-2">{this.state.errors.designation}</span>}
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label>Domain</label>
                                                                        <select className="form-control" name="domain" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.domain} >
                                                                            <option value="">Select Domain</option>
                                                                            {
                                                                                this.state.domainList.map((list, index) => {
                                                                                    return (
                                                                                        <option value={list.value} key={index}>{list.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {this.state.errors.domain && <span className="text-danger pl-2">{this.state.errors.domain}</span>}
                                                                    </div>
                                                                </>
                                                                :
                                                                this.state.occupationType === 'business' ?
                                                                    <>
                                                                        <div>
                                                                            <h6 className="text-dark font-weight-bold mb-7 text-center">Register Your Company</h6>
                                                                            <label className="text-muted">You may add more details about your business in next screens.Also, you may add multiple businesses from your profile screen later.</label>
                                                                        </div>
                                                                        <div className="form-group mb-3">
                                                                            <div className="radio-inline">
                                                                                {
                                                                                    this.state.companyTypeList.map((list, index) => {
                                                                                        return (
                                                                                            <label className="radio radio-rounded" key={index}>
                                                                                                <input type="radio" checked={this.state.companyType === list.value ? "checked" : ''} name={list.value} onChange={(event) => this.companyTypeChange(event)} value={this.state.companyType} />
                                                                                                <span></span>
                                                                                                {list.label}
                                                                                            </label>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            this.state.companyType == 'new' ? <>
                                                                                <div className="form-group mb-3">
                                                                                    <label>Business Type</label>
                                                                                    <div className="radio-inline">
                                                                                        {
                                                                                            this.state.businessTypeList.map((list, index) => {
                                                                                                return (
                                                                                                    <label className="radio radio-rounded" key={index}>
                                                                                                        <input type="radio" checked={this.state.businessType === list.value ? "checked" : ''} name={list.value} onChange={(event) => this.businessTypeChange(event)} value={this.state.company} />
                                                                                                        <span></span>
                                                                                                        {list.name}
                                                                                                    </label>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group mb-3">
                                                                                    <label>Business Name</label>
                                                                                    <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Business Name" name="businessName" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.businessName} />
                                                                                    {this.state.errors.businessName && <span className="text-danger pl-2">{this.state.errors.businessName}</span>}
                                                                                </div>
                                                                                <div className="form-group mb-3">
                                                                                    <label>Website <span className="text-muted">(Optional)</span></label>
                                                                                    <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Website" name="website" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.website} />
                                                                                    {this.state.errors.website && <span className="text-danger pl-2">{this.state.errors.website}</span>}
                                                                                </div>
                                                                                <div className="form-group mb-3">
                                                                                    <label>Designation</label>
                                                                                    <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Designation" name="designation" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.designation} />
                                                                                    {this.state.errors.designation && <span className="text-danger pl-2">{this.state.errors.designation}</span>}
                                                                                </div>
                                                                                <div className="form-group mb-3">
                                                                                    <label>Domain</label>
                                                                                    <select className="form-control" name="domain" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.domain} >
                                                                                        <option value="">Select Domain</option>
                                                                                        {
                                                                                            this.state.domainList.map((list, index) => {
                                                                                                return (
                                                                                                    <option value={list.value} key={index}>{list.name}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                    {this.state.errors.domain && <span className="text-danger pl-2">{this.state.errors.domain}</span>}
                                                                                </div>
                                                                                <div className="form-group mb-3">
                                                                                    <label>Services</label>
                                                                                    <InputTag onGetTags={this.onGetTags} tags={this.state.services} />
                                                                                    {this.state.errors.services && <span className="text-danger pl-2">{this.state.errors.services}</span>}
                                                                                </div>
                                                                                <span className="form-text text-muted">Add tags for services you provides, e.g.#plumbing,#softwaredevelopment,#cncjobs</span>
                                                                            </>
                                                                                :
                                                                                <>
                                                                                    <div className="form-group mb-3">
                                                                                        <label>Company Name</label>
                                                                                        <Select
                                                                                            value={this.state.selectedCompanyObj}
                                                                                            onChange={this.handleChange}
                                                                                            options={this.state.businessList}
                                                                                        />
                                                                                        {this.state.errors.selectedCompany && <span className="text-danger pl-2">{this.state.errors.selectedCompany}</span>}
                                                                                    </div>
                                                                                    <div className="form-group mb-3">
                                                                                        <label>Designation</label>
                                                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Designation" name="designation" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.designation} />
                                                                                        {this.state.errors.designation && <span className="text-danger pl-2">{this.state.errors.designation}</span>}
                                                                                    </div>
                                                                                </>
                                                                        }

                                                                    </>
                                                                    :
                                                                    this.state.occupationType === 'student' ?
                                                                        <>
                                                                            <div className="form-group mb-3">
                                                                                <label>College Name</label>
                                                                                <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="College Name" name="collegeName" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.collegeName} />
                                                                                {this.state.errors.collegeName && <span className="text-danger pl-2">{this.state.errors.collegeName}</span>}
                                                                            </div>
                                                                            <div className="form-group mb-3">
                                                                                <label>Study</label>
                                                                                <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Study" name="study" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.study} />
                                                                                {this.state.errors.study && <span className="text-danger pl-2">{this.state.errors.study}</span>}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <div className="form-group mb-3">
                                                                            <label>Other Type</label>
                                                                            <input className="form-control form-control-solid h-auto py-5 px-6" type="text" placeholder="Other Type" name="otherType" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.otherType} />
                                                                            {this.state.errors.otherType && <span className="text-danger pl-2">{this.state.errors.otherType}</span>}
                                                                        </div>
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-between pt-1">
                                                        <div className="mr-2">
                                                            <button type="button" className="btn btn-light-green font-weight-bolder text-uppercase px-8 py-3" data-wizard-type="action-prev" onClick={() => { this.setState({ tab: 'YourSelf' }) }}>Previous</button>
                                                        </div>
                                                        <div className="d-flex">
                                                            {
                                                                this.state.signupLoading ?
                                                                    <button type="button" id="kt_login_signup_submit" className="btn btn-green font-weight-bolder text-uppercase py-3 spinner spinner-white spinner-right">Submit</button>
                                                                    :
                                                                    <button type="button" className="btn btn-green font-weight-bolder text-uppercase px-8 py-3" data-wizard-type="action-next" onClick={() => { this.onSubmitValidate() }}>Submit</button>
                                                            }
                                                            {
                                                                this.state.signupLoading ?
                                                                    <button type="button" className="btn btn-green font-weight-bolder text-uppercase py-3 ml-2 spinner spinner-white spinner-right">Skip</button>
                                                                    :
                                                                    <button type="button" className="btn btn-green font-weight-bolder text-uppercase px-8 py-3 ml-2" data-wizard-type="action-next" onClick={() => { this.onSubmitCompleteProfile() }}>Skip</button>
                                                            }

                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {
                                                this.state.tab === 'ForgotPassword' &&
                                                <form className="form" id="kt_login_signup_form">

                                                    <div className="form-group">
                                                        <input className="form-control form-control-solid h-auto py-5 px-6" type="email" placeholder="Email" name="email" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.email} />
                                                        {this.state.errors.email && <span className="text-danger pl-2">{this.state.errors.email}</span>}
                                                    </div>

                                                    <div className="form-group d-flex flex-wrap" style={{ justifyContent: 'space-between' }}>
                                                        {
                                                            this.state.loginLoading ? <button type="button" id="kt_login_signup_submit" className="btn btn-green font-weight-bold py-3 spinner spinner-white spinner-right">Submit</button>
                                                                :
                                                                <button type="button" id="kt_login_signup_submit" className="btn btn-green font-weight-bold px-9 py-4 my-3 mx-4" onClick={(event) => { this.onSendForgetPasswordMail(event) }}>Submit</button>
                                                        }
                                                        <button type="button" id="kt_login_signup_cancel" className="btn btn-light-green font-weight-bold px-9 py-4 my-3 mx-4" onClick={() => { this.onCancelProcess() }}>Cancel</button>
                                                    </div>
                                                </form>
                                            }

                                        </div>
                                }

                            </div>

                            <div className="align-item-center mt-7">
                                {/* <div className="pl-5" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ fontSize: '1.100rem' }}>Initiative By</h4>
                                    <img src="assets/logo/Final-Logo.png" className="max-h-120px" alt="" height={50} style={{ marginLeft: 10, }} />
                                </div> */}
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ fontSize: '1.100rem' }}>Powered By</h4>
                                    <img src="assets/logo/xpertnest-logo.png" className="max-h-120px" alt="" height={50} style={{ marginLeft: 10, }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.alert &&
                    <SweetAlert
                        success
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        confirmBtnBsStyle="primary"
                        cancelBtnBsStyle="light"
                        reverseButtons={true}
                        confirmBtnStyle={{ backgroundColor: '#28a745', borderColor: 'rgb(76, 174, 76)', color: '#fff' }}
                        onConfirm={() => this.onRedirectCompanyEdit()}
                        onCancel={() => this.onRedirectDashboard()}
                    >
                        Do you want to update company detail now ?
                    </SweetAlert>
                }
            </>
        )
    }
}
