import React, { Component } from 'react';
import Routes from './Routes/routes';
import { Route, BrowserRouter as Router, Redirect, withRouter } from 'react-router-dom';
import loginRoute from './Routes/loginRoute';
import Login from './Pages/Login';
import JobDetail from './Pages/JobDetail';
import PostDetail from './Pages/PostDetail';
import Support from './Pages/Support';
import ResetPassword from './Pages/ResetPassword';
import ForgotPassword from './Pages/ForgotPassword';
import Secured from './Pages/Secured';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak';
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLogin: false,
      loading: true,
      token: ''
    }
  }

  async componentDidMount() {
    let user = await localStorage.getItem('user');
    user = await JSON.parse(user)
    if (user) {
      await this.setState({ isLogin: true })
      await this.checkJobPath();
    } else {
      console.log(this.state.isLogin)

    }
  }

  checkJobPath = () => {
    let pathNames = window.location.pathname.split('/') || [];
    let pathName = pathNames.length > 0 ? pathNames[1] : '';
    console.log(pathName)
    if (pathName == 'Job') {
      console.log(pathName)
      window.location = '/JobDetails/' + pathNames[2];
      // this.props.history.replace('#/Job/' + pathNames[2])
    }
    if (pathName == 'PostDetail') {
      console.log(pathName)
      window.location = '/post/' + pathNames[2];
      // this.props.history.replace('#/Job/' + pathNames[2])
    }
  }

  onKeycloakEvent = (event, error) => {
    console.log('onKeycloakEvent', event, error)
    console.log(`Keycloak Event ${event}`);
    if (event && event === 'onReady') {
      this.setState({ keycloakReady: true })
    }
  }

  onKeycloakTokens = (tokens) => {
    console.log('onKeycloakTokens', tokens)
    this.setState({ token: tokens })
  }

  render() {
    return (
      // <Router forceRefresh={true}>
      //   {
      //     this.state.isLogin ?
      //       <Route path="/" name='App' exact component={Routes}></Route>
      //       : <Route path="/" name='App' exact component={loginRoute}></Route>
      //   }
      // </Router>
      

        <Router basename="/" forceRefresh={true}>
          {
            this.state.isLogin ?
              <Route name='Base' exact component={Routes}></Route>
              :
              <>
                <Route path="/Job/:id" name="JobDetail" component={JobDetail}></Route>
                <Route path="/PostDetail/:id" name="JobDetail" component={PostDetail}></Route>
                <Route path="/resetpassword" name='ResetPassword' component={ResetPassword}></Route>
                <Route path="/" exact name='App' component={Login}></Route>
                <Route path="/login" name='App' component={Login}></Route>
                <Route path="/support" name="Support" component={Support}></Route>
                <Route path="/forgotpassword" name='ForgotPassword' component={ForgotPassword}></Route>
                <Route path="/secured" name='Secured' component={Secured}></Route>
              </>
          }
        </Router>
      
    );

  }

}

export default App;
