import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AllFunction from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import Axios from 'axios';
import Auth from '../Utilities/Auth';
import Store from './store';
import MessageAlert from '../Components/MessageAlert';
import Autocomplete from "react-google-autocomplete";

class Profile extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            tab: 'PersonalInformation',
            dataLoading: false,
            loading: false,
            userId: '',
            token: '',
            user: '',
            firstName: '',
            lastName: '',
            mobileNo: '',
            companyName: '',
            designation: '',
            oldPassword: '',
            newPassword: '',
            cPassword: '',
            businesses: [],
            occupationType: '',
            uploadedProfileImage: '',
            imgae_url: '',
            profileImage: '',
            errors: {},
            city: '',
            state: '',
            country: '',
            street_address: "",
            countryList: [],
            stateList: [],
            cityList: [],
            followingList: [],
            followerList: [],
            searchName: '',
            whatsAppLink: '',
            facebookLink: '',
            twitterLink: '',
            linkedinLink: '',
            success: false,
            successMsg: '',
            collegeName: '',
            study: ''

        }
        this.store = Store;
    }

    onChangeTab = async (tab) => {
        await this.setState({ tab });
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
        const params = new URLSearchParams(this.props.location.search);
        const tab = params.get('tab');
        // console.log("=====", this.props.location.search)
        if (tab !== null && tab !== '') {
            await this.setState({ tab })
        }
        await this.store.setSubHeader("Profile", ["Profile", "Update Your Profile"]);

        const script2 = document.createElement('script');
        script2.src = process.env.PUBLIC_URL + "assets/js/pages/custom/profile/profile.js"
        document.body.appendChild(script2);
    }

    getUserCredintials = async () => {
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        const token = await localStorage.getItem('token');
        const userId = user._id;
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ userId, token });
            await this.getUserDetail()
            await this.getUserBusiness();
        }
    }

    getUserDetail = async () => {
        this.setState({ dataLoading: true })
        let errors = {};
        fetch(Constants.url + 'user/' + this.state.userId,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        dataLoading: false, user: responseData.users,
                        firstName: responseData.users.firstName || '',
                        lastName: responseData.users.lastName || '',
                        mobileNo: responseData.users.mobileNo || '',
                        city: responseData.users.city || '',
                        state: responseData.users.state || '',
                        country: responseData.users.country || '',
                        occupationType: responseData.users.occupationType || '',
                        uploadedProfileImage: responseData.users.profileImage || '',
                        companyName: responseData.users.company ? responseData.users.company.companyName : '',
                        designation: responseData.users.company ? responseData.users.company.designation : '',
                        whatsAppLink: responseData.users.whatsAppLink || '',
                        facebookLink: responseData.users.facebookLink || '',
                        twitterLink: responseData.users.twitterLink || '',
                        linkedinLink: responseData.users.linkedinLink || '',
                        street_address: responseData.users.street_address || "",
                        collegeName: responseData.users.collegeName || "",
                        study: responseData.users.study || "",
                    });
                    // if(this.state.country != ''){
                    //     this.getStateList();
                    // }
                    // console.log(this.state.uploadedProfileImage)
                    // window.location = '/';
                }
                else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     * Get All Business of login user
     */
    getUserBusiness = async () => {
        this.setState({ dataLoading: true })
        let errors = {};
        fetch(Constants.url + 'userBusiness',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        dataLoading: false, businesses: responseData.Businesses,

                    });
                    // window.location = '/';
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     * _onProfileImageChange()
     * @param {*} e
     * @description This function set image url in state and file object in profileImage
     */
    _onProfileImageChange = async (e) => {
        let errors = {};

        if (e.target.files[0]['size'] > 2097152) {
            errors.profileImage = "File size exceeded 2 MB";
            this.setState({ errors: errors });
        }
        else {
            const file = this.myRef.current.files[0];
            const reader = new FileReader();

            reader.onloadend = async () => {
                await this.setState({
                    imgae_url: reader.result
                })
            }
            await this.setState({
                profileImage: e.target.files[0]
            })
            e.target.value = '';
            if (file) {
                reader.readAsDataURL(file);
            }
            else {
                this.setState({
                    imgae_url: ""
                })
            }
        }
    }

    /**
     *
     * @param {*} e
     * @description Remove selected image
     */
    _onRemoveImage = async (e) => {
        await this.setState({ imgae_url: '', profileImage: '' })
    }

    profileValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.firstName) {
            formIsValid = false;
            errors.firstName = "Please enter first name.";
        }

        if (!this.state.lastName) {
            formIsValid = false;
            errors.lastName = "Please enter last name.";
        }

        if (!this.state.mobileNo) {
            formIsValid = false;
            errors.mobileNo = "Please enter mobile no.";
        }

        if (!this.state.city) {
            formIsValid = false;
            errors.city = "Please enter city.";
        }

        if (!this.state.street_address) {
            formIsValid = false;
            errors.street_address = "Please enter your location.";
        }

        if (!this.state.state) {
            formIsValid = false;
            errors.state = "Please enter state.";
        }

        if (!this.state.country) {
            formIsValid = false;
            errors.country = "Please enter country.";
        }

        if (this.state.occupationType === 'employee') {
            if (!this.state.companyName) {
                formIsValid = false;
                errors.companyName = "Please enter company name.";
            }

            if (!this.state.designation) {
                formIsValid = false;
                errors.designation = "Please enter designation.";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onUpdateProfile = () => {
        if (this.profileValidation()) {
            this.setState({ loading: true })
            setTimeout(() => {
                window.blockPage()
            }, 300);
            let company = {
                companyName: this.state.companyName,
                designation: this.state.designation,
            }

            let formData = new FormData();
            formData.append('firstName', this.state.firstName);
            formData.append('lastName', this.state.lastName);
            formData.append('occupationType', this.state.occupationType);
            formData.append('mobileNo', this.state.mobileNo);
            formData.append('street_address', this.state.street_address);
            formData.append('city', this.state.city);
            formData.append('state', this.state.state);
            formData.append('country', this.state.country);
            formData.append('whatsAppLink', this.state.whatsAppLink);
            formData.append('facebookLink', this.state.facebookLink);
            formData.append('twitterLink', this.state.twitterLink);
            formData.append('linkedinLink', this.state.linkedinLink);
            formData.append('collegeName', this.state.collegeName);
            formData.append('study', this.state.study);
            if (this.state.profileImage !== '') {
                formData.append('profileImage', this.state.profileImage);
            }

            if (this.state.occupationType === "employee") {
                formData.append('company', JSON.stringify(company));
            }

            let errors = {};
            Axios({
                method: "POST",
                url: Constants.url + 'user/profileUpdate',
                data: formData,
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': this.state.token
                }
            }).then(response => {
                let responseData = response.data;
                // console.log("Profile Image", responseData)
                if (responseData.status) {
                    this.setState({ loading: false, success: true, successMsg: 'Profile Updated successfully.' });
                    localStorage.setItem('user', JSON.stringify(responseData.users));
                    window.location.reload();
                    this.getUserDetail();
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            }).catch((error) => {
                console.log(error);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
        }
    }

    changePasswordValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.oldPassword) {
            formIsValid = false;
            errors.oldPassword = "Please enter current password.";
        }

        if (!this.state.newPassword) {
            formIsValid = false;
            errors.newPassword = "Please enter new password.";
        }

        if (!this.state.cPassword) {
            formIsValid = false;
            errors.cPassword = "Please enter confirm password.";
        }

        if (this.state.newPassword !== this.state.cPassword) {
            formIsValid = false;
            errors.cPassword = "Retype your password.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onChangePassword = () => {
        if (this.changePasswordValidation()) {
            this.setState({ loading: true })
            setTimeout(() => {
                window.blockPage()
            }, 300);
            let body = JSON.stringify({
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword,
            });
            let errors = {};
            fetch(Constants.url + 'user/changePassword',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.state.token
                    },
                    body: body
                }).then((response) => response.json())
                .then((responseData) => {
                    // console.log(responseData)
                    if (responseData.status) {
                        Auth.Logout();
                    }else if(!responseData.status && 'auth' in responseData){
                        Auth.Logout();
                    } 
                    else {
                        errors.error = responseData.error;
                        this.setState({ errors: errors, loading: false })
                    }
                })
                .catch(err => {
                    this.setState({ loading: false })
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors })
                    console.log(err);
                });
        }
    }

    getFollowingList = async () => {
        this.setState({ listLoading: true, tab: 'Following' })
        let errors = {};
        setTimeout(() => {
            window.blockPage()
        }, 300);
        fetch(Constants.url + 'following',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        followingList: responseData.follows
                    });
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    getFollowerList = async () => {
        this.setState({ listLoading: true, tab: 'Followers' })
        let errors = {};
        setTimeout(() => {
            window.blockPage()
        }, 300);
        fetch(Constants.url + 'followers',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        followerList: responseData.follows
                    });
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    getAddressComponentData = (place) => {
        let address_components = place.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name })
            }
        })
        this.setState({ street_address: place.formatted_address, location: place.formatted_address });

    }

    render() {

        return (
            <>
                {/* <!--begin::Entry--> */}
                <div className="d-flex flex-column-fluid">
                    {/* <!--begin::Container--> */}
                    <div className="container">
                        {/* <!--begin::Profile Personal Information--> */}
                        <button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none mb-3" id="kt_subheader_mobile_toggle">
                            <span></span>
                        </button>
                        <span className="d-inline-block d-lg-none" style={{ color: '#fff', verticalAlign: 'middle' }}>Profile</span>
                        <div className="d-flex flex-row">
                            {/* <!--begin::Aside--> */}
                            <div className="flex-row-auto offcanvas-mobile w-350px w-xxl-350px col-sm-12" id="kt_profile_aside">
                                {/* <!--begin::Profile Card--> */}
                                <div className="card card-custom card-stretch">
                                    {/* <!--begin::Body--> */}
                                    <div className="card-body pt-4">
                                        {/* <!--begin::User--> */}
                                        <div className="d-flex align-items-center">
                                            <div className="symbol symbol-60 symbol-xxl-80 mr-5 align-self-start align-self-xxl-center">
                                                {
                                                    this.state.uploadedProfileImage ?
                                                        <div className="symbol-label shadow-sm" style={{ backgroundImage: `url(${Constants.ProfileImageUrl}${this.state.uploadedProfileImage})` }}></div>
                                                        :
                                                        <div className="symbol-label" style={{ backgroundImage: 'url(assets/noImage.png)' }}></div>
                                                }

                                                <i className="symbol-badge bg-success"></i>
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{this.state.user && this.state.user.firstName}</a>
                                                    <Link to={'/Chat'}>
                                                        <i className="flaticon2-chat-1 icon-md text-muted" data-toggle="tooltip" data-placement="right" title="" data-original-title="Click to learn more..."></i>
                                                    </Link>
                                                </div>
                                                <div className="text-muted">
                                                    {
                                                        this.state.user.occupationType === 'employee' ?
                                                            <span>{this.state.user.company ? this.state.user.company.designation + ' at ' + this.state.user.company.companyName : ''}</span>
                                                            :
                                                            this.state.user.occupationType === 'student' ? <span>{this.state.user.occupationType + ' at ' + this.state.user.collegeName}</span> :
                                                                this.state.user.occupationType === 'business' ? <span>{this.state.user.occupationType}</span> : this.state.user.otherType

                                                    }
                                                </div>

                                                {/* <div className="mt-2">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="btn btn-light-success align-items-center btn-sm btn-block py-1" onClick={() => this.getFollowerList()}>
                                                                <div className="d-flex flex-column text-center">
                                                                    <span className="text-dark-75 font-weight-bold font-size-sm">{this.state.user.follows}</span>
                                                                    <span className="font-weight-bolder font-size-sm">Followers</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="btn btn-light-success align-items-center btn-sm btn-block py-1" onClick={() => this.getFollowingList()}>
                                                                <div className="d-flex flex-column text-center">
                                                                    <span className="text-dark-75 font-weight-bold font-size-sm">{this.state.user.following}</span>
                                                                    <span className="font-weight-bolder font-size-sm">Following</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        {/* <!--end::User--> */}
                                        {/* <!--begin::Contact--> */}
                                        <div className="py-9">
                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <span className="font-weight-bold mr-2">Email:</span>
                                                <a href="#" className="text-muted text-hover-primary">{this.state.user.email}</a>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <span className="font-weight-bold mr-2">Contact:</span>
                                                <span className="text-muted">{this.state.user.mobileNo ?? '-'}</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span className="font-weight-bold mr-2">Location:</span>
                                                <span className="text-muted" style={{ textTransform: 'capitalize' }}>{this.state.user.city ?? '-'}</span>
                                            </div>
                                        </div>
                                        {/* <!--end::Contact--> */}
                                        {/* <!--begin::Nav--> */}
                                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                            <div className="navi-item mb-2">
                                                <span className={`navi-link py-4 ${this.state.tab === 'PersonalInformation' && 'active'}`} style={{ cursor: 'pointer' }} onClick={() => this.onChangeTab('PersonalInformation')}>
                                                    <span className="navi-icon mr-2">
                                                        <span className="svg-icon">
                                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg--> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                    <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                    <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero" />
                                                                </g>
                                                            </svg>
                                                            {/* <!--end::Svg Icon--> */}
                                                        </span>
                                                    </span>
                                                    <span className="navi-text font-size-lg">Personal Information</span>
                                                </span>
                                            </div>
                                            {/* <div className="navi-item mb-2">
                                                <span className={`navi-link py-4 ${this.state.tab === 'AccountInformation' && 'active'}`} style={{ cursor: 'pointer' }} onClick={() => this.onChangeTab('AccountInformation')}>
                                                    <span className="navi-icon mr-2">
                                                        <span className="svg-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3" />
                                                                    <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    <span className="navi-text font-size-lg">Account Information</span>
                                                </span>
                                            </div> */}
                                            <div className="navi-item mb-2">
                                                <span className={`navi-link py-4 ${this.state.tab === 'ChangePassword' && 'active'}`} style={{ cursor: 'pointer' }} onClick={() => this.onChangeTab('ChangePassword')}>
                                                    <span className="navi-icon mr-2">
                                                        <span className="svg-icon">
                                                            {/* <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Shield-user.svg--> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3" />
                                                                    <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z" fill="#000000" opacity="0.3" />
                                                                    <path d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" fill="#000000" opacity="0.3" />
                                                                </g>
                                                            </svg>
                                                            {/* <!--end::Svg Icon--> */}
                                                        </span>
                                                    </span>
                                                    <span className="navi-text font-size-lg">Change Password</span>
                                                </span>
                                            </div>
                                            <div className="navi-item mb-2">
                                                <span className={`navi-link py-4 ${this.state.tab === 'Business' && 'active'}`} style={{ cursor: 'pointer' }} onClick={() => this.onChangeTab('Business')}>
                                                    <span className="navi-icon mr-2">
                                                        <span className="svg-icon svg-icon-2x">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z" fill="#000000" opacity="0.3" />
                                                                    <path d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z" fill="#000000" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    <span className="navi-text font-size-lg">Business Information</span>
                                                </span>
                                            </div>
                                        </div>
                                        {/* <!--end::Nav--> */}
                                    </div>
                                    {/* <!--end::Body--> */}
                                </div>
                                {/* <!--end::Profile Card--> */}
                            </div>
                            {/* <!--end::Aside--> */}
                            {/* <!--begin::Content--> */}

                            {
                                this.state.tab === 'PersonalInformation' ?
                                    <div className="flex-row-fluid ml-lg-8">
                                        <div className="card card-custom card-stretch">
                                            <div className="card-header py-3">
                                                <div className="card-title align-items-start flex-column">
                                                    <h3 className="card-label font-weight-bolder text-dark">Personal Information</h3>
                                                    <span className="text-muted font-weight-bold font-size-sm mt-1">Update your personal information</span>
                                                </div>
                                                <div className="card-toolbar">
                                                    {
                                                        this.state.loading ?
                                                            <button type="button" className="btn btn-light-success spinner spinner-darker-success spinner-right mr-3">
                                                                Save Changes
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-light-success mr-2" onClick={() => { this.onUpdateProfile() }}>Save Changes</button>
                                                    }

                                                    <button type="reset" className="btn btn-secondary">Cancel</button>
                                                </div>
                                            </div>
                                            <form className="form">
                                                <div className="card-body">
                                                    <div className="col-md-12">
                                                        {
                                                            this.state.success &&
                                                            <MessageAlert children={this.state.successMsg} variant="success" />
                                                        }
                                                    </div>
                                                    {/* <div className="row">
                                                        <label className="col-xl-3"></label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <h5 className="font-weight-bold mb-6">Customer Info</h5>
                                                        </div>
                                                    </div> */}
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Profile Image</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: 'url(assets/media/users/blank.png)' }}>

                                                                {/* <div className="image-input-wrapper" style={{ backgroundImage: 'url(assets/media/users/300_21.jpg)' }}></div> */}
                                                                {this.state.imgae_url ?
                                                                    <div className="image-input-wrapper" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.state.imgae_url})`, backgroundPosition: 'center' }}></div>
                                                                    :
                                                                    this.state.uploadedProfileImage ?
                                                                        <div className="image-input-wrapper" style={{ backgroundImage: `url(${Constants.ProfileImageUrl}${this.state.uploadedProfileImage})`, backgroundPosition: 'center' }}></div>
                                                                        : <div className="image-input-wrapper" style={{ backgroundImage: 'url(assets/noImage.png)', backgroundPosition: 'center' }}></div>
                                                                }
                                                                <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                                                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                                                    <input type="file" name="profileImage" accept=".png, .jpg, .jpeg" ref={this.myRef} onChange={(e) => this._onProfileImageChange(e)} />
                                                                    {/* <input type="hidden" name="profile_avatar_remove" /> */}
                                                                </label>
                                                                {
                                                                    this.state.imgae_url &&

                                                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-toggle="tooltip" title="Cancel Image" onClick={(e) => this._onRemoveImage(e)} style={{ position: 'absolute', right: '-10px', bottom: '-5px' }}>
                                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                    </span>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">First Name</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.firstName} name="firstName" />
                                                            {this.state.errors.firstName && <span className="text-danger pl-2">{this.state.errors.firstName}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Last Name</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.lastName} name="lastName" />
                                                            {this.state.errors.lastName && <span className="text-danger pl-2">{this.state.errors.lastName}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Contact Phone</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <div className="input-group input-group-lg input-group-solid">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-phone"></i>
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control form-control-lg form-control-solid" onChange={(event) => AllFunction.onChange(event, this, "Number")} value={this.state.mobileNo} name="mobileNo" placeholder="Phone" />
                                                            </div>
                                                            {this.state.errors.mobileNo && <span className="text-danger pl-2">{this.state.errors.mobileNo}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Location</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <Autocomplete
                                                                apiKey={Constants.GOOGLE_MAPS_API_KEY}
                                                                style={{ width: "100%" }}
                                                                onPlaceSelected={(place) => {
                                                                    this.getAddressComponentData(place)
                                                                }}
                                                                className="form-control form-control-lg"
                                                                name="street_address"
                                                                defaultValue={this.state.street_address}
                                                            />
                                                            {this.state.errors.street_address && <span className="text-danger pl-2">{this.state.errors.street_address}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Country</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input type="text" className="form-control form-control-lg" name="country" placeholder="Country" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.country} readOnly />

                                                            {/* <select className="form-control form-control-lg" name="country" autoComplete="off" onChange={(event) => this.onChangeCountry(event)} value={this.state.country}>
                                                                <option value="">Select Country</option>
                                                                {
                                                                    this.state.countryList.map((list, index) => {
                                                                        return (
                                                                            <option value={list.name} key={index}>{list.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select> */}
                                                            {this.state.errors.country && <span className="text-danger pl-2">{this.state.errors.country}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">State</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input type="text" className="form-control form-control-lg" name="state" placeholder="State" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.state} readOnly />

                                                            {/* <select className="form-control form-control-lg" name="state" autoComplete="off" onChange={(event) => this.onChangeState(event)} value={this.state.state}>
                                                                <option value="">Select State</option>
                                                                {
                                                                    this.state.stateList.map((list, index) => {
                                                                        return (
                                                                            <option value={list.name} key={index}>{list.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select> */}
                                                            {this.state.errors.state && <span className="text-danger pl-2">{this.state.errors.state}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">City</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input type="text" className="form-control form-control-lg" name="city" placeholder="City" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.city} readOnly />

                                                            {/* <select className="form-control form-control-lg" name="city" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.city}>
                                                                <option value="">Select City</option>
                                                                {
                                                                    this.state.cityList.map((list, index) => {
                                                                        return (
                                                                            <option value={list.name} key={index}>{list.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select> */}
                                                            {this.state.errors.city && <span className="text-danger pl-2">{this.state.errors.city}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Email Address</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <div className="input-group input-group-lg input-group-solid">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-at"></i>
                                                                    </span>
                                                                </div>
                                                                <label type="text" className="form-control form-control-lg form-control-solid">{this.state.user.email ?? '-'}</label>
                                                            </div>
                                                            <span className="form-text text-muted">We'll never share your email with anyone else.</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.occupationType === 'employee' &&
                                                        <>
                                                            <div className="row">
                                                                <label className="col-xl-3"></label>
                                                                <div className="col-lg-9 col-xl-6">
                                                                    <h5 className="font-weight-bold mt-10 mb-6">Company Info</h5>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-xl-3 col-lg-3 col-form-label">Company Name</label>
                                                                <div className="col-lg-9 col-xl-6">
                                                                    <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.companyName} name="companyName" placeholder='Company Name' />
                                                                    {this.state.errors.companyName && <span className="text-danger pl-2">{this.state.errors.companyName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-xl-3 col-lg-3 col-form-label">Designation</label>
                                                                <div className="col-lg-9 col-xl-6">
                                                                    <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.designation} name="designation" placeholder="Designation" />
                                                                    {this.state.errors.designation && <span className="text-danger pl-2">{this.state.errors.designation}</span>}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        this.state.occupationType === 'student' &&
                                                        <>
                                                            <div className="row">
                                                                <label className="col-xl-3"></label>
                                                                <div className="col-lg-9 col-xl-6">
                                                                    <h5 className="font-weight-bold mt-10 mb-6">Education Info</h5>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-xl-3 col-lg-3 col-form-label">College Name</label>
                                                                <div className="col-lg-9 col-xl-6">
                                                                    <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.collegeName} name="collegeName" placeholder='Company Name' />
                                                                    {this.state.errors.collegeName && <span className="text-danger pl-2">{this.state.errors.collegeName}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-xl-3 col-lg-3 col-form-label">Study</label>
                                                                <div className="col-lg-9 col-xl-6">
                                                                    <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.study} name="study" placeholder="Education" />
                                                                    {this.state.errors.study && <span className="text-danger pl-2">{this.state.errors.study}</span>}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    <div className="row">
                                                        <label className="col-xl-3"></label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <h5 className="font-weight-bold mt-10 mb-6">Social Info</h5>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">WhatsApp Link</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this, 'Number')} value={this.state.whatsAppLink} name="whatsAppLink" placeholder='WhatsApp Link' />
                                                            {this.state.errors.whatsAppLink && <span className="text-danger pl-2">{this.state.errors.whatsAppLink}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Facebook Link</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.facebookLink} name="facebookLink" placeholder='Facebook Link' />
                                                            {this.state.errors.facebookLink && <span className="text-danger pl-2">{this.state.errors.facebookLink}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Twitter Link</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.twitterLink} name="twitterLink" placeholder='Twitter Link' />
                                                            {this.state.errors.twitterLink && <span className="text-danger pl-2">{this.state.errors.twitterLink}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Linkedin Link</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input className="form-control form-control-lg form-control-solid" type="text" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.linkedinLink} name="linkedinLink" placeholder='Linkedin Link' />
                                                            {this.state.errors.linkedinLink && <span className="text-danger pl-2">{this.state.errors.linkedinLink}</span>}
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Company Site</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <div className="input-group input-group-lg input-group-solid">
                                                                <input type="text" className="form-control form-control-lg form-control-solid" placeholder="Website"  />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="card-footer">
                                                    {
                                                        this.state.loading ?
                                                            <button type="button" className="btn btn-light-success spinner spinner-darker-success spinner-right mr-3">
                                                                Save Changes
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-light-success mr-2" onClick={() => { this.onUpdateProfile() }}>Save Changes</button>
                                                    }

                                                    <button type="reset" className="btn btn-secondary">Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div> : ''
                            }

                            {/* <!--end::Content--> */}

                            {/* <!--begin::Content of Account Information--> */}
                            {
                                this.state.tab === 'AccountInformation' ?
                                    <div className="flex-row-fluid ml-lg-8">
                                        {/* <!--begin::Card--> */}
                                        <div className="card card-custom">
                                            {/* <!--begin::Header--> */}
                                            <div className="card-header py-3">
                                                <div className="card-title align-items-start flex-column">
                                                    <h3 className="card-label font-weight-bolder text-dark">Account Information</h3>
                                                    <span className="text-muted font-weight-bold font-size-sm mt-1">Change your account settings</span>
                                                </div>
                                                <div className="card-toolbar">
                                                    {
                                                        this.state.loading ?
                                                            <button type="button" className="btn btn-light-success spinner spinner-darker-success spinner-right mr-3">
                                                                Save Changes
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-light-success mr-2">Save Changes</button>
                                                    }

                                                    <button type="reset" className="btn btn-secondary">Cancel</button>
                                                </div>
                                            </div>
                                            {/* <!--end::Header--> */}
                                            {/* <!--begin::Form--> */}
                                            <form className="form">
                                                <div className="card-body">
                                                    {/* <!--begin::Heading--> */}
                                                    <div className="row">
                                                        <label className="col-xl-3"></label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <h5 className="font-weight-bold mb-6">Account:</h5>
                                                        </div>
                                                    </div>
                                                    {/* <!--begin::Form Group--> */}
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Username</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <div className="">
                                                                <input className="form-control form-control-lg form-control-solid" type="text" defaultValue="nick84" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!--begin::Form Group--> */}
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Email Address</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <div className="input-group input-group-lg input-group-solid">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">
                                                                        <i className="la la-at"></i>
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control form-control-lg form-control-solid" defaultValue="nick.watson@loop.com" placeholder="Email" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row align-items-center">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Communication</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <div className="checkbox-inline">
                                                                <label className="checkbox">
                                                                    <input type="checkbox" checked="checked" />
                                                                    <span></span>Email</label>
                                                                <label className="checkbox">
                                                                    <input type="checkbox" checked="checked" />
                                                                    <span></span>SMS</label>
                                                                <label className="checkbox">
                                                                    <input type="checkbox" />
                                                                    <span></span>Phone</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!--begin::Form Group--> */}
                                                    <div className="separator separator-dashed my-5"></div>
                                                    {/* <!--begin::Form Group--> */}
                                                    <div className="row">
                                                        <label className="col-xl-3"></label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <h5 className="font-weight-bold mb-6">Security:</h5>
                                                        </div>
                                                    </div>
                                                    {/* <!--begin::Form Group--> */}
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Login verification</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <button type="button" className="btn btn-light-primary font-weight-bold btn-sm">Setup login verification</button>
                                                            <p className="form-text text-muted pt-2">After you log in, you will be asked for additional information to confirm your identity and protect your account from being compromised.
                                                                <a href="#" className="font-weight-bold">Learn more</a>.</p>
                                                        </div>
                                                    </div>
                                                    {/* <!--begin::Form Group--> */}
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label">Password reset verification</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <div className="checkbox-inline">
                                                                <label className="checkbox m-0">
                                                                    <input type="checkbox" />
                                                                    <span></span>Require personal information to reset your password.</label>
                                                            </div>
                                                            <p className="form-text text-muted py-2">For extra security, this requires you to confirm your email or phone number when you reset your password.
                                                                <a href="#" className="font-weight-boldk">Learn more</a>.</p>
                                                            <button type="button" className="btn btn-light-danger font-weight-bold btn-sm">Deactivate your account ?</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            {/* <!--end::Form--> */}
                                        </div>
                                        {/* <!--end::Card--> */}
                                    </div> : ''
                            }

                            {/* <!--end::Content--> */}

                            {/* <!--begin::Content of Change Password--> */}

                            {
                                this.state.tab === 'ChangePassword' ?
                                    <div className="flex-row-fluid ml-lg-8">
                                        {/* <!--begin::Card--> */}
                                        <div className="card card-custom">
                                            {/* <!--begin::Header--> */}
                                            <div className="card-header py-3">
                                                <div className="card-title align-items-start flex-column">
                                                    <h3 className="card-label font-weight-bolder text-dark">Change Password</h3>
                                                    <span className="text-muted font-weight-bold font-size-sm mt-1">Change your account password</span>
                                                </div>
                                                <div className="card-toolbar">
                                                    {
                                                        this.state.loading ?
                                                            <button type="button" className="btn btn-light-success spinner spinner-darker-success spinner-right mr-3">
                                                                Save Changes
                                                            </button>
                                                            :
                                                            <button type="reset" className="btn btn-light-success mr-2" onClick={() => { this.onChangePassword() }}>Save Changes</button>
                                                    }

                                                    <button type="reset" className="btn btn-secondary">Cancel</button>
                                                </div>
                                            </div>
                                            {/* <!--end::Header--> */}
                                            {/* <!--begin::Form--> */}
                                            <form className="form">
                                                <div className="card-body">
                                                    {this.state.errors.error &&
                                                        <div className="alert alert-danger text-left" role="alert">
                                                            <span className='pl-3'>{this.state.errors.error}</span>
                                                        </div>
                                                    }
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">Current Password</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input type="password" className="form-control form-control-lg form-control-solid mb-2" placeholder="Current password" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.oldPassword} name='oldPassword' />
                                                            {this.state.errors.oldPassword && <><span className="text-danger pl-2">{this.state.errors.oldPassword}</span><br /></>}
                                                            <a href="#" className="text-sm font-weight-bold">Forgot password ?</a>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">New Password</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input type="password" className="form-control form-control-lg form-control-solid" placeholder="New password" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.newPassword} name='newPassword' />
                                                            {this.state.errors.newPassword && <span className="text-danger pl-2">{this.state.errors.newPassword}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-lg-3 col-form-label text-alert">Verify Password</label>
                                                        <div className="col-lg-9 col-xl-6">
                                                            <input type="password" className="form-control form-control-lg form-control-solid" placeholder="Verify password" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.cPassword} name='cPassword' />
                                                            {this.state.errors.cPassword && <span className="text-danger pl-2">{this.state.errors.cPassword}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    {
                                                        this.state.loading ?
                                                            <button type="button" className="btn btn-light-primary spinner spinner-primary spinner-right mr-3">
                                                                Save Changes
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-light-primary mr-2" onClick={() => { this.onChangePassword() }}>Save Changes</button>
                                                    }

                                                    <button type="button" className="btn btn-secondary">Cancel</button>
                                                </div>
                                            </form>
                                            {/* <!--end::Form--> */}
                                        </div>
                                    </div> : ''
                            }

                            {
                                this.state.tab === 'Business' ?
                                    <div className="flex-row-fluid ml-lg-8">
                                        {/* <!--begin::Card--> */}
                                        <div className="card card-custom" style={{ minHeight: 500 }}>
                                            {/* <!--begin::Header--> */}
                                            <div className="card-header py-3">
                                                <div className="card-title align-items-start flex-column">
                                                    <h3 className="card-label font-weight-bolder text-dark">Business Information</h3>
                                                    <span className="text-muted font-weight-bold font-size-sm mt-1">Add your new business</span>
                                                </div>
                                                <div className="card-toolbar">

                                                    <Link to={'/createCompany'} className="btn btn-light-success">
                                                        <i className="flaticon2-plus-1"></i> Add Business
                                                    </Link>
                                                </div>
                                            </div>
                                            {/* <!--end::Header--> */}
                                            {/* <!--begin::Form--> */}
                                            <div className="card-body">

                                                <div>
                                                    {
                                                        this.state.dataLoading ? 'Loading...' :
                                                            this.state.businesses.map((business, index) => {
                                                                return (
                                                                    <div key={index} className="col-sm-12 col-md-6 col-xl-6 col-xxl-6 p-0">
                                                                        <div key={index} className='card card-custom'>
                                                                            <div className="col-md-12 p-5">
                                                                                <div className="row">
                                                                                    <div className="col-sm-6 col-md-3 col-xl-3 col-xxl-3">
                                                                                        <div className="symbol symbol-50 symbol-lg-60 symbol-light">
                                                                                            <span className="symbol-label">
                                                                                                {
                                                                                                    business.companyLogo ?
                                                                                                        <img src={`${Constants.CompanyLogoUrl}${business.companyLogo}`} className="h-50 align-self-center" alt="" />
                                                                                                        :
                                                                                                        <img src="assets/noImage.png" className="h-50 align-self-center" alt="" />
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-md-6 col-xl-9 col-xxl-9" style={{ zIndex: '0' }}>
                                                                                        <div className="align-items-center justify-content-between flex-wrap mt-0">
                                                                                            <div className="mr-0">
                                                                                                <Link to={'/EditCompany/' + business._id} className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3 text-hover-primary">
                                                                                                    {business.businessName}
                                                                                                    <i className="flaticon2-correct text-success icon-md ml-2"></i>
                                                                                                </Link>
                                                                                                <div className="d-flex flex-wrap my-1">
                                                                                                    <span className="text-muted font-weight-bold mr-lg-0 mr-0 mb-lg-0 mb-2">
                                                                                                        <span className="svg-icon svg-icon-success mr-1">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                                                    <path d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z" fill="#000000" fillRule="nonzero" />
                                                                                                                    <circle fill="#000000" opacity="0.3" cx="12" cy="10" r="6" />
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                        </span>{business.website}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="d-flex flex-wrap my-1">
                                                                                                    <span className="text-muted font-weight-bold mr-lg-0 mr-0 mb-lg-0 mb-2">
                                                                                                        <span className="svg-icon svg-icon-success mr-1">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                                                    <path d="M11.914857,14.1427403 L14.1188827,11.9387145 C14.7276032,11.329994 14.8785122,10.4000511 14.4935235,9.63007378 L14.3686433,9.38031323 C13.9836546,8.61033591 14.1345636,7.680393 14.7432841,7.07167248 L17.4760882,4.33886839 C17.6713503,4.14360624 17.9879328,4.14360624 18.183195,4.33886839 C18.2211956,4.37686904 18.2528214,4.42074752 18.2768552,4.46881498 L19.3808309,6.67676638 C20.2253855,8.3658756 19.8943345,10.4059034 18.5589765,11.7412615 L12.560151,17.740087 C11.1066115,19.1936265 8.95659008,19.7011777 7.00646221,19.0511351 L4.5919826,18.2463085 C4.33001094,18.1589846 4.18843095,17.8758246 4.27575484,17.613853 C4.30030124,17.5402138 4.34165566,17.4733009 4.39654309,17.4184135 L7.04781491,14.7671417 C7.65653544,14.1584211 8.58647835,14.0075122 9.35645567,14.3925008 L9.60621621,14.5173811 C10.3761935,14.9023698 11.3061364,14.7514608 11.914857,14.1427403 Z" fill="#000000" />
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                        </span>{business.mobileNo ?? '-'}
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="d-flex flex-wrap my-1">
                                                                                                    <span className="text-muted font-weight-bold mr-lg-0 mr-0 mb-lg-0 mb-2">
                                                                                                        <span className="svg-icon svg-icon-success svg-icon-2x">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                                                    <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fillRule="nonzero" />
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                        </span>{business.city ?? '-'}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <Link to={`/Company/${business._id}`} className="btn btn-light-primary">
                                                                                                    View
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            </div>
                                            {/* <!--end::Form--> */}
                                        </div>
                                    </div> : ''
                            }

                            {/* <!--end::Content-->     */}

                            {
                                this.state.tab === 'Followers' ?
                                    <div className="flex-row-fluid ml-lg-8">
                                        {/* <!--begin::Card--> */}
                                        <div className="card card-custom" style={{ minHeight: 500 }}>
                                            {/* <!--begin::Header--> */}
                                            <div className="card-header py-3">
                                                <div className="card-title align-items-start flex-column">
                                                    <h3 className="card-label font-weight-bolder text-dark">Followers</h3>
                                                    <span className="text-muted font-weight-bold font-size-sm mt-1">Your Followers {this.state.user.follows}</span>
                                                </div>
                                                <div className="card-toolbar">
                                                    <div className="col-md-12 my-2 my-md-0">
                                                        <div className="input-icon">
                                                            <input type="text" className="form-control form-control-solid" placeholder="Search followers" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.searchName} name="searchName" />
                                                            <span>
                                                                <i className="flaticon2-search-1 text-muted"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!--end::Header--> */}
                                            <div className="card-body pt-5">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    {
                                                        this.state.dataLoading ? '' :
                                                            this.state.followerList.filter((follow, indx) => { return follow.userId.firstName.toLowerCase().includes(this.state.searchName.toLowerCase()) || follow.userId.lastName.toLowerCase().includes(this.state.searchName.toLowerCase()) }).map((follower, index) => {
                                                                return (
                                                                    <div className="col-md-6 pl-0" key={index}>
                                                                        <div className="d-flex flex-wrap align-items-center mb-5">
                                                                            <div className="symbol symbol-50 flex-shrink-0">
                                                                                {
                                                                                    follower.userId.profileImage ?
                                                                                        <div className="symbol-label" style={{ backgroundImage: `url(${Constants.ProfileImageUrl + follower.userId.profileImage})` }}></div>
                                                                                        :
                                                                                        <div className="symbol-label" style={{ backgroundImage: 'url(assets/media/users/default.jpg)' }}></div>
                                                                                }
                                                                            </div>
                                                                            <div className="d-flex flex-column ml-4 flex-grow-1 mr-2">
                                                                                <a href="#" className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">{follower.userId.firstName} {follower.userId.lastName}</a>
                                                                                <span className="text-muted font-weight-bold">{follower.userId.email}</span>
                                                                            </div>
                                                                            {/* <button type="button" className="btn btn-outline-secondary">Remove</button> */}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''
                            }

                            {
                                this.state.tab === 'Following' ?
                                    <div className="flex-row-fluid ml-lg-8">
                                        {/* <!--begin::Card--> */}
                                        <div className="card card-custom" style={{ minHeight: 500 }}>
                                            {/* <!--begin::Header--> */}
                                            <div className="card-header py-3">
                                                <div className="card-title align-items-start flex-column">
                                                    <h3 className="card-label font-weight-bolder text-dark">Following</h3>
                                                    <span className="text-muted font-weight-bold font-size-sm mt-1">Following  {this.state.user.following}</span>
                                                </div>
                                                <div className="card-toolbar">
                                                    <div className="col-md-12 my-2 my-md-0">
                                                        <div className="input-icon">
                                                            <input type="text" className="form-control form-control-solid" placeholder="Search following" name="searchName" value={this.state.searchName} onChange={(event) => { AllFunction.onChange(event, this) }} />
                                                            <span>
                                                                <i className="flaticon2-search-1 text-muted"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!--end::Header--> */}
                                            <div className="card-body pt-5">
                                                <div className="d-flex justify-content-between align-items-center row">
                                                    {
                                                        this.state.dataLoading ? '' :
                                                            this.state.followingList.filter((follow, indx) => { return follow.followUserId.firstName.toLowerCase().includes(this.state.searchName.toLowerCase()) || follow.followUserId.lastName.toLowerCase().includes(this.state.searchName.toLowerCase()) }).map((following, index) => {
                                                                return (
                                                                    <div className="col-md-6 pl-0" key={index}>
                                                                        <div className="d-flex flex-wrap align-items-center mb-5">
                                                                            <div className="symbol symbol-50 flex-shrink-0">
                                                                                {
                                                                                    following.followUserId.profileImage ?
                                                                                        <div className="symbol-label" style={{ backgroundImage: `url(${Constants.ProfileImageUrl}${following.followUserId.profileImage})` }}></div>
                                                                                        :
                                                                                        <div className="symbol-label" style={{ backgroundImage: 'url(assets/media/users/default.jpg)' }}></div>
                                                                                }

                                                                            </div>
                                                                            <div className="d-flex flex-column ml-4 flex-grow-1 mr-2">
                                                                                <a href="#" className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">{following.followUserId.firstName} {following.followUserId.lastName}</a>
                                                                                <span className="text-muted font-weight-bold">{following.followUserId.email}</span>
                                                                            </div>
                                                                            {/* <button type="button" className="btn btn-outline-secondary">Following</button> */}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''
                            }

                        </div>
                        {/* <!--end::Profile Personal Information--> */}
                    </div>
                    {/* <!--end::Container--> */}
                </div>
                {/* <!--end::Entry--> */}
            </>
        );
    }
}

export default Profile;
