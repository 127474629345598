import React, { Component } from 'react';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';
import { Link } from 'react-router-dom';

class PostCard extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const postList = this.props.postList || [];
        const chatList = this.props.chatList || [];
        const userId = this.props.userId || '';
        if (postList.length > 0) {
            return postList.map((post, index) => {
                let time = AllFunction.dateDifference(post.postDate);

                let titleHeight = post?.title ? post?.title.trim() == "" ? 0 : 50 : 0
                let descriptionHeight = post?.title ? post?.title.trim() == "" ? 130 : 80 : 130
                return (
                    <div className="col-xl-4 col-md-4 col-lg-4" key={index}>
                        <div className="card card-custom gutter-b shadow-sm">
                            <div className="card-header">
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50 flex-shrink-0 mr-4">
                                        {
                                            post?.businessId?.companyLogo ?
                                                <div className="symbol-label" style={{ backgroundImage: `url('${Constants.CompanyLogoUrl + post.businessId.companyLogo}')`, backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                                :
                                                <div className="symbol-label" style={{ backgroundImage: `url('assets/noImage.png')` }}></div>
                                        }
                                    </div>
                                    <div>
                                        <Link to={'/Company/' + post?.businessId?._id} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{post.businessId?.businessName}</Link>
                                        <span className="text-muted font-weight-bold d-block" style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: 170, whiteSpace: 'nowrap' }}><span data-toggle="tooltip" title={post.businessId?.services.join(",")}>{post.businessId?.services.join(",")}</span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <div className="flex-grow-1 pb-5 mt-0">
                                    <Link to={'/Post/' + post._id} className="text-dark font-weight-bolder text-hover-primary font-size-h6" >
                                        <span style={{ display : 'block', minHeight :titleHeight, height :titleHeight,  overflow: 'hidden', textOverflow: 'ellipsis', }}>{post.title} </span>
                                    </Link>
                                    <p className="text-dark-50 font-weight-normal font-size-lg mt-0 mb-0" style={{ display: '-webkit-box', height: descriptionHeight, WebkitBoxOrient: 'vertical', overflow: "hidden", textOverflow: 'ellipsis' }}>
                                        <span dangerouslySetInnerHTML={{ __html: post.description }}></span>
                                    </p>
                                </div>
                                <div className="flex-grow-1 pb-0 mt-0 d-flex justify-content-between">
                                    <div className="d-flex align-items-center"><i className="flaticon-calendar-with-a-clock-time-tools mr-2"></i> {time}</div>
                                    <Link to={'/Post/' + post._id} style={{ cursor : 'pointer' }} className="text-dark font-weight-bolder text-hover-primary">See More</Link>
                                </div>
                            </div>
                            <div className="card-footer col-md-12" style={{ padding: '1.5rem 2rem' }}>
                                <ul className="row pl-0">
                                    <li className="list-inline-item col-md-4 mr-0 text-center" >
                                        <a href="" className="text-dark">
                                            <p className="mb-0 text-muted" style={{ textOverflow: 'ellipsis', height : 30 }}><i className="flaticon2-user mr-2"></i><span className="text-dark-75" >By</span> {post.userId.firstName} </p>
                                        </a>
                                    </li>
                                    <li className="list-inline-item col-md-4 mr-0 text-center">
                                        <p className="text-muted mb-0"><i className="flaticon-eye mr-2"></i>{post.views ? post.views.length : 0} Views</p>
                                    </li>
                                    {/* <li className="list-inline-item col-md-4 mr-0 text-center">
                                        <p className="text-muted mb-0"><i className="flaticon2-calendar-1 mr-2"></i>10-Sep-2018</p>
                                    </li> */}
                                    <li className="list-inline-item col-md-4 mr-0 text-center">
                                        { 
                                            userId == post.userId._id ?
                                                <Link to={'/EditPost/' + post._id} ><p className="text-muted mb-0"><i className="flaticon-edit-1 mr-2"></i>Edit</p> </Link>
                                                :
                                                AllFunction.includesMultiDimension(chatList, post.userId._id) ?
                                                    <Link to={'/Chat?receiverId=' + post.userId._id + '&Name=' + post.userId.firstName}><p className="text-muted mb-0"><i className="flaticon2-chat-1 mr-2"></i>Message</p></Link>
                                                    :
                                                    <Link to={'/Chat?receiverId=' + post.userId._id + '&Name=' + post.userId.firstName + '&post=' + post._id}><p className="text-muted mb-0"><i className="flaticon2-chat-1 mr-2"></i>Message</p></Link>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        else {
            return <div></div>
        }
    }
}

export default PostCard;