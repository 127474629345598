import React, { Component } from "react";

let Active = {
  color: "#ffffff",
  backgroundColor: "#004680",
  padding: "5px 10px",
  marginRight: "2px",
  marginLeft: "2px",
  borderRadius: 6
}
let InActive = {
  color: "#000000",
  backgroundColor: "#999999",
  padding: "5px 10px",
  cursor: "pointer",
  marginRight: "2px",
  marginLeft: "2px",
  borderRadius: 6
}

let InActiveDis = {

  cursor: "not-allowed",

}

class Pagination extends Component {


  /**
 * pagination button view return
 * @param {string} buttonName 
 * @returns 
 */
  pagination(buttonName, that, FunctionName=null) {
    const { currentPage, listCount, listPerPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive } = that.state;

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(listCount / listPerPage); i++) {
      pageNumbers.push(i);
    }

    if (buttonName === "renderPageNumbers") {
      return pageNumbers.map(number => {
        if (currentPage === number) {
          return (
            <span
              key={number}
              id={number}
              className={"btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1"}
              onClick={that.handleClick}>
              {number}
            </span>
          )
        } else if ((number < upperPageBound + 1) && number > lowerPageBound) {
          return (
            <span
              key={number}
              id={number}
              className="btn btn-icon btn-sm border-0 btn-light btn-hover-primary mr-2 my-1"
              onClick={that.handleClick}>
              {number}
            </span>
          )
        }
      });
    }
    else if (buttonName === "pageIncrementBtn") {
      // let pageIncrementBtn = null;
      if (pageNumbers.length > upperPageBound) {
        return (<span className='btn btn-icon btn-sm border-0 btn-light btn-hover-primary mr-2 my-1'
          onClick={() => this.btnIncrementClick(that, FunctionName)}>
          &hellip;
        </span>)
      }
    }
    else if (buttonName === "pageDecrementBtn") {
      // let pageDecrementBtn = null;
      if (lowerPageBound >= 1) {
        return (<span className='btn btn-icon btn-sm border-0 btn-light btn-hover-primary mr-2 my-1'
          onClick={() => this.btnDecrementClick(that, FunctionName)}>
          &hellip;
        </span>)
      }
    }
    else if (buttonName === "renderPrevBtn") {
      // let renderPrevBtn = null;
      if (isPrevBtnActive === 'disabled') {
        return <span className={isPrevBtnActive + ' btn btn-icon btn-sm btn-light-primary mr-2 my-1'} style={InActiveDis}> <i className="fas fa-angle-left"></i> </span>
      }
      else {
        return <span className={isPrevBtnActive + 'btn btn-icon btn-sm btn-light-primary mr-2 my-1'} onClick={() => this.btnPrevClick(that, FunctionName)}> <i className="fas fa-angle-left"></i> </span>
      }
    }
    else if (buttonName === "renderNextBtn") {
      // let renderNextBtn = null;
      if (isNextBtnActive === 'disabled') {
        return <span className={isNextBtnActive + ' btn btn-icon btn-sm btn-light-primary mr-2 my-1'} style={InActiveDis}> <i className="fas fa-angle-right"></i> </span>
      }
      else {
        return <span className={isNextBtnActive + ' btn btn-icon btn-sm btn-light-primary mr-2 my-1'} onClick={() => this.btnNextClick(that, FunctionName)}> <i className="fas fa-angle-right"></i> </span>
      }
    }

  }
  search = async (event, data) => {

    let searchField = data?.searchField ? Object.keys(data.searchField) : [];
    searchField?.map(async (key, index) => {
      if (data.that.state[key] !== "") {
        console.log("before", key, data.that.state[key])
        await data.that.setState({
          [key]: ""
        })
      }
    });

    //   if (!Reg.specialCharacter.test(event?.target?.value)) {

    await data.that.setState({
      currentPage: 1,
      listPerPage: 10,
      upperPageBound: 5,
      lowerPageBound: 0,
      // listCount: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      pageBound: 5,
      stext: event.target.value
    });
    // if (data.that.state.stext === "") {
    //   await data.that.getData();
    // }

    //   }
  }
  listPerPage = async (event, that, FunctionName) => {
    console.log("hello")
    await that.setState({
      currentPage: 1,
      listPerPage: 3,
      upperPageBound: 5,
      lowerPageBound: 0,
      listCount: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      pageBound: 5,
      listPerPage: event.target.value
    });

    await that[!!FunctionName ? FunctionName : 'getData']()
  }
  setPrevAndNextBtnClass(listid, that) {
    let totalPage = Math.ceil(that.state.listCount / that.state.listPerPage);
    that.setState({ isNextBtnActive: 'disabled' });
    that.setState({ isPrevBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      that.setState({ isPrevBtnActive: '' });
    }
    else if (listid === 1 && totalPage > 1) {
      that.setState({ isNextBtnActive: '' });
    }
    else if (totalPage > 1) {
      that.setState({ isNextBtnActive: '' });
      that.setState({ isPrevBtnActive: '' });
    }
  }
  btnIncrementClick = async (that, FunctionName=null) => {
    that.setState({ upperPageBound: that.state.upperPageBound + that.state.pageBound });
    that.setState({ lowerPageBound: that.state.lowerPageBound + that.state.pageBound });
    let listid = that.state.upperPageBound + 1;
    await that.setState({ currentPage: listid });
    await that[!!FunctionName ? FunctionName : 'getData']();
    this.setPrevAndNextBtnClass(listid, that);
  }
  btnDecrementClick = async (that, FunctionName=null) => {
    that.setState({ upperPageBound: that.state.upperPageBound - that.state.pageBound });
    that.setState({ lowerPageBound: that.state.lowerPageBound - that.state.pageBound });
    let listid = that.state.upperPageBound - that.state.pageBound;
    await that.setState({ currentPage: listid });
    await that[!!FunctionName ? FunctionName : 'getData']();
    this.setPrevAndNextBtnClass(listid, that);
  }
  btnPrevClick = async (that, FunctionName=null) => {
    if ((that.state.currentPage - 1) % that.state.pageBound === 0) {
      that.setState({ upperPageBound: that.state.upperPageBound - that.state.pageBound });
      that.setState({ lowerPageBound: that.state.lowerPageBound - that.state.pageBound });
    }
    let listid = that.state.currentPage - 1;
    await that.setState({ currentPage: listid });
    await that[!!FunctionName ? FunctionName : 'getData']();
    this.setPrevAndNextBtnClass(listid, that);
  }
  btnNextClick = async (that, FunctionName=null) => {
    if ((that.state.currentPage + 1) > that.state.upperPageBound) {
      that.setState({ upperPageBound: that.state.upperPageBound + that.state.pageBound });
      that.setState({ lowerPageBound: that.state.lowerPageBound + that.state.pageBound });
    }
    let listid = that.state.currentPage + 1;
    await that.setState({ currentPage: listid });
    await that[!!FunctionName ? FunctionName : 'getData']();
    this.setPrevAndNextBtnClass(listid, that);
  }
  // pagination(that) {
  //   return (<div className="pull-right pr-3">
  //     {this.pagination("renderPrevBtn", that),
  //     this.pagination("pageDecrementBtn", that),
  //     this.pagination("renderPageNumbers", that),
  //     this.pagination("pageIncrementBtn", that),
  //     this.pagination("renderNextBtn", that)}
  //   </div>)
  // }
}

const PaginationFunction = new Pagination();
export default PaginationFunction;
