import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer" style={{bottom:0,position:'fixed',width:'100%'}}>
                    <div className="container align-items-center">
                        <div className="text-dark order-2 order-md-1" style={{float:'left'}}>
                            <span className="text-muted font-weight-bold mr-2">2021©</span>
                            <span className="font-weight-bold mr-2" style={{ fontSize: '1.100rem' }}>Initiative By : Vishv Umiya Foundation</span>
                        </div>
                        <div style={{ float:'right' }}>
                            <h4 style={{ fontSize: '1.100rem' }}>Powered By : Xpertnest</h4>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Footer;