import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import 'firebase/compat/auth';

var firebaseConfig = {
  apiKey: "AIzaSyAYlppPWXykvLx8w-S6GB9CQ-X9biBWDJY",

  authDomain: "businessconnect-318512.firebaseapp.com",

  projectId: "businessconnect-318512",

  storageBucket: "businessconnect-318512.appspot.com",

  messagingSenderId: "422227948430",

  appId: "1:422227948430:web:fcb4814d3ceff54ef76adf",

};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//   firebase.analytics();

export default firebase;
