import { action, makeObservable, observable } from "mobx";

class Store {
    heading = null;
    subTitle = [];
    setSubHeader = () => {}
    constructor() {
        makeObservable(this, {
            heading: observable,
            subTitle: observable,
            setSubHeader: action
        })
    }

    setSubHeader = (title = '', subTitle = []) => {
        this.heading = title
        this.subTitle = subTitle;
    }
}

const store = new Store();
export default store;
