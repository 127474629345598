import { observer } from "mobx-react";
import React from "react";

function SubHeader({ store }) {
    return (
        <div className="subheader py-2 py-lg-7 subheader-transparent" id="kt_subheader">
            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <div className="d-flex align-items-center flex-wrap mr-1">
                    <div className="d-flex flex-column">
                        <h2 className="text-white font-weight-bold my-2 mr-5">{store.heading}</h2>
                        <div className="d-flex align-items-center font-weight-bold my-2">
                            <a href="#" className="opacity-75 hover-opacity-100">
                                <i className="flaticon2-shelter text-white icon-1x"></i>
                            </a>
                            <span className="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                            {
                                store.subTitle.length > 0 &&
                                store.subTitle.map((sub, index) => {
                                    let len = store.subTitle.length - 1;
                                    return (
                                        <span key={index}>
                                            <a href="" className="text-white text-hover-white opacity-75 hover-opacity-100">{sub}</a>
                                            {len !== index && <span className="label label-dot label-sm bg-white opacity-75 mx-3"></span>}
                                        </span>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(SubHeader);