import React, { Component } from 'react';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';
import { Link } from 'react-router-dom';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,    
} from "react-share";

class JobCard extends Component {
    constructor(props) {
        super(props);
    }

    
    render() {
        const postList = this.props.postList || [];
        const chatList = this.props.chatList || [];
        const appList = this.props.appList || [];
        const userId = this.props.userId || '';
        if (postList.length > 0) {
            return postList.map((post, index) => {
                let time = AllFunction.dateDifference(post.date);
                //console.log(Constants.shareUrl+'#/JobDetail/'+post._id)
                return (
                    <div className="col-xl-6 col-md-6 col-lg-6" key={index}>
                        <div className="card card-custom gutter-b shadow-sm">
                            <div className="card-header">
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50 flex-shrink-0 mr-4">
                                        {
                                            post.businessId.companyLogo ?
                                                <div className="symbol-label" style={{ backgroundImage: `url(${Constants.CompanyLogoUrl + post.businessId.companyLogo})`, backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                                :
                                                <div className="symbol-label" style={{ backgroundImage: 'url(assets/noImage.png)' }}></div>
                                        }
                                    </div>
                                    <div>
                                        <Link to={'/Company/' + post.businessId._id} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{post.businessId.businessName}</Link>
                                        <span className="text-muted font-weight-bold d-block" style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: 170, whiteSpace: 'nowrap' }}><span data-toggle="tooltip" title={post.businessId.services.join(",")}>{post.businessId.services.join(",")}</span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <div className="flex-grow-1 mt-0">
                                    <Link to={'/JobDetails/' + post._id} className="text-dark font-weight-bolder text-hover-primary font-size-h6">
                                        {post.title}
                                    </Link>

                                    <div className="row justify-content-between mt-2">
                                        <p className="text-dark-50 font-weight-normal font-size-lg mt-0 mb-0 col-lg-4 col-md-6 col-sm-6" style={{ overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            <span className="fas fa-map-marker-alt font-weight-bolder text-dark-50"></span>
                                            <span className="ml-2">
                                                {post.city ?? ''}
                                                {(post.state != "") ? ',' + post.state : ''}
                                                {(post.country != "") ? ',' + post.country : ''}
                                            </span>
                                        </p>
                                        <p className="text-dark-50 font-weight-normal font-size-lg mt-0 mb-0 col-lg-5 col-md-6 col-sm-6">
                                            <span className="fas fa-coins font-weight-bolder text-dark-50"></span>
                                            <span className="ml-2">{post.minSalary != '' ? post.minSalary : ''}{post.maxSalary != '' ? ' - ' + post.maxSalary : ''}{post.minSalary == "" && post.maxSalary == "" ? 'Not Specified' : ' ' + post.currency ?? ''}</span>
                                        </p>
                                        <p className="text-dark-50 font-weight-normal font-size-lg mt-0 mb-0 col-lg-3 col-md-6 col-sm-6">
                                            <span className="fas fa-clock font-weight-bolder text-dark-50"></span>
                                            <span className="ml-2">{post.jobTime.replace("_", " ")}</span>
                                        </p>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <span className="font-weight-bolder text-dark">Exp.</span>
                                            <span className="ml-2">{post.minExperience != '' ? post.minExperience : ''}{post.maxExperience != '' ? ' - ' + post.maxExperience : ''}{post.minExperience == "" && post.maxExperience == "" ? 'Not Specified' : ''}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <span className="font-weight-bolder text-dark">Skills.</span>
                                            <span className="ml-2">{post.skills.length > 0 ? post.skills.join(",") : 'Not Specified'}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="d-flex align-items-center col-md-12">
                                            {/* <p className="text-muted mb-0 cursor-pointer text-hover-primary" data-toggle="modal" data-target="#exampleModal" onClick={() => this.openShareModal(post.id)}><i className="fas fa-share-alt mr-2"></i></p> */}
                                            <p className="text-dark font-weight-bolder mb-0">Share :</p>
                                            <div className="p-1 pl-3">
                                                    <WhatsappShareButton url={Constants.shareUrl+'/Job/'+post._id}>
                                                        <WhatsappIcon type="button" size={25} round={true} />
                                                    </WhatsappShareButton>
                                                </div>
                                                <div className="p-1">
                                                    <FacebookShareButton url={Constants.shareUrl+'/Job/'+post._id}>
                                                        <FacebookIcon type="button" size={25} round={true} />
                                                    </FacebookShareButton>
                                                </div>
                                                <div className="p-1">
                                                    <LinkedinShareButton url={Constants.shareUrl+'/Job/'+post._id}>
                                                        <LinkedinIcon type="button" size={25} round={true} />
                                                    </LinkedinShareButton>
                                                </div>
                                                <div className="p-1">
                                                    <TwitterShareButton url={Constants.shareUrl+'/Job/'+post._id}>
                                                        <TwitterIcon type="button" size={25} round={true} />
                                                    </TwitterShareButton>
                                                </div>
                                                <div className="p-1">
                                                    <EmailShareButton url={Constants.shareUrl+'/Job/'+post._id}>
                                                        <EmailIcon type="button" size={25} round={true} />
                                                    </EmailShareButton>
                                                </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <div className="flex-grow-1 pb-0 mt-0 d-flex justify-content-between align-items-end">
                                    <p className="text-dark-50 font-weight-normal font-size-lg mt-0 mb-0" style={{ display: '-webkit-box', height: 45, WebkitBoxOrient: 'vertical', overflow: "hidden", textOverflow: 'ellipsis',WebKitLineClamp:2 }}>
                                        <span dangerouslySetInnerHTML={{ __html: post.description }}></span>
                                    </p>
                                    <Link to={'/Job/' + post._id} className="text-dark font-weight-bolder text-hover-primary align-items-center">... See More</Link>
                                </div> */}
                            </div>
                            <div className="card-footer col-md-12" style={{ padding: '1rem 1rem' }}>
                                <ul className="row pl-3">
                                    <li className="list-inline-item col-md-6 mr-0">
                                        <p className="text-muted mb-0"><i className="flaticon-calendar-with-a-clock-time-tools mr-2"></i>{time}</p>
                                    </li>
                                    {/* <li className="list-inline-item col-md-4 mr-0 text-center">
                                        <a href="" className="text-dark">
                                            <p className="mb-0 text-muted"><i className="flaticon2-user mr-2"></i><span className="text-dark-75">By</span> {post.userId.firstName} </p>
                                        </a>
                                    </li> */}
                                    {/* <li className="list-inline-item col-md-4 mr-0 text-center">
                                        <p className="text-muted mb-0"><i className="flaticon-eye mr-2"></i>{post.views ? post.views.length : 0} Views</p>
                                    </li> */}
                                    {/* <li className="list-inline-item col-md-4 mr-0 text-center">
                                        <p className="text-muted mb-0"><i className="flaticon2-calendar-1 mr-2"></i>10-Sep-2018</p>
                                    </li> */}
                                    <li className="list-inline-item col-md-6 mr-0 text-right">
                                        {
                                            userId == post.userId._id ?
                                                <Link to={'/EditJob/' + post._id} ><p className="text-muted mb-0 btn"><i className="flaticon-edit-1 mr-2"></i>Edit</p> </Link>
                                                :
                                                AllFunction.includesMultiDimension(appList, post._id) ?
                                                    <div><p className="text-muted mb-0 btn"><i className="flaticon2-chat-1 mr-2" style={{cursor:'default'}}></i>Applied</p></div>
                                                    :
                                                    <Link to={'/Job/' + post._id} className="btn btn-primary font-weight-bolder text-uppercase">Apply Now</Link>
                                        }
                                    </li>

                                </ul>
                            </div>
                        </div>                        
                    </div>

                )
            })
        }
        else {
            return <div></div>
        }
    }
}

export default JobCard;