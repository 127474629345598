import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constants from '../Utilities/Constants';

class MobileHeader extends Component {
    render() {
        return (
            <>
                <div id="kt_header_mobile" className="header-mobile header-mobile-fixed" style={{backgroundColor:'#07a398'}}>
                    {/* <!--begin::Logo--> */}
                    <Link to={'/'}>
                        <img alt="Logo" src="/assets/logo/Logo-white.png" className="max-h-25px" />
                    </Link>
                    {/* <!--end::Logo--> */}
                    {/* <!--begin::Toolbar--> */}
                    <div className="d-flex align-items-center">
                        <button className="btn p-0 burger-icon burger-icon-left ml-4" id="kt_header_mobile_toggle">
                            <span></span>
                        </button>
                        <button className="btn p-0 ml-2" id="kt_header_mobile_topbar_toggle">
                            {/* <span className="symbol symbol-30 ml-2">
                                {
                                    this.state.User.profileImage ?
                                        <img alt="Pic" src={Constants.ProfileImageUrl + this.state.User.profileImage} />
                                        :
                                        <img alt="Pic" src="/assets/media/users/default.jpg" />
                                }
                            </span> */}
                        </button>
                    </div>
                    {/* <!--end::Toolbar--> */}
                </div>
            </>
        );
    }
}

export default MobileHeader;