import React, { Component } from 'react';
import MessageAlert from '../Components/MessageAlert';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';
import Store from './store';

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            user: '',
            email: '',
            message: '',
            name: '',
            loading: false,
            errors: {},
            successMsg: '',
            success: false,
            title: ''
        }
        this.store = Store;
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
        await this.store.setSubHeader("FeedBack", ["FeedBack", "Add FeedBack"])
    }

    getUserCredintials = async () => {
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        const token = await localStorage.getItem('token');
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token, user, name: user.firstName, email: user.email });
        }
    }

    feedbackValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.name) {
            formIsValid = false;
            errors.name = "Please enter your name.";
        }

        if (!this.state.email) {
            formIsValid = false;
            errors.email = "Please enter your email.";
        }

        if (!this.state.message) {
            formIsValid = false;
            errors.message = "Please enter message.";
        }

        if (!this.state.title) {
            formIsValid = false;
            errors.title = "Please enter title.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onAddFeedBack = () => {
        if (this.feedbackValidation()) {
            this.setState({ loading: true })
            let errors = {};
            let body = JSON.stringify({
                message: this.state.message,
                title: this.state.title
            })
            fetch(Constants.url + 'feedback',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.state.token
                    },
                    body: body
                }).then((response) => response.json())
                .then((responseData) => {
                    // console.log(responseData)
                    if (responseData.status) {
                        this.setState({ loading: false, message: '', title: '', success: true, successMsg: 'Feedback added successfully.' });

                    }else if(!responseData.status && 'auth' in responseData){
                        Auth.Logout();
                    } 
                    else {
                        errors.error = "Something went wrong, Please try again after sometime.";
                        this.setState({ errors: errors, loading: false })
                    }
                })
                .catch(err => {
                    this.setState({ loading: false })
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors })
                    console.log(err);
                });
        }
    }

    render() {
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom gutter-b">
                            <div className="card-header">
                                <h3 className="card-title">Add FeedBack </h3>
                            </div>
                            <div className="card-body">
                                <div className="col-md-12">
                                    {
                                        this.state.success &&
                                        <MessageAlert children={this.state.successMsg} variant="success" />
                                    }
                                    {this.state.errors.error &&
                                        <MessageAlert children={this.state.errors.error} variant="danger" />
                                    }
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label>Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-lg" name="name" placeholder="Enter Your Name" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.name} />
                                        {this.state.errors.name && <span className="form-text text-danger pl-2">{this.state.errors.name}</span>}
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Email <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-lg" name="email" placeholder="Enter Your Email" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.email} />
                                        {this.state.errors.email && <span className="form-text text-danger pl-2">{this.state.errors.email}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Title <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-lg" name="title" placeholder="Enter Title" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.title} />
                                        {this.state.errors.title && <span className="form-text text-danger pl-2">{this.state.errors.title}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Message <span className="text-danger">*</span></label>
                                        <textarea rows={5} className="form-control form-control-lg" name="message" placeholder="Enter Message" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.message} />
                                        {this.state.errors.message && <span className="form-text text-danger pl-2">{this.state.errors.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-lg-6">
                                        {
                                            this.state.loading ?
                                                <button type="button" className="btn btn-primary mr-2 spinner spinner-white spinner-right">Submit</button>

                                                :
                                                <button type="button" className="btn btn-primary mr-2" onClick={() => { this.onAddFeedBack() }}>Submit</button>
                                        }
                                        <button type="reset" className="btn btn-secondary" onClick={() => { window.location = '/' }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Feedback;