import { useState, useEffect } from 'react'

const MessageAlert = ({ variant, children }) => {
    const [show, setShow] = useState(true)

    // On componentDidMount set the timer
    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 5 seconds set the show value to false
            setShow(false)
        }, 5000)

        return () => {
            clearTimeout(timeId)
        }
    }, []);

    // If show is false the component will return null and stop here
    if (!show) {
        return null;
    }

    // If show is true this will be returned
    return (
        <div className={`alert alert-custom alert-otice fade show p-5 shadow-sm alert-light-${variant}`}>
            <div className="alert-text">{children}</div>
            <div className="alert-close">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true"><i className="ki ki-close"></i></span>
                </button>
            </div>
        </div>
    )
}

MessageAlert.defaultPros = {
    variant: 'info',
}

export default MessageAlert;
