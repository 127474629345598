import React, { Component } from 'react';
import Constants from '../Utilities/Constants';
import Auth from '../Utilities/Auth';
import AllFunction from '../Utilities/Functions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Store from './store';
import Axios from 'axios';
import InputTag from '../Components/InputTag';
import CurrencyJson from '../../src/currency.json';
import Autocomplete from "react-google-autocomplete";
class CreateJob extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            jobTypeList: [
                {
                    label: 'Permanent Job',
                    value: 'Permanent_Job'
                },
                {
                    label: 'Contract Based',
                    value: 'Contract_Based'
                }
            ],
            jobTimeList: [
                {
                    label: 'Full Time',
                    value: 'Full_Time'
                },
                {
                    label: 'Part Time',
                    value: 'Part_Time'
                }
            ],
            jobType: 'Permanent_Job',
            jobTime: 'Full_Time',
            userId: '',
            token: '',
            user: '',
            businesses: [],
            dataLoading: false,
            businessId: '',
            title: '',
            description: '',
            loading: false,
            errors: {},
            id: this.props.match.params.id ? this.props.match.params.id : '',
            image_url: '',
            postFile: '',
            uploadedImage: '',
            location: '',
            minSalary: '',
            maxSalary: '',
            minExperience: '',
            maxExperience: '',
            skills: "",
            role: '',
            currency: '',
            domain: '',
            domainList: [],
            isDisabled: false,
            countryList: [],
            cityList: [],
            stateList: [],
            city: '',
            country: '',
            state: '',
            street_address: '',
            businessName: 'Select Business'
        }
        this.store = Store;
    }
    componentDidMount = async () => {
        await this.getUserCredintials();
        await this.store.setSubHeader("Job", ["Job", "Create Job"])
        if (this.state.id && this.state.id !== '') {
            await this.getJobDetail();
            await this.store.setSubHeader("Job", ["Job", "Edit Job"])
        }
    }

    getUserCredintials = async () => {
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        const token = await localStorage.getItem('token');
        const userId = user._id;
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ userId, token, user });
            await this.getUserBusiness();
            await this.getDomainList();
            // await this.getCountryList();
        }
    }

    getDomainList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'dropdown/Domain',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({ dataLoading: false, domainList: responseData.dropdown.options });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getCountryList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'places?type=COUNTRY',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({ dataLoading: false, countryList: responseData.places });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onChangeCountry = async (event) => {
        await this.setState({ country: event.target.value, stateList: [], cityList: [] });
        await this.getStateList();
    }

    getStateList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'places?type=STATE&name=' + this.state.country,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({ dataLoading: false, stateList: responseData.places });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onChangeState = async (event) => {
        await this.setState({ state: event.target.value, cityList: [] });
        await this.getCityList();
    }

    getCityList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'places?type=CITY&name=' + this.state.state,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({ dataLoading: false, cityList: responseData.places });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getDomainList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'dropdown/Domain',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({ dataLoading: false, domainList: responseData.dropdown.options });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getJobDetail = () => {
        let errors = {};
        let body = JSON.stringify({
            userId: this.state.userId
        })
        this.setState({ dataLoading: true })
        fetch(Constants.url + 'job/' + this.state.id,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log("Job Detail", responseData)
                if (responseData.status) {
                    this.setState({
                        dataLoading: false,
                        businessId: responseData.job.businessId,
                        title: responseData.job.title,
                        description: responseData.job.description,
                        uploadedImage: responseData.job.file,
                        location: responseData.job.location,
                        minSalary: responseData.job.minSalary,
                        maxSalary: responseData.job.maxSalary,
                        jobType: responseData.job.jobType,
                        currency: responseData.job.currency,
                        jobTime: responseData.job.jobTime,
                        skills: responseData.job.skills || "",
                        role: responseData.job.role || '',
                        minExperience: responseData.job.minExperience || '',
                        maxExperience: responseData.job.maxExperience || '',
                        domain: responseData.job.domain,
                        country: responseData.job.country,
                        state: responseData.job.state,
                        city: responseData.job.city,
                        isDisabled: responseData.job.isDisabled,
                        street_address: responseData.job.street_address || ''
                    });
                    if (this.state.country != '') {
                        this.getStateList();
                    }
                    if (this.state.state != '') {
                        this.getCityList();
                    }
                    // this.onGetTags(responseData.job.skills || []);
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     * Get All Business of login user
     */
    getUserBusiness = async () => {
        this.setState({ dataLoading: true })
        let errors = {};
        fetch(Constants.url + 'userBusiness',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        dataLoading: false, businesses: responseData.Businesses,
                    });
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    jobValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.businessId) {
            formIsValid = false;
            errors.businessId = "Please select Business.";
        }

        if (!this.state.title) {
            formIsValid = false;
            errors.title = "Please enter title.";
        }

        if (!this.state.location) {
            formIsValid = false;
            errors.location = "Please enter location.";
        }

        if (!this.state.description) {
            formIsValid = false;
            errors.description = "Please enter description.";
        }

        if (!this.state.domain) {
            formIsValid = false;
            errors.domain = "Please enter domain.";
        }

        if (this.state.minSalary && this.state.maxSalary) {
            if (parseFloat(this.state.maxSalary) < parseFloat(this.state.minSalary)) {
                formIsValid = false;
                errors.maxSalary = "Please enter valid max salary.";
            }
            if (!this.state.currency) {
                formIsValid = false;
                errors.currency = "Please select currency.";
            }
        }

        if (!this.state.street_address) {
            formIsValid = false;
            errors.street_address = "Please enter your location.";
        }

        this.setState({ errors: errors });
        if (Object.keys(errors).length > 0) {
            window.scroll({
                top: 100,
                behavior: 'smooth'
            });

        }
        return formIsValid;
    }

    getAddressComponentData = (place) => {
        let address_components = place.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name })
            }
        })
        this.setState({ street_address: place.formatted_address, location: place.formatted_address });

    }

    onCreateJob = () => {
        if (this.jobValidation()) {
            this.setState({ loading: true })
            let errors = {};
            let method = 'POST';

            let formData = new FormData();

            if (this.state.id && this.state.id !== '') {
                method = 'PUT';
                formData.append('_id', this.state.id);
                formData.append('isDisabled', this.state.isDisabled);
            }

            formData.append('businessId', this.state.businessId);
            formData.append('title', this.state.title);
            formData.append('jobType', this.state.jobType);
            formData.append('jobTime', this.state.jobTime);
            formData.append('location', this.state.location);
            formData.append('minSalary', parseFloat(this.state.minSalary));
            formData.append('maxSalary', parseFloat(this.state.maxSalary));
            formData.append('description', this.state.description);
            formData.append('skills', this.state.skills);
            formData.append('role', this.state.role);
            formData.append('minExperience', this.state.minExperience);
            formData.append('maxExperience', this.state.maxExperience);
            formData.append('domain', this.state.domain);
            formData.append('country', this.state.country);
            formData.append('state', this.state.state);
            formData.append('city', this.state.city);
            formData.append('currency', this.state.currency);
            formData.append('street_address', this.state.street_address);
            if (this.state.postFile !== '') {
                formData.append('file', this.state.postFile);
            }

            Axios({
                method: method,
                url: Constants.url + 'job',
                data: formData,
                headers: {
                    'Accept': 'application/json,multipart/form-data',
                    'Content-Type': 'application/json,multipart/form-data',
                    'Authorization': this.state.token
                }
            }).then(response => {
                let responseData = response.data;
                // console.log("Add Job", responseData)
                if (responseData.status) {
                    this.setState({ loading: false });
                    window.location = '/#/JobArticles';
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.log(error);
            });

        }
        else {
            console.log("not valid");
        }
    }

    onUploadFileChange = async (e) => {
        let errors = {};

        if (e.target.files[0]['size'] > 2097152) {
            errors.postFile = "File size exceeded 2 MB";
            this.setState({ errors: errors });
        }
        else {
            const file = this.myRef.current.files[0];
            const reader = new FileReader();

            reader.onloadend = async () => {
                await this.setState({
                    image_url: reader.result
                })
            }
            await this.setState({
                postFile: e.target.files[0]
            })
            e.target.value = '';
            if (file) {
                reader.readAsDataURL(file);
            }
            else {
                this.setState({
                    image_url: ""
                })
            }
        }
    }

    _onRemoveImage = async (e) => {
        await this.setState({ image_url: '', postFile: '' })
    }

    jobTypeChange = async (event) => {
        const target = event.target;
        var value = target.type === "radio" ? target.checked : target.value;
        const name = target.name;

        await this.setState({
            [name]: value,
            jobType: name,
        });
    }

    changeBusiness = async (businessId, businessName) => {
        await this.setState({ businessId, businessName })
    }

    render() {
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom gutter-b">
                            <div className="card-header">
                                <h3 className="card-title">Job Details</h3>
                            </div>
                            <div className="card-body">

                                {/* <div className="col-md-12 p-0 pt-5">
                                    <div className="form-group row mb-3">
                                        <label className=" col-lg-2 col-sm-12">Select JobType <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <div className="radio-inline">
                                                {
                                                    this.state.jobTypeList.map((list, index) => {
                                                        return (
                                                            <label className="radio radio-rounded" key={index}>
                                                                <input type="radio" checked={this.state.jobType === list.value ? "checked" : ''} name='jobType' onChange={(event) => this.jobTypeChange(event)} value={this.state.jobType} />
                                                                <span></span>
                                                                {list.label}
                                                            </label>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-md-12 p-0">
                                    <div className="form-group row mb-3">
                                        <label className=" col-lg-2 col-sm-12">Select JobTime <span className="text-danger">*</span></label>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="radio-inline">
                                                {
                                                    this.state.jobTimeList.map((list, index) => {
                                                        return (
                                                            <label className="radio radio-rounded" key={index}>
                                                                <input type="radio" checked={this.state.jobTime === list.value ? "checked" : ''} name='jobTime' onChange={(event) => this.jobTypeChange(event)} value={this.state.jobTime} />
                                                                <span></span>
                                                                {list.label}
                                                            </label>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.state.id && this.state.id !== '' ? <>
                                                <label className=" col-lg-1 col-sm-12">Disabled <span className="text-danger">*</span></label>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="checkbox-inline">
                                                        <label className="checkbox checkbox-rounded">
                                                            <input type="checkbox" checked={this.state.isDisabled === true ? "checked" : ''} name='isDisabled' onChange={(event) => AllFunction.onChange(event, this)} value={this.state.isDisabled} />
                                                            <span></span>
                                                            Disabled
                                                        </label>

                                                    </div>
                                                </div>
                                            </>
                                                : ''
                                        }

                                    </div>
                                </div>
                                {/* <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className=" col-lg-2 col-sm-12">Select Business <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <select className="form-control" name="businessId" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.businessId}>
                                                <option value=''>Select Business</option>
                                                {
                                                    this.state.dataLoading ? '...' :
                                                        this.state.businesses.map((business, index) => {
                                                            return (
                                                                <option key={index} value={business._id}>{business.businessName}</option>
                                                            )
                                                        })
                                                }
                                            </select>
                                            {this.state.errors.businessId && <span className="text-danger pl-2">{this.state.errors.businessId}</span>}
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-md-12 p-0 pt-5">
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-2 col-sm-12">Select Business <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <div class="dropdown mr-1">
                                                <button type="button" class="btn btn-outline-secondary btn-block dropdown-toggle d-flex align-items-center justify-content-between" id="dropdownMenuOffset" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {this.state.businessName}
                                                </button>
                                                <div class="dropdown-menu pb-0 pt-0" aria-labelledby="dropdownMenuOffset" style={{ width: '100%' }}>
                                                    {
                                                        this.state.dataLoading ? '...' :
                                                            this.state.businesses.map((business, index) => {
                                                                return (
                                                                    <p class="dropdown-item mb-0 cursor-pointer d-block" onClick={() => { this.changeBusiness(business._id, business.businessName) }}>
                                                                        <div className="row align-items-center">
                                                                            <div className="col-md-1">
                                                                                <div className="symbol symbol-35 symbol-lg-35 mr-5">
                                                                                    {
                                                                                        business.companyLogo ?
                                                                                            <img src={Constants.CompanyLogoUrl + business.companyLogo} alt="" style={{ width: 35 }} />
                                                                                            :
                                                                                            <img src="assets/noImage.png" alt="" style={{ width: 35 }} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-11">
                                                                                {business.businessName}
                                                                            </div>
                                                                        </div>
                                                                    </p>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            </div>
                                            {this.state.errors.businessId && <span className="text-danger pl-2">{this.state.errors.businessId}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className=" col-lg-2 col-sm-12">Select Domain <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <select className="form-control form-control-lg" name="domain" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.domain}>
                                                <option value="">Select Domain</option>
                                                {
                                                    this.state.domainList.map((list, index) => {
                                                        return (
                                                            <option value={list.value} key={index}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {this.state.errors.domain && <span className="text-danger pl-2">{this.state.errors.domain}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className="col-xl-2 col-lg-2 ">Title <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="title" placeholder="Job Title" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.title} />
                                            </div>
                                            {this.state.errors.title && <span className="text-danger pl-2">{this.state.errors.title}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className="col-xl-2 col-lg-2 ">Location <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <Autocomplete
                                                apiKey={Constants.GOOGLE_MAPS_API_KEY}
                                                style={{ width: "100%" }}
                                                onPlaceSelected={(place) => {
                                                    this.getAddressComponentData(place)
                                                }}
                                                className="form-control form-control-lg"
                                                name="street_address"
                                                defaultValue={this.state.street_address}
                                            />
                                            {this.state.errors.street_address && <span className="text-danger pl-2">{this.state.errors.street_address}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className="col-xl-2 col-lg-2 "></label>
                                        <div className="col-lg-2 col-md-2 col-sm-12">
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="country" placeholder="Country" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.country} readOnly />
                                            </div>
                                            {/* <select className="form-control form-control-lg" name="country" autoComplete="off" onChange={(event) => this.onChangeCountry(event)} value={this.state.country}>
                                                <option value="">Select Country</option>
                                                {
                                                    this.state.countryList.map((list, index) => {
                                                        return (
                                                            <option value={list.name} key={index}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                            </select> */}
                                            {this.state.errors.country && <span className="form-text text-danger pl-2">{this.state.errors.country}</span>}
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-12">
                                            {/* <select className="form-control form-control-lg" name="state" autoComplete="off" onChange={(event) => this.onChangeState(event)} value={this.state.state}>
                                                <option value="">Select State</option>
                                                {
                                                    this.state.stateList.map((list, index) => {
                                                        return (
                                                            <option value={list.name} key={index}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                            </select> */}
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="state" placeholder="State" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.state} readOnly />
                                            </div>
                                            {this.state.errors.state && <span className="form-text text-danger pl-2">{this.state.errors.state}</span>}
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-12">
                                            {/* <select className="form-control form-control-lg" name="city" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.city}>
                                                <option value="">Select City</option>
                                                {
                                                    this.state.cityList.map((list, index) => {
                                                        return (
                                                            <option value={list.name} key={index}>{list.name}</option>
                                                        )
                                                    })
                                                }
                                            </select> */}
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="city" placeholder="City" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.city} readOnly />
                                            </div>
                                            {this.state.errors.city && <span className="form-text text-danger pl-2">{this.state.errors.city}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className="col-xl-2 col-lg-2 ">Role</label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="role" placeholder="Role" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.role} />
                                            </div>
                                            {this.state.errors.role && <span className="text-danger pl-2">{this.state.errors.role}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className="col-xl-2 col-lg-2 ">Salary (yearly)</label>
                                        <div className="col-lg-2 col-md-2 col-sm-12">
                                            <select className="form-control" name="currency" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.currency}>
                                                <option value=''>Select Currency</option>
                                                {
                                                    Object.keys(CurrencyJson).map((currency, index) => {
                                                        return (
                                                            <option value={currency} key={index}>{currency}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {this.state.errors.currency && <span className="text-danger pl-2">{this.state.errors.currency}</span>}
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-12">
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="minSalary" placeholder="Minimum Salary" onChange={(event) => AllFunction.onChange(event, this, "Number")} value={this.state.minSalary} />
                                            </div>
                                            {this.state.errors.minSalary && <span className="text-danger pl-2">{this.state.errors.minSalary}</span>}
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-12">
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="maxSalary" placeholder="Maximum Salary" onChange={(event) => AllFunction.onChange(event, this, "Number")} value={this.state.maxSalary} />
                                            </div>
                                            {this.state.errors.maxSalary && <span className="text-danger pl-2">{this.state.errors.maxSalary}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className="col-xl-2 col-lg-2 ">Experience (in years)</label>
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="minExperience" placeholder="Minimum Experience" onChange={(event) => AllFunction.onChange(event, this, "Number")} value={this.state.minExperience} />
                                            </div>
                                            {this.state.errors.minExperience && <span className="text-danger pl-2">{this.state.errors.minExperience}</span>}
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="maxExperience" placeholder="Maximum Experience" onChange={(event) => AllFunction.onChange(event, this, "Number")} value={this.state.maxExperience} />
                                            </div>
                                            {this.state.errors.maxExperience && <span className="text-danger pl-2">{this.state.errors.maxExperience}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className="col-xl-2 col-lg-2 ">Skills</label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <input type="text" className="form-control form-control-lg" name="skills" placeholder="skills" onChange={(event) => { AllFunction.onChange(event, this) }} value={this.state.skills} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row mb-3">
                                        <label className="col-xl-2 col-lg-2  ">Upload File</label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <div className="image-input image-input-outline" id="kt_profile_avatar">
                                                {this.state.image_url ?
                                                    <div className="image-input-wrapper" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.state.image_url})`, backgroundPosition: 'center' }}></div>
                                                    :
                                                    this.state.dataLoading ? '....' : this.state.uploadedImage ?
                                                        <div className="image-input-wrapper" style={{ backgroundImage: `url(${Constants.PostFileUrl}${this.state.uploadedImage})`, backgroundPosition: 'center', backgroundSize: 'contain' }}></div>
                                                        :
                                                        <div className="image-input-wrapper" style={{ backgroundImage: 'url(assets/noImage.png)', backgroundPosition: 'center' }}></div>
                                                }
                                                <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                                    <input type="file" name="postFile" accept=".png, .jpg, .jpeg" ref={this.myRef} onChange={(e) => this.onUploadFileChange(e)} />
                                                </label>
                                                {
                                                    this.state.image_url &&

                                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-toggle="tooltip" title="Cancel Image" onClick={(e) => this._onRemoveImage(e)} style={{ position: 'absolute', right: '-10px', bottom: '-5px' }}>
                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 p-0">
                                    <div className="form-group row mb-3">
                                        <label className="col-xl-2 col-lg-2  ">Description <span className="text-danger">*</span></label>
                                    </div>

                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={this.state.description}
                                        onReady={editor => {

                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            this.setState({ description: data })
                                        }}
                                        onBlur={(event, editor) => {

                                        }}
                                        onFocus={(event, editor) => {

                                        }}
                                    />
                                    {this.state.errors.description && <span className="text-danger pl-2">{this.state.errors.description}</span>}
                                </div>
                                <div className="d-flex justify-content-end border-top mt-5 pt-10">
                                    <div>
                                        {
                                            this.state.loading ?
                                                <button type="button" className="btn btn-primary font-weight-bolder text-uppercase py-4 spinner spinner-white spinner-right">Submit</button>
                                                :
                                                <button type="button" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" onClick={() => this.onCreateJob()}>{(this.state.id && this.state.id !== '') ? "Edit" : "Submit"}</button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CreateJob;