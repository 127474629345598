import { Component } from 'react';
import moment from 'moment';

class Functions extends Component {
    onChange(event, object, validate = '') {
        let nam = event.target.name;
        let val = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        if (validate === 'String') {
            val = val.replace(/[^a-zA-Z\s]/g, '');
        }
        if (validate === 'Number') {
            val = val.replace(/[^0-9]/g, '');
        }
        object.setState({ [nam]: val });
        // console.log(val)
    }

    includesMultiDimension = (arr, str) => {
        // console.log(arr,"Function")
        return JSON.stringify(arr).includes(str);
    }

    // includesMultiDimension = (arr, str) => {
    //     let isCheckId = false;
    //     console.log(arr)
    //     arr.filter((ar)=>{
    //         console.log(ar.userId._id,"AR",str)
    //         if((ar.conectUserId._id == str || ar.userId._id == str) && !isCheckId){
    //             isCheckId = true
    //         }
    //     })
    //     console.log(isCheckId)
    //     return isCheckId;
    // }

    CheckConnectionStatus = (arr, str) => {
        let list = arr.filter(ar => (ar.conectUserId._id == str || ar.userId._id == str) && ar.connectionStatus === 'Pending')
        return list
    }
    dateDifference(actualDate){
        return moment(actualDate).fromNow();
    }
    // dateDifference(actualDate) {
    //     // console.log(actualDate)
    //     // Calculate time between two dates:
    //     const date1 = new Date(actualDate); // the date you already commented/ posted
    //     const date2 = new Date(); // today

    //     let r = {}; // object for clarity
    //     let message = '';

    //     const diffInSeconds = Math.abs(date2 - date1) / 1000;
    //     // console.log(diffInSeconds)
    //     const days = Math.floor(diffInSeconds / 60 / 60 / 24);
    //     const hours = Math.floor(diffInSeconds / 60 / 60 % 24);
    //     const minutes = Math.floor(diffInSeconds / 60 % 60);
    //     const seconds = Math.floor(diffInSeconds % 60);
    //     const milliseconds =
    //         Math.round((diffInSeconds - Math.floor(diffInSeconds)) * 1000);

    //     const months = Math.floor(days / 31);
    //     const years = Math.floor(months / 12);

    //     // the below object is just optional 
    //     // if you want to return an object instead of a message
    //     r = {
    //         years: years,
    //         months: months,
    //         days: days,
    //         hours: hours,
    //         minutes: minutes,
    //         seconds: seconds,
    //         milliseconds: milliseconds
    //     };

    //     // check if difference is in years or months
    //     if (years === 0 && months === 0) {
    //         // show in days if no years / months
    //         if (days > 0) {
    //             if (days === 1) {
    //                 message = days + ' day';
    //             } else { message = days + ' days'; }
    //         } else if (hours > 0) {
    //             if (hours === 1) {
    //                 message = hours + ' hour';
    //             } else {
    //                 message = hours + ' hours';
    //             }
    //         } else {
    //             // show in minutes if no years / months / days
    //             if (minutes === 1) {
    //                 message = minutes + ' minute';
    //             } else { message = minutes + ' minutes'; }
    //         }
    //     } else if (years === 0 && months > 0) {
    //         // show in months if no years
    //         if (months === 1) {
    //             message = months + ' month';
    //         } else { message = months + ' months'; }
    //     } else if (years > 0) {
    //         // show in years if years exist
    //         if (years === 1) {
    //             message = years + ' year';
    //         } else { message = years + ' years'; }
    //     }

    //     return message;
    //     // this is the message a user see in the view
    // }

    // getChatListId(chatList, userId) {
    //     let newchatListId = '';
    //     let id = chatList.filter((list) => {
    //         if (this.includesMultiDimension(list.userIds, userId)) {
    //             newchatListId = list._id;
    //         }
    //     })
    //     return newchatListId;
    // }

    getChatListId = (arr, userId) => {
        let ChatListIds = null;
        arr.filter((userls) => {
            if (userls.userIds) {
                let isUser = userls.userIds.find(({ _id }) => _id == userId);
                if (Boolean(isUser)) {
                    ChatListIds = userls._id;
                }
            }
        });
        return ChatListIds;
    }

    getConnectionStatus = (arr, str) => {
        try {
          let list = arr.filter(ar => (ar.conectUserId._id == str) || (ar.userId._id == str)) || [];
        //   console.log(list)
          return list.length == 1 ? list[0].connectionStatus : 'connect';
        }
        catch (e) {
          console.log(e);
          return 'connect';
        }
      }
    
    getConnectionObj = (arr, str) => {
        try {
          let list = arr.filter(ar => (ar.conectUserId._id == str) || (ar.userId._id == str)) || [];
          // console.log(arr)
          return list.length == 1 ? list[0] : false;
        }
        catch (e) {
          console.log(e);
          return false;
        }
      }

}


const AllFunction = new Functions();
export default AllFunction;