import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        }
    }

    async componentDidMount() {
        await this.setState({ redirect: false })
    }

    componentWillUnmount() {
        this.setState({ redirect: false });
    }

    CheckRedirect() {
        let user = localStorage.getItem('user');
        if (!user) {
            window.location = '/';
            return true;
        } else {
            return false;
        }
    }

    CheckUserAuth() {
        // let user = localStorage.getItem('user');
        // if (!user) {
        //     window.location = '/#/login';
        //     return true;
        // } else {
        //     return false;
        // }

        let user = localStorage.getItem("user");
        let User = JSON.parse(user);
        console.log(User)
        if (!User) {
            // alert("!User")
            // this.Logout();
            return false;
        }
    }

    Logout = async () => {

        // localStorage.clear();
        localStorage.removeItem('user');
        localStorage.removeItem('token');

        setInterval(() => {
            return this.setState({ redirect: true });
        });
        window.accessToken = null;
        window.location = '/login';

    }

    renderRedirect = () => {

        if (this.state.redirect) {
            return (<Redirect to='/login' />
            )
        }
    }

    render() {
        return (
            this.renderRedirect()
        )
    }
}

const AuthController = new Auth()
export default AuthController;
