import React, { Component } from 'react';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import store from './store';
import PaginationFunction from '../Components/Pagination';
import AllFunction from '../Utilities/Functions';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            loading: false,
            notificationList: [],
            listCount: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 5,
            currentPage: 1,
            listPerPage: 10,
            upperPageBound: 5,
            lowerPageBound: 0,
        }
        this.store = store;
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
        await this.store.setSubHeader("Notifications", ["Notifications"])
    }

    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            await this.getData();
        }
    }

    getData = () => {
        this.setState({ loading: true })
        let errors = {};
        let domain = this.state.domain
        let urlpath = `allNotification?page=${this.state.currentPage}&limit=${this.state.listPerPage}`;
        fetch(Constants.url + urlpath,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        loading: false,
                        notificationList: responseData.notifications,
                        listCount: responseData.count
                    });
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
       * pagination handle click
       * @param {event} event 
       */
    handleClick = async (event) => {
        let listid = Number(event.target.id);
        await this.setState({
            currentPage: listid
        });
        await this.getData();
        PaginationFunction.setPrevAndNextBtnClass(listid, this);
    }


    render() {
        const { listCount, listPerPage } = this.state;
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom gutter-b">
                            <div className="card-header">
                                <h3 className="card-title">Notifications</h3>
                            </div>
                            <div className="card-body">
                                {
                                    this.state.notificationList.map((notification, index) => {
                                        let time = AllFunction.dateDifference(notification.date);
                                        return (
                                            <div class="d-flex align-items-center mb-3" key={index}>
                                                <span class="bullet bullet-bar bg-success align-self-stretch mr-5"></span>
                                                <div class="d-flex flex-column flex-grow-1">
                                                    <div class="text-dark-75 font-weight-bold font-size-lg mb-1">{notification.text}</div>
                                                    <span class="text-muted font-weight-bold">{time}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {
                                    listCount > listPerPage &&
                                    <div className="row justify-content-end">
                                        {PaginationFunction.pagination("renderPrevBtn", this)}
                                        {PaginationFunction.pagination("pageDecrementBtn", this)}
                                        {PaginationFunction.pagination("renderPageNumbers", this)}
                                        {PaginationFunction.pagination("pageIncrementBtn", this)}
                                        {PaginationFunction.pagination("renderNextBtn", this)}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Notifications;