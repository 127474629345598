import React, { Component } from 'react';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import moment from 'moment';
import Store from './store';
import AllFunction from '../Utilities/Functions';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import PaginationFunction from '../Components/Pagination';

class Job extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();
        this.state = {
            jobId: this.props.match.params.id ? this.props.match.params.id : '',
            token: '',
            jobDetail: '',
            dataLoading: false,
            errors: {},
            userId: '',
            chatList: [],
            listLoading: false,
            postUser: '',
            appList: [],
            listCount: 0,
			isPrevBtnActive: 'disabled',
			isNextBtnActive: '',
			pageBound: 5,
            currentPage: 1,
			listPerPage: 10,
			upperPageBound: 5,
			lowerPageBound: 0,
        }
        this.store = Store;
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
        await this.store.setSubHeader("Post", ["Post", "Post Detail"])
        localStorage.removeItem('lastPage')
    }

    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token, userId: user._id });
            // console.log(this.state.userId)
            await this.getChatList();
            await this.getJobDetail();
            await this.getData();

        }
    }

    getJobDetail = () => {
        // console.log("Update Call")
        let errors = {};
        let body = JSON.stringify({
            userId: this.state.userId
        })
        this.setState({ dataLoading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);
        fetch(Constants.url + 'job/' + this.state.jobId,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData, "Update Data====")
                if (responseData.status) {
                    this.setState({
                        dataLoading: false,
                        jobDetail: responseData.job,
                        postUser: responseData.job.userId
                    });
                    // console.log(this.state.jobDetail.userId._id)
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    getChatList = async () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'chat/chatList',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        chatList: responseData.ChatList
                    });
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getData = async () => {
        this.setState({ listLoading: true })
        let errors = {};
        let urlpath = `jobApplications?jobId=${this.state.jobId}&page=${this.state.currentPage}&limit=${this.state.listPerPage}`;
        fetch(Constants.url + urlpath,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData, "Application")
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        appList: responseData.JobApps,
                        listCount: responseData.count
                    });
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onUploadFileChange = async (e) => {
        let errors = {};

        if (e.target.files[0]['size'] > 2097152) {
            errors.postFile = "File size exceeded 2 MB";
            this.setState({ errors: errors });
        }
        else {
            const file = this.myRef.current.files[0];
            const reader = new FileReader();

            reader.onloadend = async () => {
                await this.setState({
                    image_url: reader.result
                })
            }
            await this.setState({
                postFile: e.target.files[0]
            })
            e.target.value = '';
            if (file) {
                reader.readAsDataURL(file);
            }
            else {
                this.setState({
                    image_url: ""
                })
            }
        }
    }

    _onRemoveImage = async (e) => {
        await this.setState({ image_url: '', postFile: '' })
    }

    jobValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.description) {
            formIsValid = false;
            errors.description = "Please enter description.";
        }

        if (!this.state.postFile) {
            formIsValid = false;
            errors.postFile = "Please upload document.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onCreateJobApplication = () => {
        if (this.jobValidation()) {
            this.setState({ loading: true })
            let errors = {};
            let method = 'POST';

            let formData = new FormData();

            formData.append('description', this.state.description);
            formData.append('jobId', this.state.jobId);
            if (this.state.postFile !== '') {
                formData.append('file', this.state.postFile);
            }

            Axios({
                method: method,
                url: Constants.url + 'jobApply',
                data: formData,
                headers: {
                    'Accept': 'application/json,multipart/form-data',
                    'Content-Type': 'application/json,multipart/form-data',
                    'Authorization': this.state.token
                }
            }).then(response => {
                let responseData = response.data;
                // console.log("Add Job", responseData)
                if (responseData.status) {
                    this.setState({ loading: false });
                    window.location = '/#/JobArticles';
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.log(error);
            });

        }
    }

    /**
	   * pagination handle click
	   * @param {event} event
	   */
	handleClick = async (event) => {
		let listid = Number(event.target.id);
		await this.setState({
			currentPage: listid
		});
		await this.getData();
		PaginationFunction.setPrevAndNextBtnClass(listid, this);
	}

    render() {
        let List = this.state?.appList;
		const { listCount, listPerPage } = this.state;
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom gutter-b">
                            {
                                this.state.dataLoading ? '' :
                                    <>
                                        <div className="card-body" style={{ minHeight: 360 }}>
                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-45 symbol-light mr-5">
                                                    <span className="symbol-label">
                                                        <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
                                                                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
                                                                    <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                                                                    <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-column flex-grow-1">
                                                    <a href="#" className="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{this.state.jobDetail.title}</a>
                                                    <div className="d-flex">
                                                        <div className="d-flex align-items-center pr-5">
                                                            <span className="svg-icon svg-icon-md svg-icon-primary pr-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                        <path d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z" fill="#000000" opacity="0.3" />
                                                                        <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span className="text-muted font-weight-bold">Due {moment(this.state.jobDetail.date).format('DD MMM YYYY')} </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="pt-3">
                                                <div className="text-dark-75 font-size-lg font-weight-normal pt-5 mb-5">
                                                    <p dangerouslySetInnerHTML={{ __html: this.state.jobDetail.description }}></p>
                                                </div>

                                                {
                                                    this.state.jobDetail.file && this.state.jobDetail.file !== '' ?
                                                        <div className="bgi-no-repeat bgi-size-cover rounded min-h-180px w-200 mb-10" style={{ backgroundImage: `url(${Constants.PostFileUrl}${this.state.jobDetail.file})`, backgroundSize: 'contain' }}></div>
                                                        : ''
                                                }

                                                <div className="row justify-content-between">
                                                    <p className="text-dark-50 font-weight-normal font-size-lg mt-0 mb-0 col-lg-3 col-md-6 col-sm-6">
                                                        <span className="fas fa-map-marker-alt font-weight-bolder text-dark-50"></span>
                                                        <span className="ml-2">
                                                        {this.state.jobDetail.city??''}
                                                        {(this.state.jobDetail.state != "") ? ','+this.state.jobDetail.state : ''}
                                                        {(this.state.jobDetail.country != "") ? ','+this.state.jobDetail.country : ''}
                                                        </span>
                                                    </p>
                                                    <p className="text-dark-50 font-weight-normal font-size-lg mt-0 mb-0 col-lg-3 col-md-6 col-sm-6">
                                                        <span className="fas fa-coins font-weight-bolder text-dark-50"></span>
                                                        <span className="ml-2">{this.state.jobDetail.minSalary != '' ? this.state.jobDetail.minSalary : ''}{this.state.jobDetail.maxSalary != '' ? ' - '+this.state.jobDetail.maxSalary : ''}{this.state.jobDetail.minSalary == "" && this.state.jobDetail.maxSalary == "" ? 'Not Specified' : '' }</span>
                                                    </p>
                                                    <p className="text-dark-50 font-weight-normal font-size-lg mt-0 mb-0 col-lg-3 col-md-6 col-sm-6 mt-1">
                                                        <span className="fas fa-clock font-weight-bolder text-dark-50"></span>
                                                        <span className="ml-2">{this.state.jobDetail?.jobTime?.replace("_", " ")}</span>
                                                    </p>
                                                </div>

                                                <div className="row mt-3">
                                                    <div className="col-md-12">
                                                        <span className="font-weight-bolder text-dark">Role.</span>
                                                        <span className="ml-2">{this.state.jobDetail?.role != this.state.jobDetail?.role ? '' : 'Not Specified'}</span>
                                                    </div>
                                                </div>

                                                <div className="row mt-3">
                                                    <div className="col-md-12">
                                                        <span className="font-weight-bolder text-dark">Exp.</span>
                                                        <span className="ml-2">{this.state.jobDetail.minExperience != '' ? this.state.jobDetail.minExperience : ''}{this.state.jobDetail.maxExperience != '' ? ' - '+this.state.jobDetail.maxExperience : ''}{this.state.jobDetail.minExperience == "" && this.state.jobDetail.maxExperience == "" ? 'Not Specified' : '' }</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-3 mb-3">
                                                    <div className="col-md-12">
                                                        <span className="font-weight-bolder text-dark">Skills.</span>
                                                        <span className="ml-2">{this.state.jobDetail?.skills?.length > 0 ? this.state.jobDetail?.skills?.join(",") : 'Not Specified'}</span>
                                                    </div>
                                                </div>

                                                <span className="text-dark-50 rounded font-weight-bolder font-size-sm">
                                                    <span className="svg-icon pr-2">
                                                        <img src="/assets/eye.png" />
                                                    </span>
                                                    {this.state.jobDetail.views ? this.state.jobDetail.views.length : '0'} Views
                                                </span>

                                                {/* {
                                                    this.state.dataLoading ? '' : this.state.userId == this.state.postUser._id ? '' :
                                                        AllFunction.includesMultiDimension(this.state.chatList, this.state.postUser._id) ?
                                                            <Link to={'/Chat?receiverId=' + this.state.postUser._id + '&Name=' + this.state.postUser.firstName} className="btn btn-hover-text-primary btn-hover-icon-primary btn-sm btn-text-dark-50 bg-hover-light-primary rounded font-weight-bolder font-size-sm p-2">
                                                                <span className="svg-icon svg-icon-md svg-icon-dark-75 pr-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                            <path d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z" fill="#000000" opacity="0.3" />
                                                                            <path d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z" fill="#000000" opacity="0.3" />
                                                                        </g>
                                                                    </svg>
                                                                </span>Message
                                                            </Link>
                                                            :
                                                            <Link to={'/Chat?receiverId=' + this.state.postUser._id + '&Name=' + this.state.postUser.firstName + '&post=' + this.state.jobDetail._id} className="btn btn-hover-text-primary btn-hover-icon-primary btn-sm btn-text-dark-50 bg-hover-light-primary rounded font-weight-bolder font-size-sm p-2">
                                                                <span className="svg-icon svg-icon-md svg-icon-dark-75 pr-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                            <path d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z" fill="#000000" opacity="0.3" />
                                                                            <path d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z" fill="#000000" opacity="0.3" />
                                                                        </g>
                                                                    </svg>
                                                                </span>Connect
                                                            </Link>
                                                } */}
                                            </div>


                                        </div>
                                    </>
                            }
                        </div>

                        <div className="card card-custom overflow-hidden position-relative mb-8">
                            <div className="card-header">
                                <h3 className="card-title">Applications</h3>
                            </div>
                            <div className="card-body">
                                {
                                    this.state.postUser._id == this.state.userId ?
                                        <>
                                            {this.state.appList.length <= 0 ? 'No Applications' :
                                                <div className="col-md-12 mt-10">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>User</th>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Documents</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.appList.map((list, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{ width: '20%' }}>
                                                                                <span style={{ width: 250 }}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="symbol symbol-40 symbol-sm flex-shrink-0">
                                                                                            {
                                                                                                list.userId.profileImage ?
                                                                                                    <img className="" src={Constants.ProfileImageUrl + list.userId.profileImage} alt="photo" />
                                                                                                    :
                                                                                                    <img className="" src="/assets/media/users/default.jpg" alt="photo" />
                                                                                            }

                                                                                        </div>
                                                                                        <div className="ml-4">
                                                                                            <Link to={'/User/' + list.userId._id}>
                                                                                                <div className="text-dark-75 font-weight-bolder font-size-lg mb-0">{list.userId.firstName}&nbsp;{list.userId.lastName}</div>
                                                                                                <div className="text-muted font-weight-bold">{list.userId.email}</div>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </span>
                                                                            </td>
                                                                            <td style={{ width: '10%' }}>
                                                                                <div className="font-weight-bolder mb-0">{moment(list.date).format('DD/MM/YYYY')}</div>
                                                                            </td>
                                                                            <td style={{ width: '55%' }}>
                                                                                <div className="font-weight-bolder mb-0">
                                                                                    {list.description}
                                                                                </div>
                                                                            </td>
                                                                            <td style={{ width: '10%' }}>
                                                                                <a href={Constants.JobMediaUrl+list.file} download={list.file} target="_blank" className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2" title="Edit details">
                                                                                    <span className="flaticon-file-2"></span>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {
                                                        listCount > listPerPage &&
                                                        <div className="row justify-content-end">
                                                            {PaginationFunction.pagination("renderPrevBtn", this)}
                                                            {PaginationFunction.pagination("pageDecrementBtn", this)}
                                                            {PaginationFunction.pagination("renderPageNumbers", this)}
                                                            {PaginationFunction.pagination("pageIncrementBtn", this)}
                                                            {PaginationFunction.pagination("renderNextBtn", this)}
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            </>
                                        :

                                        AllFunction.includesMultiDimension(this.state.appList, this.state.userId) ?
                                            ' No Applications'
                                            :
                                            <div className="pt-3">
                                                <div className="col-md-12 p-5 pb-10 text-dark font-weight-bolder text-center">
                                                    <h3>Apply Now</h3>
                                                </div>
                                                <div className="col-md-12 p-0">
                                                    <div className="form-group row">
                                                        <label className="col-xl-2 col-lg-2">Description</label>
                                                        <div className="col-lg-10 col-md-9 col-sm-12">
                                                            <div className="input-group input-group-lg">
                                                                <textarea className="form-control form-control-lg" name="description" placeholder="Description" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.description} rows={5} />
                                                            </div>
                                                            {this.state.errors.description && <span className="text-danger pl-2">{this.state.errors.description}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 p-0">
                                                    <div className="form-group row mb-3">
                                                        <label className="col-xl-2 col-lg-2">Upload File</label>
                                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                                            <div className="image-input image-input-outline" id="kt_profile_avatar">
                                                                {this.state.image_url ?
                                                                    <div className="image-input-wrapper" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.state.image_url})`, backgroundPosition: 'center' }}></div>
                                                                    :
                                                                    this.state.dataLoading ? '....' : this.state.uploadedImage ?
                                                                        <div className="image-input-wrapper" style={{ backgroundImage: `url(${Constants.PostFileUrl}${this.state.uploadedImage})`, backgroundPosition: 'center', backgroundSize: 'contain' }}></div>
                                                                        :
                                                                        <div className="image-input-wrapper" style={{ backgroundImage: 'url(/assets/noImage.png)', backgroundPosition: 'center' }}></div>
                                                                }
                                                                <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change file">
                                                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                                                    <input type="file" name="postFile" accept=".png, .jpg, .jpeg .pdf" ref={this.myRef} onChange={(e) => this.onUploadFileChange(e)} />
                                                                </label>
                                                                {
                                                                    this.state.image_url &&

                                                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-toggle="tooltip" title="Cancel Image" onClick={(e) => this._onRemoveImage(e)} style={{ position: 'absolute', right: '-10px', bottom: '-5px' }}>
                                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                    </span>
                                                                }
                                                            </div>
                                                            {this.state.errors.postFile && <span className="text-danger pl-2">{this.state.errors.postFile}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end border-top mt-5 pt-10">
                                                    <div>
                                                        {
                                                            this.state.loading ?
                                                                <button type="button" className="btn btn-primary font-weight-bolder text-uppercase py-4 spinner spinner-white spinner-right">Submit</button>
                                                                :
                                                                <button type="button" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" onClick={() => this.onCreateJobApplication()}>Submit</button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Job;
