import React from 'react';

function LoadMoreBtn(props) {
    const { state, isMoreData, currentPage, onClick, loading, disabled } = props;
    let { pageLimit } = props;
    if (!pageLimit) {
        pageLimit = currentPage + 1
    }
    if (isMoreData && currentPage < pageLimit) {
        return (
            <div className='col-md-12 text-right mt-5'>
                <button type="button" disabled={loading || disabled} onClick={onClick} className={loading ? "btn btn-outline-primary btn-sm font-weight-bolder col-md-2 spinner spinner-primary spinner-right" : "btn btn-outline-primary btn-sm font-weight-bolder col-md-2"}>View More</button>
            </div>
        );
    } else {
        return false
    }
}

export default LoadMoreBtn;