import React, { Component } from 'react';
import BusinessCard from '../Components/BusinessCard';
import MessageAlert from '../Components/MessageAlert';
import PostCard from '../Components/PostCard';
import JobCard from '../Components/JobCard';
import UserProfileCard from '../Components/UserProfileCard';
import LoadMoreBtn from '../Components/LoadMoreBtn';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';
import Store from './store';
import Autocomplete from "react-google-autocomplete";
class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            dataLoading: false,
            users: [],
            businesses: [],
            posts: [],
            jobs: [],
            search: '',
            userId: '',
            chatList: [],
            followingList: [],
            connectionList: [],
            isTyping: false,
            typingID: "CardLoader",
            activeTab: 'users',
            success: false,
            successMsg: '',
            domainList: [],
            domain: '',
            street_address: '',
            category: '',
            currentPage: 1,
            listPerPage: 20,
            isMoreData: false,
            viewall : false,
        }
        this.store = Store;
    }
    componentDidMount = async () => {
        await this.store.setSubHeader("Search", ["Search"])
        const params = new URLSearchParams(this.props.location.search);
        const search = params.get('search');
        if (search && search !== '') {
            await this.setState({ search: search })
        }
        const tab = params.get('tab');
        if (tab && tab !== '') {
            await this.setState({ activeTab: tab })
        }
        const viewall = params.get('view');
        if (viewall && viewall !== '') {
            await this.setState({ viewall: viewall })
        }
        await this.getUserCredintials();
    }
    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            const userId = user._id;
            await this.setState({ userId })
        }
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            await this.getChatList();
            await this.getFollowingList();
            await this.getConnectionList();
            await this.getDomainList();

            if(this.state.viewall == 'all') {
                await this.onGetSearchData();
            }
            else if (this.state.search === "") {
                this.setState({ users: [], businesses: [], posts: [] });
                return;
            }
            else {
                
                if (this.state.search !== "") {
                    await this.onGetSearchData();
                }
                else {
                    this.setState({ users: [] });
                }
            }

        }
    }

    getDomainList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'dropdown/Domain',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({ dataLoading: false, domainList: responseData.dropdown.options });

                } else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getConnectionList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'connection',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        connectionList: responseData.connections
                    });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getChatList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'chat/chatList',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        chatList: responseData.ChatList
                    });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getFollowingList = async () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'following',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        followingList: responseData.follows
                    });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onChangeTab = async (tab) => {
        await this.setState({ activeTab: tab, users: [], businesses: [], posts: [], jobs: [], currentPage: 1, isMoreData: false })
        if (tab === 'post') {
            await this.setState({ domain: '', street_address: '' })
        } else {
            await this.setState({ category: '' })
        }
        if (this.state.search !== "") {
            setTimeout(() => {
                window.blockPage()
            }, 300);
            await this.onGetSearchData();
        }
    }

    onSearchChange = async (event) => {
        await this.setState({
            search: event.target.value,
            isTyping: true,
            users: [],
            businesses: [],
            posts: [],
            jobs: [],
            currentPage: 1
        });

        if (this.state.search == '') {
            this.setState({ isTyping: false })
        }
    }

    onGetSearchData = (isLoadingData = true) => {
        let errors = {};
        if (isLoadingData) {
            setTimeout(() => {
                window.blockPage()
            }, 300);
        }

        let urlPath = `common_search/?search=${this.state.search}&type=${this.state.activeTab}&page=${this.state.currentPage || "1"}&limit=${this.state.listPerPage || "20"}`;
        if (this.state.activeTab == "post") {
            urlPath += `&category=${this.state.category || ""}`;
        } else {
            urlPath += `&location=${this.state.location || ""}&domain=${this.state.domain || ""}`;
        }
        fetch(Constants.url + urlPath,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
            }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData.count)
                if (responseData.status) {
                    // this.setState({
                    //     dataLoading: false,
                    //     users: responseData.users || [],
                    //     businesses: responseData.Businesses || [],
                    //     posts: responseData.posts || [],
                    //     jobs: responseData.jobs || [],
                    //     isTyping: false,
                    //     listCount: responseData.count
                    // });
                    if (this.state.activeTab == "users") {
                        let isMoreData = (!!responseData.users && responseData.users.length > (this.state.listPerPage - 1))
                        const UList = this.state.users?.concat(responseData.users || []) || [];
                        this.setState({ users: UList, isMoreData });
                    } else if (this.state.activeTab == "business") {
                        let isMoreData = (!!responseData.Businesses && responseData.Businesses.length > (this.state.listPerPage - 1))
                        const BList = this.state.businesses?.concat(responseData.Businesses || []) || [];
                        this.setState({ businesses: BList, isMoreData });
                    } else if (this.state.activeTab == "post") {
                        let isMoreData = (!!responseData.posts && responseData.posts.length > (this.state.listPerPage - 1))
                        const PList = this.state.posts?.concat(responseData.posts || []) || [];
                        this.setState({ posts: PList, isMoreData });
                    } else if (this.state.activeTab == "job") {
                        console.log(responseData.jobs)
                        let isMoreData = (!!responseData.jobs && responseData.jobs.length > (this.state.listPerPage - 1))
                        const JList = this.state.jobs?.concat(responseData.jobs || []) || [];
                        this.setState({ jobs: JList, isMoreData });
                    }
                    this.setState({ isTyping: false, dataLoading: false, })
                    // console.log(this.state.posts, "RESPONSE")
                    this.getConnectionList();
                    if (isLoadingData) {
                        setTimeout(() => {
                            window.loadPopover()
                            window.unblockPage()
                        }, 300);
                    }
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                    if (isLoadingData) {
                        setTimeout(() => {
                            window.unblockPage()
                        }, 300);
                    }
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                if (isLoadingData) {
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            });
    }

    // Chat
    onFollows = (followUserId) => {
        this.setState({ loading: true })
        let errors = {};
        let body = JSON.stringify({
            followUserId: followUserId,
        });

        fetch(Constants.url + 'follow',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    // this.onGetSearchData();
                    this.getFollowingList();
                    this.setState({ loading: false })
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    sendConnectionRequest = (conectUserId) => {
        this.setState({ loading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);

        let errors = {};
        let body = JSON.stringify({
            conectUserId: conectUserId,
        });

        fetch(Constants.url + 'connect',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.getConnectionList();
                    // this.onGetSearchData();
                    this.setState({ loading: false, success: true, successMsg: 'Request sent successfully.' })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getAddressComponentData = (place) => {
        let address_components = place.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name })
            }
        })
        this.setState({ street_address: place.formatted_address, location: place.formatted_address });

    }

    onChangeAutoComplete = (event) => {
        if (event.target.value === "") {
            this.setState({ country: '', state: '', city: '', street_address: '' })
        }
    }

    render() {
        const { listCount, listPerPage } = this.state;
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-custom mb-7">
                                    <div className="card-body rounded bg-light">
                                        <ul className="nav nav-tabs nav-tabs-line nav-bold nav-tabs-line-2x mb-0">
                                            <li className="nav-item">
                                                <a className={`nav-link ${this.state.activeTab === 'users' ? 'active' : ''}`} data-toggle="tab" href="#kt_tab_pane_1" onClick={() => { this.onChangeTab('users') }}>
                                                    <span className="nav-icon">
                                                        <i className="flaticon-users" style={{ fontSize: '1.5rem' }}></i>
                                                    </span>
                                                    <span className="nav-text" style={{ fontSize: '1.2rem', fontWeight: 700 }}>Users</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${this.state.activeTab === 'business' ? 'active' : ''}`} data-toggle="tab" href="#kt_tab_pane_1" onClick={() => { this.onChangeTab('business') }}>
                                                    <span className="nav-icon">
                                                        <i className="flaticon-users" style={{ fontSize: '1.5rem' }}></i>
                                                    </span>
                                                    <span className="nav-text" style={{ fontSize: '1.2rem', fontWeight: 700 }}>Business</span>
                                                </a>
                                            </li>
                                            {/* <li className="nav-item">
                                                <a className={`nav-link ${this.state.activeTab === 'JobArticles' ? 'active' : ''}`} data-toggle="tab" href="#kt_tab_pane_2" onClick={() => { this.onChangeTab('JobArticles') }}>
                                                    <span className="nav-icon">
                                                        <i className="flaticon-background"></i>
                                                    </span>
                                                    <span className="nav-text" style={{ fontSize: '1.2rem', fontWeight: 700 }}>Job articles</span>
                                                </a>
                                            </li> */}
                                            <li className="nav-item">
                                                <a className={`nav-link ${this.state.activeTab === 'job' ? 'active' : ''}`} data-toggle="tab" href="#kt_tab_pane_1" onClick={() => { this.onChangeTab('job') }}>
                                                    <span className="nav-icon">
                                                        <i className="flaticon-users" style={{ fontSize: '1.5rem' }}></i>
                                                    </span>
                                                    <span className="nav-text" style={{ fontSize: '1.2rem', fontWeight: 700 }}>Job Posting</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${this.state.activeTab === 'post' ? 'active' : ''}`} data-toggle="tab" href="#kt_tab_pane_2" onClick={() => { this.onChangeTab('post') }}>
                                                    <span className="nav-icon">
                                                        <i className="flaticon2-crisp-icons" style={{ fontSize: '1.5rem' }}></i>
                                                    </span>
                                                    <span className="nav-text" style={{ fontSize: '1.2rem', fontWeight: 700 }}>News & Posts</span>
                                                </a>
                                            </li>

                                        </ul>
                                        <div className="d-flex flex-column flex-lg-row-auto p-10 p-md-10">
                                            <h1 className="font-weight-bolder text-dark mb-8">Search</h1>
                                            {/* <div className="font-size-h4 mb-8">Get users,posts and business</div> */}
                                            <div className="row">
                                                <div className="d-flex flex-center bg-white rounded col-md-4">
                                                    <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24" />
                                                                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <input type="text" className="form-control border-0 font-weight-bold pl-2" placeholder="Search by people,post,business" name='search' onChange={(event) => this.onSearchChange(event)} value={this.state.search} />
                                                </div>
                                                {
                                                    this.state.activeTab === 'post' ?
                                                        <div className="col-md-4">
                                                            <select className="form-control form-control-lg" name="category" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.category}>
                                                                <option value="">Select Category</option>
                                                                {
                                                                    Constants.POST_CATEGORIES.map((cat, id) => {
                                                                        return (
                                                                            <option key={id} value={`${cat.value}`}>{cat.title}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>

                                                        :
                                                        <div className="col-md-4">
                                                            <select className="form-control form-control-lg" name="domain" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.domain}>
                                                                <option value="">Select Domain</option>
                                                                {
                                                                    this.state.domainList.map((list, index) => {
                                                                        return (
                                                                            <option value={list.value} key={index}>{list.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                }
                                                {
                                                    this.state.activeTab === 'post' ? '' :
                                                        <div className="col-md-3">
                                                            <Autocomplete
                                                                apiKey={Constants.GOOGLE_MAPS_API_KEY}
                                                                style={{ width: "100%" }}
                                                                onPlaceSelected={(place) => {
                                                                    this.getAddressComponentData(place)
                                                                }}
                                                                className="form-control form-control-lg"
                                                                name="street_address"
                                                                defaultValue={this.state.street_address}
                                                                onChange={(event) => { this.onChangeAutoComplete(event) }}
                                                            />
                                                        </div>
                                                }

                                                <div className="col-md-1">
                                                    <button className="btn btn-light-primary px-6 font-weight-bold"
                                                        onClick={async () => {
                                                            await this.setState({ currentPage: 1, users: [], businesses: [], posts: [], jobs: [] })
                                                            await this.onGetSearchData()
                                                        }}>Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">

                                {
                                    this.state.isTyping ? <div style={{ height: 150 }} className="spinner spinner-lg spinner-primary spinner-center mr-15"></div> :

                                        <div className="mb-0">
                                            {
                                                this.state.dataLoading ? '.....' :
                                                    <>
                                                        {
                                                            this.state.search !== '' && this.state.users.length <= 0 && this.state.posts.length <= 0 && this.state.businesses.length <= 0 ?
                                                                <div className="card card-custom mb-7">
                                                                    <div className="card-header">
                                                                        <div className="card-title">No results found</div>
                                                                    </div>
                                                                    <div className="card-body rounded">
                                                                        <div className="row justify-content-center" style={{ height: 665, backgroundImage: 'url("assets/EmptyScreen/Data.png")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 450, objectFit: 'contain' }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                    {this.state.users.length > 0 &&
                                                                        <div>
                                                                            <div className="d-flex justify-content-between align-items-center mb-7">
                                                                                <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Grow Your Network</h2>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    this.state.success &&
                                                                                    <MessageAlert children={this.state.successMsg} variant="success" />
                                                                                }
                                                                            </div>

                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    {

                                                                                        this.state.dataLoading ? '' :
                                                                                            <UserProfileCard isSearch={true} userList={this.state.users} connectionList={this.state.connectionList} onConnectUser={(id) => { this.sendConnectionRequest(id) }} chatList={this.state.chatList} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {/* Business */}
                                                                    {this.state.businesses.length > 0 && <div className="d-flex justify-content-between align-items-center mb-7">
                                                                        <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Business</h2>
                                                                    </div>}
                                                                    <div className="row">
                                                                        <BusinessCard businessList={this.state.businesses} userId={this.state.userId} chatList={this.state.chatList} />
                                                                    </div>

                                                                    {/*  Post */}
                                                                    {this.state.posts.length > 0 && <div className="d-flex justify-content-between align-items-center mb-8 mt-8">
                                                                        <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Posts</h2>
                                                                    </div>}
                                                                    <div className="row">
                                                                        {
                                                                            this.state.dataLoading ? 'this' :
                                                                                <PostCard postList={this.state.posts} userId={this.state.userId} chatList={this.state.chatList} />
                                                                        }
                                                                    </div>
                                                                    {/*  Post */}
                                                                    {this.state.jobs.length > 0 && <div className="d-flex justify-content-between align-items-center mb-8 mt-8">
                                                                        <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Jobs</h2>
                                                                    </div>}
                                                                    <div className="row">
                                                                        {
                                                                            this.state.dataLoading ? 'this' :
                                                                                <JobCard postList={this.state.jobs} userId={this.state.userId} chatList={this.state.chatList} />
                                                                        }
                                                                    </div>

                                                                    <LoadMoreBtn
                                                                        isMoreData={this.state.isMoreData}
                                                                        disabled={this.state.loading}
                                                                        pageLimit={3}
                                                                        currentPage={this.state.currentPage}
                                                                        onClick={async () => {
                                                                            await this.setState({ isLoadMore: true, currentPage: !!this.state.currentPage ? (parseInt(this.state.currentPage) + 1) : 1 });
                                                                            await this.onGetSearchData(false, false);
                                                                        }}
                                                                    />
                                                                </>
                                                        }
                                                    </>
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Search;