
// import keycloak from '../keycloak';
import Constants from '../Utilities/Constants';
import Keycloak from "keycloak-js";
function KeycloakSigninButton() {

    const signIn = async () => {
        window.location.href = '/secured';
        // console.log("Keycloak")
        // const keycloak = new Keycloak('/keycloak.json');
        // console.log(Keycloak)
        // // keycloak.login()
        // keycloak.init({ onLoad:'login-required' }).then(authenticated => {
        //     console.log(keycloak)
        //     if (authenticated) {
        //         console.log(authenticated)
        //     }
        // })
    }

    const verifyUser = (userInfo) => {

        let body = JSON.stringify({
            email: userInfo.email
        });

        fetch(Constants.url + "verify/user",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            })
            .then((response) => response.json())
            .then((responseData) => {
                console.log(responseData)
                if (responseData.status) {

                    // props.callBack(responseData)
                } else {
                    console.log("Error")
                }
            }).catch((err) => {
                console.log("err", err);
            });
    }

    return (
        <button onClick={signIn} type="button" className="btn font-weight-bolder px-4 py-2 my-3 font-size-lg shadow-sm" style={{ backgroundColor: '#ffffff', borderColor: '#dddddd4a' }}>
            <img src="assets/logo/vuf_logo.png" alt="google login" className="svg-icon svg-icon-md" style={{ width: 30, height: 30 }}></img>
            <span>Sign in with VUF</span>
        </button>
    )
}

export default KeycloakSigninButton;

// import { useKeycloak } from '@react-keycloak/web';
// import React, { useEffect } from 'react'
// import Constants from '../Utilities/Constants';

// const KeycloakSigninButton = (props) => {
//     const { keycloak } = useKeycloak()


//     useEffect(() => {
//         console.log("Hook", keycloak)
//         if (keycloak.authenticated) {
//             keycloak.loadUserInfo()
//                 .then(function (profile) {
//                     console.log(profile)
//                     verifyUser(profile)
//                 }).catch(function () {
//                     console.log('Failed to load user profile');
//                 });
//         }


//     }, [props])

//     const verifyUser = (userInfo) => {

//         let body = JSON.stringify({
//             email: userInfo.email
//         });

//         fetch(Constants.url + "verify/keycloak-user",
//             {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: body,
//             })
//             .then((response) => response.json())
//             .then((responseData) => {
//                 console.log(responseData)
//                 if (responseData.status) {

//                     props.callBack(responseData)
//                 } else {
//                     console.log("Error")
//                 }
//             }).catch((err) => {
//                 console.log("err", err);
//             });
//     }

//     return (
//         <>
//             <button type="button" className="btn font-weight-bolder px-4 py-2 my-3 font-size-lg shadow-sm" style={{ backgroundColor: '#ffffff', borderColor: '#dddddd4a' }}
//                 onClick={() => {
//                     if (keycloak) {
//                         keycloak.login()
//                     }
//                 }}
//             >
//                 <img src="assets/media/svg/social-icons/keycloak.svg" alt="google login" className="svg-icon svg-icon-md" style={{ width: 30, height: 30 }}></img>
//                 <span>Sign in with Keycloak</span>
//             </button>

//         </>


//     )
// }

// export default KeycloakSigninButton
