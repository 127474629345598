import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BusinessCard from '../Components/BusinessCard';
import PostCard from '../Components/PostCard';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';
import Store from './store';

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            businessDetail: '',
            id: this.props.match.params.id ? this.props.match.params.id : '',
            dataLoading: false,
            Userbusiness: '',
            UserPost: [],
            products: [],
            chatList: [],
            services: [],
            activeTab: 'Post',
            userDetail: ''
        }
        this.store = Store;
    }
    componentDidMount = async () => {
        await this.store.setSubHeader("Profile", ["Profile", "Profile Detail"])
        await this.getUserCredintials();

    }

    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            const userId = user._id;
            await this.setState({ userId })
        }
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            if (this.state.id && this.state.id !== '') {
                await this.getUserDetail();
                await this.getChatList();
            }
        }
    }

    getUserDetail = async () => {
        this.setState({ dataLoading: true })
        let errors = {};
        fetch(Constants.url + 'user/' + this.state.id,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        userDetail: responseData.users,
                        UserPost: responseData.posts,
                        Userbusiness: responseData.businesses,
                        dataLoading: false,
                    });

                    console.log(this.state.userDetail)
                    // window.location = '/';
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getChatList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'chat/chatList',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        chatList: responseData.ChatList
                    });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    render() {
        let newchatListId = AllFunction.getChatListId(this.state.chatList, this.state.userDetail._id);
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    {
                        this.state.dataLoading ? '' :
                            <div className="container">
                                <div className="card card-custom gutter-b">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 mr-7">
                                                <div className="symbol symbol-50 symbol-lg-100 symbol-circle">
                                                    {
                                                        this.state.userDetail.profileImage ?
                                                            <img alt="Pic" src={Constants.ProfileImageUrl + this.state.userDetail.profileImage} />
                                                            :
                                                            <img alt="Pic" src="/assets/noImage.png" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                    <div className="mr-3">
                                                        <div className="d-flex align-items-center mr-3">
                                                            <span className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">{this.state.userDetail.firstName}</span>
                                                        </div>
                                                        <div className="d-flex flex-wrap my-2">
                                                            <div className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                            <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                            <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                        </g>
                                                                    </svg>
                                                                </span>{this.state.userDetail.email}
                                                            </div>
                                                            <div className="text-muted font-weight-bold">
                                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                            <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                {this.state.userDetail.city} {this.state.userDetail.state ? ", " + this.state.userDetail.state : ''} {this.state.userDetail.country ? "- " + this.state.userDetail.country : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center flex-wrap justify-content-between">
                                                    <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">
                                                        <Link to={'/Chat?receiverId=' + this.state.userDetail._id + '&Name=' + this.state.userDetail.firstName + '&chatListId=' + newchatListId} className="btn btn-light-success font-weight-bolder">
                                                            <i className="flaticon2-chat-1"></i> Message
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card card-custom gutter-b">
                                            <div className="card-header card-header-tabs-line" style={{ minHeight: 20 }}>
                                                <div className="card-toolbar">
                                                    <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x" role="tablist">
                                                        <li className="nav-item">
                                                            <a className={`nav-link ${this.state.activeTab === 'Post' ? 'active' : ''}`} data-toggle="tab" href="#kt_apps_contacts_view_tab_1" style={{ paddingTop: '2rem', paddingBottom: '1rem' }} onClick={() => { this.setState({ activeTab: 'Post' }) }}>
                                                                <span className="nav-icon mr-2">
                                                                    <span className="svg-icon mr-3">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24" />
                                                                                <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) " />
                                                                                <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                                <span className="nav-text">Post</span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={`nav-link ${this.state.activeTab === 'Business' ? 'active' : ''}`} data-toggle="tab" href="#kt_apps_contacts_view_tab_1" style={{ paddingTop: '2rem', paddingBottom: '1rem' }} onClick={() => { this.setState({ activeTab: 'Business' }) }}>
                                                                <span className="nav-icon mr-2">
                                                                    <span className="svg-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24" />
                                                                                <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000" />
                                                                                <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                                                                                <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3" />
                                                                            </g>
                                                                        </svg></span>
                                                                </span>
                                                                <span className="nav-text">Business</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                {
                                                    this.state.activeTab === 'Post' &&
                                                    <div className="row">
                                                        {
                                                            this.state.dataLoading ? '' : this.state.UserPost.length <= 0 ? <div className="text-center text-muted font-size-h2 font-weight-bold p-5 col-md-12">No Post</div> :
                                                                <PostCard postList={this.state.UserPost} userId={this.state.userId} chatList={this.state.chatList} />
                                                        }
                                                    </div>
                                                }
                                                {
                                                    this.state.activeTab === 'Business' &&
                                                    <div className="row">
                                                        {
                                                            this.state.Userbusiness.length <= 0 ? <div className="text-center text-muted font-size-h2 font-weight-bold p-5 col-md-12">No Business</div> :

                                                                <BusinessCard businessList={this.state.Userbusiness} userId={this.state.userId} chatList={this.state.chatList} />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </>
        )
    }

}

export default UserProfile;