import AppleSignin from 'react-apple-signin-auth';
import Constants from '../Utilities/Constants';

/** Apple Signin button */
const MyAppleSigninButton = ({ ...rest }) => (
	<AppleSignin
		/** Auth options passed to AppleID.auth.init() */
		authOptions={{
			clientId: 'com.vufgbn',
			scope: 'email name',
			redirectURI: 'https://black-lizard-70.loca.lt/apple/callback',
			state: '',
			nonce: 'nonce',
			usePopup: true,
		}}
		/** General props */
		uiType="dark"
		/** className */
		className="apple-auth-btn"
		/** Allows to change the button's children, eg: for changing the button text */
		/** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
		onSuccess={async (response) => await verifyUser(response)}
		/** Called upon signin error */
		onError={(error) => console.error(error)} // default = undefined
		/** Checkout README.md for further customization props. */
		/** Spread rest props if needed */
		{...rest}
	/>
);

const verifyUser = async (res) => {

	let body = JSON.stringify({
		identityToken: res.authorization.id_token,
		user: res.user,
		Signup: true
	});

	await fetch(Constants.url + "appleWebCallback",
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		})
		.then((response) => response.json())
		.then((responseData) => {
			console.log(responseData)
			if (responseData.status) {
				localStorage.setItem('user', JSON.stringify(responseData.body.user));
				localStorage.setItem('token', "Bearer " + responseData.body.token);

				window.location = '/';
			} else {
				console.log("Error")
			}
		}).catch((err) => {
			console.log("err", err);
		});
}

export default MyAppleSigninButton;
