/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    url: process.env.REACT_APP_API_URL,
    urlShort: process.env.REACT_APP_API_SHORT_URL,
    ProfileImageUrl: process.env.REACT_APP_API_PROFILE_IMAGE_URL,
    CompanyLogoUrl: process.env.REACT_APP_API_COMPANY_LOGO_URL,
    CompanyDocsUrl: process.env.REACT_APP_API_COMPANY_DOCS_URL,
    ChatMediaUrl: process.env.REACT_APP_API_CHAT_MEDIA_URL,
    JobMediaUrl: process.env.REACT_APP_API_JOB_MEDIA_URL,
    ProductImageUrl: process.env.REACT_APP_API_PRODUCT_IMAGE_URL,
    ClientId: process.env.REACT_APP_API_CLIENT_ID,
    PostFileUrl: process.env.REACT_APP_API_POST_FILE_URL,
    shareUrl: process.env.REACT_APP_API_SHARE_URL,
    AUTH_URL: 'https://api.login.vishvumiyafoundation.org/api/',
    AUTH_APP_NAME: "VUFGBN",
    AUTH_SECRET: "gbn!@y84oua$o4",


    // GOOGLE_MAPS_API_KEY: 'AIzaSyBw9rATBOuE84DS7OsYwNjqW9Vd_CbTmtA',
    GOOGLE_MAPS_API_KEY: 'AIzaSyAeByXtQ4rJOq887gNcEAtAdXysiQgwVOk',
    POST_CATEGORIES: [
        { title: "News", value: "News" },
        { title: "Social", value: "Social" },
        { title: "Article", value: "Article" },
        { title: "Product/Service Information", value: "Product/Service Information" },
        { title: "Semiconductor", value: "Semiconductor" },
        { title: "Gaming", value: "Gaming" },
        { title: "Government Officials", value: "Government Officials" },
        { title: "Community Service", value: "Community Service" },
    ],
    LocalUrl: process.env.REACT_LOCAL_URL
}
