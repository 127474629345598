import React, { Component } from 'react';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import moment from 'moment';
import Store from './store';
import { Link } from 'react-router-dom';
import AllFunction from '../Utilities/Functions';
class PostDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            postId: this.props.match.params.id ? this.props.match.params.id : '',
            token: '',
            postDetail: '',
            dataLoading: false,
            errors: {},
            userId: '',
            chatList: [],
            listLoading: false,
            postUser: ''
        }
        this.store = Store;
    }


    componentDidMount = async () => {
        await this.getPostDetail();
        // console.log(this.props.match.params)
        await this.store.setSubHeader("Post", ["Post", "Post Detail"])
        localStorage.setItem('lastPage', "/PostDetail/" + this.state.postId);
    }

    getPostDetail = () => {
        // console.log("Update Call")
        let errors = {};
        let body = JSON.stringify({
            userId: this.state.userId
        })
        this.setState({ dataLoading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);
        fetch(Constants.url + 'postDetail/' + this.state.postId,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData, "Update Data====")
                if (responseData.status) {
                    this.setState({
                        dataLoading: false,
                        postDetail: responseData.post,
                        postUser: responseData.post.userId
                    });
                    // console.log(this.state.postDetail.userId._id)
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    render() {
        return (
            <>
                <div id="kt_header_mobile" className="header-mobile header-mobile-fixed" style={{ backgroundColor: '#07a398' }}>
                    {/* <!--begin::Logo--> */}
                    <Link to={'/'}>
                        <img alt="Logo" src="/assets/logo/Logo-white.png" className="max-h-25px" />
                    </Link>

                    {/* <!--end::Logo--> */}
                    {/* <!--begin::Toolbar--> */}
                    <div className="d-flex align-items-center">
                        <button className="btn p-0 burger-icon burger-icon-left ml-4" id="kt_header_mobile_toggle">
                            <span></span>
                        </button>
                        <button className="btn p-0 ml-2" id="kt_header_mobile_topbar_toggle">
                        </button>
                    </div>
                    {/* <!--end::Toolbar--> */}
                </div>
                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-row flex-column-fluid page">
                        <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                            <div id="kt_header" className="header header-fixed">
                                <div className="container d-flex align-items-stretch justify-content-between">
                                    <div className="d-flex align-items-stretch mr-3">
                                        <div className="header-logo">
                                            <Link to={'/'}>
                                                <img alt="Logo" src="/assets/logo/Logo-white.png" className="logo-default max-h-40px" />
                                                <img alt="Logo" src="/assets/logo/Logo.png" className="logo-sticky max-h-30px" />
                                                {/* <ReactSVG src={"assets/logo/Logo.svg"} className="logo-sticky max-h-40px" /> */}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content d-flex flex-column flex-column-fluid mb-20" id="kt_content">
                                <div className="subheader py-2 py-lg-7 subheader-transparent" id="kt_subheader">
                                    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                        <div className="d-flex align-items-center flex-wrap mr-1">
                                            <div className="d-flex flex-column">
                                                <h2 className="text-white font-weight-bold my-2 mr-5">Post</h2>
                                                <div className="d-flex align-items-center font-weight-bold my-2">
                                                    <a href="#" className="opacity-75 hover-opacity-100">
                                                        <i className="flaticon2-shelter text-white icon-1x"></i>
                                                    </a>
                                                    <span className="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                                                    <span>
                                                        <a href="" className="text-white text-hover-white opacity-75 hover-opacity-100">Post Detail</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column-fluid">
                                    <div className="container">
                                        <div className="card card-custom gutter-b">
                                            {
                                                this.state.dataLoading ? '' :
                                                    <>
                                                        <div className="card-body" style={{ minHeight: 360 }}>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-45 symbol-light mr-5">
                                                                    <span className="symbol-label">
                                                                        <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
                                                                                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
                                                                                    <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                                                                                    <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex flex-column flex-grow-1">
                                                                    <a href="#" className="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{this.state.postDetail.title}</a>
                                                                    <div className="d-flex">
                                                                        <div className="d-flex align-items-center pr-5">
                                                                            <span className="svg-icon svg-icon-md svg-icon-primary pr-1">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                                        <path d="M12,22 C7.02943725,22 3,17.9705627 3,13 C3,8.02943725 7.02943725,4 12,4 C16.9705627,4 21,8.02943725 21,13 C21,17.9705627 16.9705627,22 12,22 Z" fill="#000000" opacity="0.3" />
                                                                                        <path d="M11.9630156,7.5 L12.0475062,7.5 C12.3043819,7.5 12.5194647,7.69464724 12.5450248,7.95024814 L13,12.5 L16.2480695,14.3560397 C16.403857,14.4450611 16.5,14.6107328 16.5,14.7901613 L16.5,15 C16.5,15.2109164 16.3290185,15.3818979 16.1181021,15.3818979 C16.0841582,15.3818979 16.0503659,15.3773725 16.0176181,15.3684413 L11.3986612,14.1087258 C11.1672824,14.0456225 11.0132986,13.8271186 11.0316926,13.5879956 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z" fill="#000000" />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                            <span className="text-muted font-weight-bold">Due {moment(this.state.postDetail.postDate).format('DD MMM YYYY')} </span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="pt-3">
                                                                <div className="text-dark-75 font-size-lg font-weight-normal pt-5 mb-5">
                                                                    <p dangerouslySetInnerHTML={{ __html: this.state.postDetail.description }}></p>
                                                                </div>

                                                                {
                                                                    this.state.postDetail.file && this.state.postDetail.file !== '' ?
                                                                        <div className="bgi-no-repeat bgi-size-cover rounded min-h-180px w-200 mb-10" style={{ backgroundImage: `url(${Constants.PostFileUrl}${this.state.postDetail.file})`, backgroundSize: 'contain' }}></div>
                                                                        : ''
                                                                }

                                                                <span className="text-dark-50 rounded font-weight-bolder font-size-sm p-2 ml-5">
                                                                    <span className="svg-icon pr-2">
                                                                        <img src="/assets/eye.png" />
                                                                    </span>
                                                                    {this.state.postDetail.views ? this.state.postDetail.views.length : '0'} Views
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PostDetail;