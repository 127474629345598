import React, { Component } from 'react';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import store from './store';
import { Link } from 'react-router-dom';
import AllFunction from '../Utilities/Functions';
import PostCard from '../Components/PostCard';
import LoadMoreBtn from '../Components/LoadMoreBtn';
class PostList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: '',
            postList: [],
            loading: false,
            userId: '',
            listLoading: false,
            chatList: [],
            searchName: '',
            category: '',
            currentPage: 1,
            listPerPage: 20,
            isMoreData: false
        }
        this.store = store;
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
        await this.store.setSubHeader("Post", ["Post"])
    }

    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            const userId = user._id;
            await this.setState({ userId })
        }
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            await this.getData();
            await this.getChatList();
        }
    }

    onSearchChange = async (event) => {
        await this.setState({
            searchName: event.target.value,
            postList: [],
            isLoadMore: false
        });
    }

    getChatList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'chat/chatList',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        chatList: responseData.ChatList
                    });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getData = () => {
        this.setState({ loading: true })
        let errors = {};

        let urlpath = `post?page=${this.state.currentPage}&limit=${this.state.listPerPage}&name=${this.state.searchName}&category=${this.state.category}`;
        fetch(Constants.url + urlpath,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    let isMoreData = (!!responseData.posts && responseData.posts.length > (this.state.listPerPage - 1))
                    const psList = this.state.postList?.concat(responseData.posts || []) || [];
                    this.setState({ postList: psList, isMoreData });
                    this.setState({
                        loading: false,
                    });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onSearchPost = () => {
        setTimeout(() => {
            window.loadPopover()
            window.blockPage()
        }, 300);
        let errors = {};
        fetch(Constants.url + 'searchPost/?name=' + this.state.searchName + '&category=' + this.state.category,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        loading: false,
                        postList: responseData.posts
                    });
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    render() {
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom overflow-hidden position-relative mb-8">
                            <div className="mb-0 mt-10 card-header">
                                <div className="col-lg-12 col-xl-12">
                                    <div className="row align-items-center">
                                        <div className="col-md-4 ">
                                            <div className="input-icon">
                                                <input type="text" className="form-control form-control-solid" placeholder="Search..." id="kt_datatable_search_query" name="searchName" onChange={(event) => { this.onSearchChange(event) }} value={this.state.searchName} />
                                                <span>
                                                    <i className="flaticon2-search-1 text-muted"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 ">
                                            <select className="form-control form-control-solid" id="kt_datatable_search_status" name="category" onChange={(event) => { AllFunction.onChange(event, this) }} value={this.state.category}>
                                                <option value="">Category</option>
                                                {
                                                    Constants.POST_CATEGORIES.map((cat, id) => {
                                                        return (
                                                            <option value={`${cat.value}`}>{cat.title}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-xl-4 mt-5 mt-lg-0">
                                            <button className="btn btn-light-primary px-6 font-weight-bold"
                                                onClick={async () => {
                                                    await this.setState({ currentPage: 1,postList: [] })
                                                    await this.getData()
                                                }}>Search</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    {
                                        this.state.dataLoading ? '' :
                                            <PostCard postList={this.state.postList} userId={this.state.userId} chatList={this.state.chatList} />
                                    }
                                </div>
                                <LoadMoreBtn
                                    isMoreData={this.state.isMoreData}
                                    disabled={this.state.loading}
                                    currentPage={this.state.currentPage}
                                    onClick={async () => {
                                        await this.setState({ isLoadMore: true, currentPage: !!this.state.currentPage ? (parseInt(this.state.currentPage) + 1) : 1 });
                                        await this.getData(false, false);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PostList;