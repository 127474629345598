import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Redirect, Switch, withRouter } from 'react-router-dom';
import Footer from '../Include/Footer';
import Header from '../Include/Header';
import MobileHeader from '../Include/MobileHeader';
import SubHeader from '../Include/SubHeader';
import Chat from '../Pages/Chat';
import CreateCompany from '../Pages/CreateCompany';
import CreatePost from '../Pages/CreatePost';
import Faq from '../Pages/Faq';
import Search from '../Pages/Search';
import Post from '../Pages/Post';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import Profile from '../Pages/Profile';
import Services from '../Pages/Services';
import CompanyDetail from '../Pages/CompanyDetail';
import Store from '../Pages/store';
import MyNetwork from '../Pages/MyNetwork';
import InvitationManager from '../Pages/InvitationManager';
import Dashboard from '../Pages/Dashboard';
import CreateProduct from "../Pages/CreateProduct"
import UserProfile from "../Pages/UserProfile"
import Auth from '../Utilities/Auth';
import PostList from '../Pages/PostList';
import Feedback from '../Pages/Feedback';
import JobList from '../Pages/JobList';
import Job from '../Pages/Job';
import CreateJob from '../Pages/CreateJob';
import Constants from '../Utilities/Constants';
import Notifications from '../Pages/Notifications';
import Support from '../Pages/Support';
class Routes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ischeckAuth: false,
			loading: true,
			isLogout: false,
			token: '',
			user: ''
		}
		this.store = Store;
	}

	componentDidMount = async () => {
		console.log("Routes")
		const token = await localStorage.getItem('token');
		let user = await localStorage.getItem('user');
		user = JSON.parse(user);
		if (!token) {
			console.log("Logout")
			// Auth.Logout();
			await this.setState({ isLogout: true, })
		} else {
			await this.setState({ loading: false });
			await this.setState({ user, token });
			console.log("Is Token")
			await this.getUserDetail();
			
			setInterval(() => {
				this.getChatList();
			}, 5000);
		}
	}

	getUserDetail = () => {
		
		this.setState({ dataLoading: true })
		let errors = {};
		fetch(Constants.url + 'user/' + this.state.user._id,
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': this.state.token
				},

			}).then((response) => {
				if (response.status == 403) {
					Auth.Logout();
				}
			})
			.then((responseData) => {
				// console.log(responseData)
			})
			.catch(err => {
				this.setState({ dataLoading: false })
				errors.error = "Something went wrong, Please try again after sometime.";
				this.setState({ errors })
				console.log(err);
			});
	}

	getChatList = async () => {
		this.setState({ listLoading: true })
		let errors = {};
		fetch(Constants.url + 'chat/chatList',
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': this.state.token
				},
			}).then((response) => response.json())
			.then((responseData) => {
				if (responseData.status) {
					this.setState({
						listLoading: false,
						chatList: responseData.ChatList
					});
					let ids = [];
					responseData.ChatList.map((list, index) => {						
						if (!list.seen) {
							// console.log(list)
							ids.push(list._id);							
							var msg = list.message.type === 'text' ? list.message.message : 'Image';
							let chatLsIds = JSON.parse(localStorage.getItem('ChatListIds')) || [];							
							if(chatLsIds.indexOf(list._id) == -1 && list.lastSenderID !== this.state.user._id){
								// console.log(chatLsIds.indexOf(list._id),list._id,"Logs Of",list.lastSenderID,this.state.user._id)
								// console.log(list.lastSenderID,this.state.user._id)
								this.showNotification(msg);
								localStorage.setItem('ChatListIds',JSON.stringify(ids));
							}						
						}
					})
				}else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
				else {
					errors.error = responseData.error;
					this.setState({ errors: errors, listLoading: false })
				}
			})
			.catch(err => {
				this.setState({ listLoading: false })
				errors.error = "Something went wrong, Please try again after sometime.";
				this.setState({ errors })
				console.log(err);
			});
	}

	showNotification(text) {
		console.log("Called")
        // new Notification('Hey')
        var options = {
            body: text,
            dir: "ltr"
        };
        var notification = new Notification("Global Business Network", options);

        notification.addEventListener('click', () => {
            window.open(Constants.LocalUrl+'/Chat', '_blank');
        });
    }

	render() {
		if (this.state.loading && this.state.isLogout) {
			return (<Redirect to='/login' />)
		} else if (this.state.loading) {
			return (<div>Loading...</div>);
		}
		else {
			return (
				<>
				<Router forceRefresh={true} basename="/">
					<MobileHeader />
					<div className="d-flex flex-column flex-root">
						<div className="d-flex flex-row flex-column-fluid page">
							<div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
								<Header />
								<div className="content d-flex flex-column flex-column-fluid mb-20" id="kt_content">
									<SubHeader store={this.store} />
									<Switch>
										
										<Route path="/" exact name="home" render={(props) => <Dashboard  {...props} />}></Route>
										<Route path="/faq" name="Faq" component={Faq}></Route>
										<Route path="/CreateCompany" component={CreateCompany}></Route>
										<Route path="/EditCompany/:id" name="Edit Company" component={CreateCompany}></Route>
										<Route path="/Company/:id" component={CompanyDetail}></Route>
										<Route path="/Chat" component={Chat}></Route>
										<Route path="/Services" component={Services}></Route>
										<Route path="/CreatePost" component={CreatePost}></Route>
										<Route path="/EditPost/:id" component={CreatePost}></Route>
										<Route path="/Post/:id" component={Post}></Route>
										<Route path="/PrivacyPolicy" component={PrivacyPolicy}></Route>
										<Route path="/Profile" component={Profile}></Route>
										<Route path="/MyNetwork" component={MyNetwork}></Route>
										<Route path="/InvitationManager" component={InvitationManager}></Route>
										<Route path="/CreateProduct/:businessId" component={CreateProduct}></Route>
										<Route path="/EditProduct/:id" component={CreateProduct}></Route>
										<Route path="/Search" component={Search}></Route>
										<Route path="/User/:id" component={UserProfile}></Route>
										<Route path="/Posts" component={PostList}></Route>
										<Route path="/FeedBack" component={Feedback}></Route>
										<Route path='/JobArticles' name="Job" component={JobList}></Route>
										<Route path='/CreateJob' component={CreateJob}></Route>
										<Route path="/EditJob/:id" component={CreateJob}></Route>
										<Route path="/JobDetails/:id" component={Job}></Route>
										<Route path='/Notifications' component={Notifications}></Route>
										<Route path="/support" name="Support" component={Support}></Route>
									</Switch>
								</div>
								<Footer />
							</div>
						</div>
					</div>
				</Router>
				</>
			);
		}
	}
}

export default withRouter(Routes)