import React, { Component } from 'react';
import Store from './store';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import AllFunction from '../Utilities/Functions';

class InvitationManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            listLoading: false,
            userId: '',
            connectionList: [],
            errors: {},
            alert: false,
            conectId: '',
            success: false,
            successMsg: '',
            activeTab: 'received',
            receivedList: [],
            sentList: []
        }
        this.store = Store;
    }
    componentDidMount = async () => {
        await this.store.setSubHeader("My Network", ["My Network", "Manage Invitation"])
        await this.getUserCredintials();
        const params = new URLSearchParams(this.props.location.search);
        const tab = params.get('tab');
        if (tab && tab !== '') {
            await this.setState({ activeTab: tab })
        }
        setTimeout(() => {
            window.loadPopover()
        }, 300);
    }

    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            const userId = user._id;
            await this.setState({ userId })
        }
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            await this.getAllPendingConnectionList();
        }
    }

    getAllPendingConnectionList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        setTimeout(() => {
            window.blockPage()
        }, 300);
        fetch(Constants.url + 'connection/Allpending',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    let receivedList = [];
                    let sentList = [];
                    let users = responseData.connections.filter((conect) => {
                        if (conect.userId._id === this.state.userId) {
                            sentList.push(conect)
                        } else {
                            receivedList.push(conect)
                        }
                    })

                    this.setState({
                        listLoading: false,
                        connectionList: responseData.connections,
                        receivedList: receivedList,
                        sentList: sentList
                    });
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    onChangeConnectionStatus = (conectId, conectionStatus) => {
        this.setState({ loading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);
        let errors = {};
        let body = JSON.stringify({
            id: conectId,
            status: conectionStatus
        })
        fetch(Constants.url + 'connection/changeStatus',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    let list = this.state.connectionList.filter(conect => conect._id != conectId)
                    this.setState({
                        loading: false,
                        connectionList: list
                    });

                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    /**
     * 
     * @param {*} conectId 
     * @description Open Confirm Alert
     */
    onConfirmAlert = async (conectId) => {
        // console.log(conectId)
        await this.setState({ alert: true, conectId: conectId });
    }

    /**
     * Remove request from sent conection
     */
    onRemoveConnection = () => {
        this.setState({ loading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);
        let errors = {};
        let body = JSON.stringify({
            id: this.state.conectId,
        })
        // console.log(body)
        fetch(Constants.url + 'connection/remove',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    let list = this.state.sentList.filter(conect => conect._id != this.state.conectId)
                    this.setState({
                        loading: false,
                        sentList: list
                    });
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                    this.setState({ connectionList: list, alert: false, success: true, successMsg: 'Connection removed successfully.' });
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    // Hide Success Alert
    hideSuccessAlert = async () => {
        await this.setState({ success: false, successMsg: '' });
    }


    render() {
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom gutter-b" style={{ minHeight: 360 }}>
                            <h3 style={{ padding: '2rem 2rem 0 2rem' }} className="mb-0">
                                Manage Invitations
                            </h3>
                            <div className="card-header card-header-tabs-line" style={{ minHeight: 15 }}>
                                <div className="card-toolbar">
                                    <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${this.state.activeTab === 'received' ? 'active' : ''}`} data-toggle="tab" href="#kt_apps_contacts_view_tab_1" onClick={() => this.setState({ activeTab: 'received' })} style={{ paddingTop: '1.8rem', paddingBottom: '0.8rem' }}>
                                                <span className="nav-text">Received</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${this.state.activeTab === 'sent' ? 'active' : ''}`} data-toggle="tab" href="#kt_apps_contacts_view_tab_2" onClick={() => this.setState({ activeTab: 'sent' })} style={{ paddingTop: '1.8rem', paddingBottom: '0.8rem' }}>
                                                <span className="nav-text">Sent</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {
                                this.state.activeTab === 'received' ?
                                    <div className="card-body">
                                        {
                                            this.state.listLoading ? '' :
                                                this.state.receivedList.length <= 0 ? <div className="text-center text-muted font-size-h2 font-weight-bold p-5 col-md-12">No New Invitations</div> :
                                                    this.state.receivedList.map((conect, index) => {

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 mr-5">
                                                                        <div className="symbol symbol-85 symbol-circle">
                                                                            {
                                                                                conect.userId.profileImage ?
                                                                                    <img alt="Pic" src={Constants.ProfileImageUrl + conect.userId.profileImage} />
                                                                                    :
                                                                                    <img alt="Pic" src="assets/media/users/default.jpg" />
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 mt-2">
                                                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                            <div className="mr-3">
                                                                                <div className="d-flex align-items-center mr-3">
                                                                                    <span className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">{conect.userId.firstName} {conect.userId.lastName}</span>
                                                                                </div>
                                                                                <div className="d-flex flex-wrap my-2">
                                                                                    <div className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                                    <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                                                    <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                                                </g>
                                                                                            </svg>
                                                                                        </span>{conect.userId.email}
                                                                                    </div>
                                                                                    <div className="text-muted font-weight-bold">
                                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                                    <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                                                </g>
                                                                                            </svg>
                                                                                        </span>{conect.userId.city} {conect.userId.state ? ', ' + conect.userId.state : ''} - {conect.userId.country}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-center flex-wrap justify-content-between">
                                                                                    <div className="flex-grow-1 font-weight-bold text-dark-50 mr-5">
                                                                                        {
                                                                                            conect.userId.occupationType === 'employee' ?
                                                                                                <span>{conect.userId.company ? conect.userId.company.designation + ' at ' + conect.userId.company.companyName : ''}</span>
                                                                                                :
                                                                                                conect.userId.occupationType === 'student' ? <span>{conect.userId.occupationType + ' at ' + conect.userId.collegeName}</span> :
                                                                                                    conect.conectUserId.occupationType === 'business' ? <span>{conect.conectUserId.occupationType}</span> : ''

                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="mb-0">
                                                                                {
                                                                                    this.state.loading ?
                                                                                        <button className="btn btn-light-danger mr-2 font-weight-bolder" disabled>
                                                                                            Reject
                                                                                        </button>
                                                                                        :
                                                                                        <button className="btn btn-light-danger mr-2 font-weight-bolder" onClick={() => { this.onChangeConnectionStatus(conect._id, 'Rejected') }}>
                                                                                            Reject
                                                                                        </button>
                                                                                }

                                                                                {
                                                                                    this.state.loading ?
                                                                                        <button className="btn btn-light-success font-weight-bolder" disabled>
                                                                                            Accept
                                                                                        </button>
                                                                                        :
                                                                                        <button className="btn btn-light-success font-weight-bolder" onClick={() => { this.onChangeConnectionStatus(conect._id, 'Accepted') }}>
                                                                                            Accept
                                                                                        </button>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (this.state.connectionList.length - 1 !== index) &&
                                                                    <div className="separator separator-solid mt-5 mb-5"></div>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                        }
                                    </div>
                                    :
                                    // Sent List
                                    <div className="card-body">
                                        {
                                            this.state.listLoading ? '' :
                                                this.state.sentList.length <= 0 ? <div className="text-center text-muted font-size-h2 font-weight-bold p-5 col-md-12">No Request</div> :
                                                    this.state.sentList.map((conect, index) => {
                                                        console.log(conect)
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 mr-5">
                                                                        <div className="symbol symbol-85 symbol-circle">
                                                                            {
                                                                                conect.conectUserId.profileImage ?
                                                                                    <img alt="Pic" src={Constants.ProfileImageUrl + conect.conectUserId.profileImage} />
                                                                                    :
                                                                                    <img alt="Pic" src="assets/media/users/default.jpg" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 mt-2">
                                                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                                            <div className="mr-3">
                                                                                <div className="d-flex align-items-center mr-3">
                                                                                    <span className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">{conect.conectUserId.firstName} {conect.conectUserId.lastName}</span>
                                                                                </div>
                                                                                <div className="d-flex flex-wrap my-2">
                                                                                    <div className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                                    <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                                                    <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                                                </g>
                                                                                            </svg>
                                                                                        </span>{conect.conectUserId.email}
                                                                                    </div>
                                                                                    <div className="text-muted font-weight-bold">
                                                                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                                    <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                                                </g>
                                                                                            </svg>
                                                                                        </span>{conect.conectUserId.city} {conect.conectUserId.state ? ', ' + conect.conectUserId.state : ''} - {conect.conectUserId.country}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-center flex-wrap justify-content-between">
                                                                                    <div className="flex-grow-1 font-weight-bold text-dark-50 mr-5">
                                                                                        {
                                                                                            conect.conectUserId.occupationType === 'employee' ?
                                                                                                <span>{conect.conectUserId.company ? conect.conectUserId.company.designation + ' at ' + conect.conectUserId.company.companyName : ''}</span>
                                                                                                :
                                                                                                conect.conectUserId.occupationType === 'student' ? <span>{conect.conectUserId.occupationType + ' at ' + conect.conectUserId.collegeName}</span> :
                                                                                                    conect.conectUserId.occupationType === 'business' ? <span>{conect.conectUserId.occupationType}</span> :
                                                                                                        conect.conectUserId.occupationType === 'other' ? <span>{conect.conectUserId.otherType}</span> : ''

                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="mb-0">
                                                                                {
                                                                                    this.state.loading ?
                                                                                        <button className="btn btn-light-danger mr-2 font-weight-bolder" disabled>
                                                                                            Remove
                                                                                        </button>
                                                                                        :
                                                                                        <button className="btn btn-light-danger mr-2 font-weight-bolder" onClick={() => this.onConfirmAlert(conect._id)}>
                                                                                            Remove
                                                                                        </button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (this.state.connectionList.length - 1 !== index) &&
                                                                    <div className="separator separator-solid mt-5 mb-5"></div>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                        }

                                    </div>

                            }

                        </div>
                    </div>
                </div>
                {
                    this.state.alert &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        title="Are you sure?"
                        onConfirm={() => this.onRemoveConnection()}
                        onCancel={() => this.setState({ alert: false })}
                        focusCancelBtn
                    >
                        You want to remove request
                    </SweetAlert>
                }
                {
                    this.state.success &&
                    <SweetAlert success title='' onConfirm={() => this.hideSuccessAlert()} onCancel={() => this.setState({ success: false })}>
                        {this.state.successMsg}
                    </SweetAlert>
                }
            </>
        );
    }
}

export default InvitationManager;