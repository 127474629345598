import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';
import Store from './store';
import { saveAs } from 'file-saver';
import FileViewer from 'react-file-viewer';
import PostCard from '../Components/PostCard';
class CompanyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            businessDetail: '',
            id: this.props.match.params.id ? this.props.match.params.id : '',
            dataLoading: false,
            businessUser: '',
            businessPost: [],
            products: [],
            chatList: [],
            services: [],
            activeTab: 'Summary',
            preview: false,
            fileUrl: '',
            fileType: '',
            selectedId: '',
            isImageLoading: true,
            userId: null,
            newchatListId: ''
        }
        this.store = Store;
    }
    componentDidMount = async () => {
        await this.store.setSubHeader("Business", ["Business", "Business Detail"])
        await this.getUserCredintials();
        const params = new URLSearchParams(this.props.location.search);
        const tab = params.get('tab');
        if (tab && tab !== '') {
            await this.setState({ activeTab: tab })
        }
    }

    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            const userId = user._id;
            await this.setState({ userId })
        }
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            if (this.state.id && this.state.id !== '') {
                await this.getBusiness();
            }
            await this.getChatList();
        }
    }

    getChatList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'chat/chatList',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        chatList: responseData.ChatList
                    });
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    getBusiness = () => {
        this.setState({ dataLoading: true })
        let errors = {};
        fetch(Constants.url + 'business/' + this.state.id,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        businessDetail: responseData.Business,
                        businessUser: responseData.Business.userId,
                        businessPost: responseData.posts,
                        products: responseData.products,
                        services: responseData.Business.services,
                        dataLoading: false,
                    });
                    let newchatListId = AllFunction.getChatListId(this.state.chatList, responseData.Business.userId._id);
                    this.setState({ newchatListId })
                    setTimeout(() => {
                        window.loadPopover()
                    }, 300);
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    downloadFile(url, name) {
        saveAs(url, name);
    }

    previewFile = async (fileUrl, fileType, index, fileName) => {
        setTimeout(() => {
            // document.getElementById('pg-photo-container').style.cssText = `height: "100%";width: "100%"; `;
            this.setState({ isImageLoading: false });
        }, 2000);
        await this.setState({ fileUrl, fileType, preview: true, selectedId: index, isImageLoading: true, fileName: fileName })
    }

    async hideModal() {
        window.$("#exampleModal").modal("hide");
        this.setState({ preview: false, fileUrl: '', fileType: '', isImageLoading: true })
    }

    render() {
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    {
                        this.state.dataLoading ? '' :
                            <div className="container">
                                <div className="card card-custom gutter-b">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 mr-7">
                                                <div className="symbol symbol-50 symbol-lg-100">
                                                    {
                                                        this.state.businessDetail.companyLogo ?
                                                            <img alt="Pic" src={Constants.CompanyLogoUrl + this.state.businessDetail.companyLogo} />
                                                            :
                                                            <img alt="Pic" src="/assets/noImage.png" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                    <div className="mr-3">
                                                        <div className="d-flex align-items-center mr-3">
                                                            <span className="d-flex align-items-center text-dark font-size-h5 font-weight-bold mr-3">{this.state.businessDetail.businessName}</span>
                                                        </div>
                                                        <div className="d-flex flex-wrap my-2">
                                                            <div className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                            <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
                                                                            <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
                                                                        </g>
                                                                    </svg>
                                                                </span>{this.state.businessDetail.email}
                                                            </div>
                                                            <div className="text-muted font-weight-bold">
                                                                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                            <rect x="0" y="0" width="24" height="24" />
                                                                            <path d="M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z" fill="#000000" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                {this.state.businessDetail.city} {this.state.businessDetail.state ? ", " + this.state.businessDetail.state : ''} {this.state.businessDetail.country ? "- " + this.state.businessDetail.country : ''}
                                                            </div>
                                                        </div>

                                                        <div class="d-flex flex-wrap mb-2 mt-0">
                                                            {
                                                                this.state.businessDetail.hasOwnProperty("facebookLink") && this.state.businessDetail.facebookLink !== '' ?
                                                                    <a href={this.state.businessDetail.facebookLink} class="btn btn-icon btn-xs btn-facebook mr-2">
                                                                        <i class="socicon-facebook"></i>
                                                                    </a> : ''
                                                            }
                                                            {
                                                                this.state.businessDetail.hasOwnProperty("linkedinLink") && this.state.businessDetail.linkedinLink !== '' ?
                                                                    <a href={this.state.businessDetail.linkedinLink} class="btn btn-icon btn-xs btn-linkedin mr-2">
                                                                        <i class="socicon-linkedin"></i>
                                                                    </a>
                                                                    : ''
                                                            }
                                                            {
                                                                this.state.businessDetail.hasOwnProperty("twitterLink") && this.state.businessDetail.twitterLink !== '' ?
                                                                    <a href={this.state.businessDetail.twitterLink} class="btn btn-icon btn-xs btn-twitter mr-2">
                                                                        <i class="socicon-twitter"></i>
                                                                    </a>
                                                                    : ''
                                                            }
                                                            {
                                                                this.state.businessDetail.hasOwnProperty("whatsAppNo") && this.state.businessDetail.whatsAppNo !== '' ?
                                                                    <a href="#" class="btn btn-icon btn-xs btn-whatsapp" style={{ color: '#fff', backgroundColor: '#009c8c', borderColor: '#009c8c' }}>
                                                                        <i class="socicon-whatsapp" style={{ color: '#fff' }}></i>
                                                                    </a>
                                                                    : ''
                                                            }

                                                        </div>

                                                    </div>
                                                    <div className="mb-10">
                                                        {

                                                            this.state.businessUser._id === this.state.userId ?
                                                                <>
                                                                    <Link to={'/EditCompany/' + this.state.businessDetail._id} className="btn btn-light-success mr-2 font-weight-bolder">
                                                                        <i className="flaticon-edit-1"></i> Edit
                                                                    </Link>
                                                                    <Link to={'/Createproduct/' + this.state.businessDetail._id} className="btn btn-light-success mr-2 font-weight-bolder">
                                                                        <i className="la la-plus-circle"></i> Add Product/Service
                                                                    </Link>
                                                                </>
                                                                :
                                                                <Link to={'/Chat?receiverId=' + this.state.businessUser._id + '&Name=' + this.state.businessUser.firstName + '&chatListId=' + this.state.newchatListId} className="btn btn-light-success font-weight-bolder">
                                                                    <i className="flaticon2-chat-1"></i> Connect
                                                                </Link>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center flex-wrap justify-content-between">
                                                    <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">
                                                        {this.state.services.join(",")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card card-custom gutter-b">
                                            <div className="card-header card-header-tabs-line" style={{ minHeight: 20 }}>
                                                <div className="card-toolbar">
                                                    <ul className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x" role="tablist">
                                                        <li className="nav-item">
                                                            <a className={`nav-link ${this.state.activeTab === 'Summary' ? 'active' : ''}`} data-toggle="tab" href="#kt_apps_contacts_view_tab_1" style={{ paddingTop: '2rem', paddingBottom: '1rem' }} onClick={() => { this.setState({ activeTab: 'Summary' }) }}>
                                                                <span className="nav-icon mr-2">
                                                                    <span className="svg-icon mr-3">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24" />
                                                                                <path d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z" fill="#000000" />
                                                                                <circle fill="#000000" opacity="0.3" cx="18.5" cy="5.5" r="2.5" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                                <span className="nav-text">Summary</span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={`nav-link ${this.state.activeTab === 'Post' ? 'active' : ''}`} data-toggle="tab" href="#kt_apps_contacts_view_tab_1" style={{ paddingTop: '2rem', paddingBottom: '1rem' }} onClick={() => { this.setState({ activeTab: 'Post' }) }}>
                                                                <span className="nav-icon mr-2">
                                                                    <span className="svg-icon mr-3">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24" />
                                                                                <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) " />
                                                                                <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                                <span className="nav-text">Post</span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={`nav-link ${this.state.activeTab === 'CompanyDetail' ? 'active' : ''}`} data-toggle="tab" href="#kt_apps_contacts_view_tab_1" style={{ paddingTop: '2rem', paddingBottom: '1rem' }} onClick={() => { this.setState({ activeTab: 'CompanyDetail' }) }}>
                                                                <span className="nav-icon mr-2">
                                                                    <span className="svg-icon mr-3">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24" />
                                                                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
                                                                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
                                                                                <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
                                                                                <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                                <span className="nav-text">Company Detail</span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className={`nav-link ${this.state.activeTab === 'Product' ? 'active' : ''}`} data-toggle="tab" href="#kt_apps_contacts_view_tab_1" style={{ paddingTop: '2rem', paddingBottom: '1rem' }} onClick={() => { this.setState({ activeTab: 'Product' }) }}>
                                                                <span className="nav-icon mr-2">
                                                                    <span className="svg-icon mr-3">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24" />
                                                                                <path d="M5.5,4 L9.5,4 C10.3284271,4 11,4.67157288 11,5.5 L11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L5.5,8 C4.67157288,8 4,7.32842712 4,6.5 L4,5.5 C4,4.67157288 4.67157288,4 5.5,4 Z M14.5,16 L18.5,16 C19.3284271,16 20,16.6715729 20,17.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,17.5 C13,16.6715729 13.6715729,16 14.5,16 Z" fill="#000000" />
                                                                                <path d="M5.5,10 L9.5,10 C10.3284271,10 11,10.6715729 11,11.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,12.5 C20,13.3284271 19.3284271,14 18.5,14 L14.5,14 C13.6715729,14 13,13.3284271 13,12.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z" fill="#000000" opacity="0.3" />
                                                                            </g>
                                                                        </svg></span>
                                                                </span>
                                                                <span className="nav-text">Products</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                {
                                                    this.state.activeTab === 'Summary' &&
                                                    <>
                                                        <h3 className="card-label text-dark-50 mb-5">Overview</h3>
                                                        <p className="text-dark-50">
                                                            {this.state.businessDetail.description}
                                                        </p>

                                                        {this.state.businessDetail.companyDocs && this.state.businessDetail.companyDocs.length > 0 ?
                                                            <>
                                                                <h3 className="card-label text-dark-50 mb-5 pt-2">Documents</h3>
                                                                <div className="row">
                                                                    {
                                                                        this.state.businessDetail.companyDocs.map((doc, index) => {
                                                                            console.log(doc)
                                                                            var afterDot = doc.fileName.substr(doc.fileName.indexOf('.'));

                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4">
                                                                                        <div className="card card-custom gutter-b card-stretch shadow-sm">
                                                                                            <div className="card-body">
                                                                                                <div className="d-flex flex-column align-items-center cursor-pointer" data-toggle="modal" data-target="#exampleModal" onClick={() => this.previewFile(Constants.CompanyDocsUrl + doc.url, afterDot.replace(".", ""), index, doc.fileName)}>
                                                                                                    <img alt="" className="max-h-65px" src="assets/file1.png" />
                                                                                                    <span className="text-dark-75 font-weight-bold mt-15 font-size-lg">{doc.fileName}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </>
                                                            : ''}
                                                    </>
                                                }
                                                {
                                                    this.state.activeTab === 'Post' &&
                                                    <div className="row">
                                                        {
                                                            this.state.dataLoading ? '' : this.state.businessPost.length <= 0 ? <div className="text-center text-muted font-size-h2 font-weight-bold p-5 col-md-12">No Post</div> :
                                                                <PostCard postList={this.state.businessPost} userId={this.state.userId} chatList={this.state.chatList} />
                                                        }
                                                    </div>
                                                }
                                                {
                                                    this.state.activeTab === 'CompanyDetail' &&
                                                    <>
                                                        <h3 className="card-label">{this.state.businessDetail.businessName}
                                                            <span className="d-block text-muted pt-2 font-size-sm">company profile preview</span>
                                                        </h3>
                                                        <div className="form-group row my-2">
                                                            <label className="col-md-2 col-form-label col-sm-12">Name:</label>
                                                            <div className="col-6">
                                                                <span className="form-control-plaintext font-weight-bolder">{this.state.businessDetail.businessName}</span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-2">
                                                            <label className="col-md-2 col-form-label col-sm-12">Location:</label>
                                                            <div className="col-6">
                                                                <span className="form-control-plaintext font-weight-bolder">
                                                                    {this.state.businessDetail.city} {this.state.businessDetail.state ? ", " + this.state.businessDetail.state : ''} {this.state.businessDetail.country ? "- " + this.state.businessDetail.country : ''}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-2">
                                                            <label className="col-md-2 col-form-label col-sm-12">Phone:</label>
                                                            <div className="col-6">
                                                                <span className="form-control-plaintext font-weight-bolder">{this.state.businessDetail.mobileNo}</span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-2">
                                                            <label className="col-md-2 col-form-label col-sm-12">Email:</label>
                                                            <div className="col-6">
                                                                <span className="form-control-plaintext font-weight-bolder">
                                                                    <a href={"mailto:" + this.state.businessDetail.email}>{this.state.businessDetail.email}</a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-2">
                                                            <label className="col-md-2 col-form-label col-sm-12">Website:</label>
                                                            <div className="col-6">
                                                                <span className="form-control-plaintext font-weight-bolder">
                                                                    {this.state.businessDetail.website}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row my-2">
                                                            <label className="col-md-2 col-form-label col-sm-12">Contact Person:</label>
                                                            <div className="col-6">
                                                                <span className="form-control-plaintext font-weight-bolder">
                                                                    {this.state.businessUser.firstName} {this.state.businessUser.lastName}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    this.state.activeTab === 'Product' &&
                                                    <>
                                                        {/* <h3 className="card-label text-dark-50 mb-5">Overview</h3> */}
                                                        <div className="row">
                                                            {
                                                                this.state.dataLoading ? '' :
                                                                    this.state.products.length <= 0 ? <div className="text-center text-muted font-size-h2 font-weight-bold p-5 col-md-12">No Product Found</div> :
                                                                        this.state.products.map((product, index) => {
                                                                            return (
                                                                                <div className="col-md-6 col-lg-4 col-xxl-4" key={index}>
                                                                                    <div className="card card-custom gutter-b card-stretch">
                                                                                        <div className="border-0 pt-5 pb-0" style={{ padding: '2rem 2.25rem' }}>
                                                                                            <h3 className="card-title align-items-start mb-2 d-flex justify-content-between">
                                                                                                <span className="card-label font-weight-bolder text-dark-75 mb-1" data-toggle="tooltip" title={product.title} style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis' }}>{product.title}</span>
                                                                                                {
                                                                                                    this.state.businessUser._id === this.state.userId &&
                                                                                                    <Link to={'/EditProduct/' + product._id} className="mx-3">
                                                                                                        <span className="svg-icon svg-icon-md svg-icon-primary">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                                                                    <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"></path>
                                                                                                                    <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                        </span>
                                                                                                    </Link>}
                                                                                            </h3>
                                                                                        </div>
                                                                                        <div className="card-body pt-2 d-flex flex-column rounded justify-content-between">
                                                                                            <div className="text-center rounded mb-7 symbol symbol-lg-230 d-flex justify-content-center" >
                                                                                                {
                                                                                                    product.image ?
                                                                                                        <img src={Constants.ProductImageUrl + product.image} />
                                                                                                        :
                                                                                                        <img src="assets/noImage.png" />
                                                                                                }
                                                                                            </div>
                                                                                            <div data-toggle="tooltip" title={product.description} className="font-size-h6 text-muted font-weight-bolder" style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', textOverflow: 'ellipsis' }}>
                                                                                                {product.description}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    }
                </div>
                {
                    // this.state.preview &&
                    <div className="modal fade" id="exampleModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="exampleModal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">{this.state.fileName}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <i aria-hidden="true" className="ki ki-close"></i>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-0">
                                        <div className="col-md-12" >
                                            <div style={{ height: 400, width: 400 }}>

                                                {
                                                    this.state.isImageLoading ? '....Loading' :
                                                        <FileViewer
                                                            fileType={this.state.fileType}
                                                            filePath={this.state.fileUrl}
                                                            onError={(e) => {
                                                                console.log("Error ", e)
                                                            }}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-light-primary font-weight-bold" onClick={() => this.hideModal()}>Close</button>
                                    {
                                        this.state.selectedType &&
                                        <button type="button" className="btn btn-primary font-weight-bold" onClick={() => this.ConfirmGroupUpdate()}>Update</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}
export default CompanyDetail;