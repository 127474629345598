import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AllFunction from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import Axios from 'axios';
import Auth from '../Utilities/Auth';
import InputTag from '../Components/InputTag';
import Store from './store';
import MessageAlert from '../Components/MessageAlert';
import Autocomplete from "react-google-autocomplete";

class CreateCompany extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loading: false,
            businessName: '',
            businessType: 'Service',
            domain: '',
            mobileNo: '',
            email: '',
            services: [],
            achivements: '',
            website: '',
            companyLogo: '',
            image_url: '',
            uploadedImage: '',
            errors: {},
            description: '',
            token: '',
            city: '',
            state: '',
            country: '',
            street_address: '',
            id: this.props.match.params.id ? this.props.match.params.id : '',
            dataLoading: false,
            documents: [],
            uploaded_docs: [],
            whatsAppNo: '',
            facebookLink: '',
            twitterLink: '',
            linkedinLink: '',
            whatsAppLink: '',
            youtubeLink: '',
            success: false,
            successMsg: '',
            domainList: [],
            countryList: [],
            stateList: [],
            cityList: [],
            businessTypeList: [],
            designation: ''
        }
        this.store = Store;
    }

    onDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags })
    }

    onAddition(tag) {
        const tags = [].concat(this.state.tags, tag)
        this.setState({ tags })
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
        if (this.state.id && this.state.id !== '') {
            await this.getBusiness();
        }
        await this.store.setSubHeader("Business", ["Business", "Create Business"])
    }

    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            await this.getDomainList();
            await this.getBusinessTypeList();
        }
    }

    getDomainList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'dropdown/Domain',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({ dataLoading: false, domainList: responseData.dropdown.options });

                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onGetTags = async (services) => {
        await this.setState({ services })
    }

    /**
     * onCompanyLogoChange()
     * @param {*} e 
     * @description This function set image url in state and file object in profileImage 
     */
    onCompanyLogoChange = async (e) => {
        let errors = {};

        if (e.target.files[0]['size'] > 2097152) {
            errors.companyLogo = "File size exceeded 2 MB";
            this.setState({ errors: errors });
        }
        else {
            const file = this.myRef.current.files[0];
            const reader = new FileReader();

            reader.onloadend = async () => {
                await this.setState({
                    image_url: reader.result
                })
            }
            await this.setState({
                companyLogo: e.target.files[0]
            })
            e.target.value = '';
            if (file) {
                reader.readAsDataURL(file);
            }
            else {
                this.setState({
                    image_url: ""
                })
            }
        }
    }

    _onFileUploadChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let oldFiles = this.state.documents;
            let new_files = event.target.files;
            let newfiles = Array.from(event.target.files);
            newfiles.forEach((e) => {

                oldFiles.push(e);
            });
            await this.setState({ documents: oldFiles });
        }
    }

    documents(files) {
        let content = null;
        if (files.length > 0) {
            content = files.map((image, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className="dropzone-item dz-processing dz-error dz-complete">
                            <div className="dropzone-file">
                                <div className="dropzone-filename" title="some_image_file_name.jpg">
                                    <span data-dz-name="">{image.name}</span>
                                    <strong>(
                                        <span data-dz-size=""><strong>87</strong> b</span>)</strong>
                                </div>
                            </div>

                            <div className="dropzone-toolbar">
                                <span className="dropzone-delete" onClick={() => { this.removeDocument(image.name); }}>
                                    <i className="flaticon2-cross"></i>
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })
            return content
        } else {
            return (
                <span>Upload project specific files</span>
            )
        }
    }

    removeDocument = async (name) => {
        //remove from showImages and files
        let files = this.state.documents.filter((e) => { return e.name !== name });
        this.setState({ documents: files })
    }

    _removeDocument = (name) => {
        let files = this.state.uploaded_docs.filter((e) => { return e.name !== name });

        this.setState({ uploaded_docs: files })
    }

    _onRemoveImage = async (e) => {
        await this.setState({ image_url: '', companyLogo: '' })
    }

    businessValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.businessName) {
            formIsValid = false;
            errors.businessName = "Please enter Business Name.";
        }

        if (!this.state.domain) {
            formIsValid = false;
            errors.domain = "Please enter domain.";
        }

        if (!this.state.mobileNo) {
            formIsValid = false;
            errors.mobileNo = "Please enter mobile No.";
        }

        if (!this.state.city) {
            formIsValid = false;
            errors.city = "Please enter city.";
        }

        if (!this.state.state) {
            formIsValid = false;
            errors.state = "Please enter state.";
        }

        if (!this.state.country) {
            formIsValid = false;
            errors.country = "Please enter country.";
        }

        if (!this.state.email) {
            formIsValid = false;
            errors.email = "Please enter email.";
        }

        if (!this.state.street_address) {
            formIsValid = false;
            errors.street_address = "Please enter your location.";
        }

        if (this.state.email.length != 0) {
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
                errors.email = 'Please enter valid email.';
                formIsValid = false;
            }
        } else if (!this.state.email || this.state.email.trim().length == 0) {
            errors.email = 'Please enter valid email.';
            formIsValid = false;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    getAddressComponentData = (place) => {
        let address_components = place.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name })
            }
        })
        this.setState({ street_address: place.formatted_address, location: place.formatted_address });

    }

    onAddBusiness = () => {
        if (this.businessValidation()) {
            this.setState({ loading: true })
            setTimeout(() => {
                window.blockPage()
            }, 300);

            let formData = new FormData();
            formData.append('businessName', this.state.businessName);
            formData.append('designation', this.state.designation);
            formData.append('businessType', this.state.businessType);
            formData.append('domain', this.state.domain);
            formData.append('mobileNo', this.state.mobileNo);
            formData.append('city', this.state.city);
            formData.append('state', this.state.state);
            formData.append('country', this.state.country);
            formData.append('email', this.state.email);
            formData.append('services', JSON.stringify(this.state.services));
            formData.append('achivements', this.state.achivements);
            formData.append('website', this.state.website);
            formData.append('description', this.state.description);
            formData.append('whatsAppLink', this.state.whatsAppLink);
            formData.append('facebookLink', this.state.facebookLink);
            formData.append('twitterLink', this.state.twitterLink);
            formData.append('linkedinLink', this.state.linkedinLink);
            formData.append('youtubeLink', this.state.youtubeLink);
            formData.append('street_address', this.state.street_address);
            if (this.state.companyLogo !== '') {
                formData.append('companyLogo', this.state.companyLogo);
            }

            this.state.documents.forEach((file, i) => {
                // console.log(file)
                formData.append("companyDocs", file)
            });
            // for (let [key, value] of formData.entries()) {
            //     console.log(key, value);
            // }
            // return
            let errors = {};
            let method = 'POST';
            let successMsg = 'Business Added Successfully.';
            if (this.state.id && this.state.id !== '') {
                method = 'PUT';
                formData.append('_id', this.state.id);
                formData.append('uploadedDocs', JSON.stringify(this.state.uploaded_docs));
                successMsg = 'Business Updated Successfully.';
            }

            Axios({
                method: method,
                url: Constants.url + 'business',
                data: formData,
                headers: {
                    'Accept': 'application/json,multipart/form-data',
                    'Content-Type': 'application/json,multipart/form-data',
                    'Authorization': this.state.token
                }
            }).then(response => {
                let responseData = response.data;
                // console.log("Add Business", responseData)
                if (responseData.status) {
                    this.setState({ loading: false, success: true, successMsg: successMsg });
                    this.getBusiness();
                    setTimeout(() => {
                        window.unblockPage()
                        if (this.state.id && this.state.id !== '') {
                            alert('Business Updated Successfully');
                        }
                        else {
                            alert('Business Created Successfully');
                        }
                        window.location = '/';
                    }, 300);

                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.log(error);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
        }
    }

    getBusiness = () => {
        this.setState({ dataLoading: true })
        let errors = {};
        setTimeout(() => {
            window.blockPage()
        }, 300);
        fetch(Constants.url + 'business/' + this.state.id,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        businessName: responseData.Business.businessName,
                        designation: responseData.Business.designation,
                        domain: responseData.Business.domain,
                        mobileNo: responseData.Business.mobileNo,
                        email: responseData.Business.email,
                        city: responseData.Business.city,
                        state: responseData.Business.state,
                        country: responseData.Business.country,
                        services: responseData.Business.services,
                        achivements: responseData.Business.achivements || '',
                        website: responseData.Business.website,
                        description: responseData.Business.description,
                        uploadedImage: responseData.Business.companyLogo,
                        dataLoading: false,
                        uploaded_docs: responseData.Business.companyDocs,
                        businessType: responseData.Business.businessType,
                        whatsAppLink: responseData.Business.whatsAppLink || '',
                        facebookLink: responseData.Business.facebookLink || '',
                        twitterLink: responseData.Business.twitterLink || '',
                        linkedinLink: responseData.Business.linkedinLink || '',
                        youtubeLink: responseData.Business.youtubeLink || '',
                        street_address: responseData.Business.street_address || ''
                    });
                    this.onGetTags(responseData.Business.services);
                    // if (this.state.country != '') {
                    //     this.getStateList();
                    // }
                    // window.location = '/';
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                } else if (!responseData.status && 'auth' in responseData) {
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    getBusinessTypeList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + 'dropdown/BusinessType',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({ dataLoading: false, businessTypeList: responseData.dropdown.options });

                }
                else {
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    businessTypeChange = async (event) => {
        const target = event.target;
        var value = target.type === "radio" ? target.checked : target.value;
        const name = target.name;

        await this.setState({
            [name]: value,
            businessType: name,
        });
    }

    render() {

        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom gutter-b example example-compact">
                            <div className="card-header">
                                <h3 className="card-title">Business Details</h3>
                            </div>
                            <div className="card-body">
                                <div className="col-md-12">
                                    {
                                        this.state.success &&
                                        <MessageAlert children={this.state.successMsg} variant="success" />
                                    }
                                    {this.state.errors.error &&
                                        <MessageAlert children={this.state.errors.error} variant="danger" />
                                    }
                                </div>
                                <div className="form-group mb-3">
                                    <label>Business Type</label>
                                    <div className="radio-inline">
                                        {
                                            this.state.businessTypeList.map((list, index) => {
                                                return (
                                                    <label className="radio radio-rounded" key={index}>
                                                        <input type="radio" checked={this.state.businessType === list.value ? "checked" : ''} name={list.value} onChange={(event) => this.businessTypeChange(event)} value={this.state.company} />
                                                        <span></span>
                                                        {list.name}
                                                    </label>
                                                )
                                            })
                                        }
                                        {/* <label className="radio radio-rounded">
                                            <input type="radio" name="indivisual_service" checked={this.state.businessType === "indivisual_service" ? "checked" : ''} onChange={(event) => this.businessTypeChange(event)} value={this.state.indivisual_service} />
                                            <span></span>
                                            Indivisual Service
                                        </label> */}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label>Business Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-lg" name="businessName" placeholder="Enter Business Name" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.businessName} />
                                        {this.state.errors.businessName && <span className="form-text text-danger pl-2">{this.state.errors.businessName}</span>}
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Select Domain <span className="text-danger">*</span></label>
                                        {/* <input type="text" className="form-control form-control-lg" name="domain" placeholder="Work Domain" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.domain} />
                                        {this.state.errors.domain && <span className="form-text text-danger pl-2">{this.state.errors.domain}</span>} */}
                                        <select className="form-control form-control-lg" name="domain" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.domain}>
                                            <option value="">Select Domain</option>
                                            {
                                                this.state.domainList.map((list, index) => {
                                                    return (
                                                        <option value={list.value} key={index}>{list.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {this.state.errors.domain && <span className="text-danger pl-2">{this.state.errors.domain}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label>Contact Phone <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-lg" name="mobileNo" placeholder="Phone" onChange={(event) => AllFunction.onChange(event, this, "Number")} value={this.state.mobileNo} />
                                        {this.state.errors.mobileNo && <span className="form-text text-danger pl-2">{this.state.errors.mobileNo}</span>}
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Email Address <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-lg" name="email" placeholder="Email" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.email} />
                                        {this.state.errors.email && <span className="form-text text-danger pl-2">{this.state.errors.email}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label>Company Site</label>
                                        <input type="text" className="form-control form-control-lg" name="website" placeholder="Company Website" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.website} />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Designation</label>
                                        <input type="text" className="form-control form-control-lg" name="designation" placeholder="Designation" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.designation} />
                                    </div>
                                </div>
                                <hr />
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Location <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <Autocomplete
                                            apiKey={Constants.GOOGLE_MAPS_API_KEY}
                                            style={{ width: "100%" }}
                                            onPlaceSelected={(place) => {
                                                this.getAddressComponentData(place)
                                            }}
                                            className="form-control form-control-lg"
                                            name="street_address"
                                            defaultValue={this.state.street_address}
                                        />
                                        {this.state.errors.street_address && <span className="text-danger pl-2">{this.state.errors.street_address}</span>}
                                    </div>
                                </div>


                                <div className="form-group row">
                                    <div className="col-lg-4">
                                        <label>Country <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control form-control-lg" name="country" placeholder="Country" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.country} readOnly />

                                        {/* <select className="form-control form-control-lg" name="country" autoComplete="off" onChange={(event) => this.onChangeCountry(event)} value={this.state.country}>
                                            <option value="">Select Country</option>
                                            {
                                                this.state.countryList.map((list, index) => {
                                                    return (
                                                        <option value={list.name} key={index}>{list.name}</option>
                                                    )
                                                })
                                            }
                                        </select> */}
                                        {this.state.errors.country && <span className="form-text text-danger pl-2">{this.state.errors.country}</span>}
                                    </div>
                                    <div className="col-lg-4">
                                        <label>State <span className="text-danger">*</span></label>
                                        <div className="input-group input-group-lg">
                                            <input type="text" className="form-control form-control-lg" name="state" placeholder="State" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.state} readOnly />
                                        </div>
                                        {/* <select className="form-control form-control-lg" name="state" autoComplete="off" onChange={(event) => this.onChangeState(event)} value={this.state.state}>
                                            <option value="">Select State</option>
                                            {
                                                this.state.stateList.map((list, index) => {
                                                    return (
                                                        <option value={list.name} key={index}>{list.name}</option>
                                                    )
                                                })
                                            }
                                        </select> */}
                                        {this.state.errors.state && <span className="form-text text-danger pl-2">{this.state.errors.state}</span>}
                                    </div>
                                    <div className="col-lg-4">
                                        <label>City <span className="text-danger">*</span></label>
                                        <div className="input-group input-group-lg">
                                            <input type="text" className="form-control form-control-lg" name="city" placeholder="City" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.city} readOnly />
                                        </div>
                                        {/* <select className="form-control form-control-lg" name="city" autoComplete="off" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.city}>
                                            <option value="">Select City</option>
                                            {
                                                this.state.cityList.map((list, index) => {
                                                    return (
                                                        <option value={list.name} key={index}>{list.name}</option>
                                                    )
                                                })
                                            }
                                        </select> */}
                                        {this.state.errors.city && <span className="form-text text-danger pl-2">{this.state.errors.city}</span>}
                                    </div>
                                </div>
                                <hr />
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label>WhatsApp Link</label>
                                        <input type="text" className="form-control form-control-lg" name="whatsAppLink" placeholder="WhatsApp Link" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.whatsAppLink} />
                                        {this.state.errors.whatsAppLink && <span className="form-text text-danger pl-2">{this.state.errors.whatsAppLink}</span>}
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Facebook Link</label>
                                        <input type="text" className="form-control form-control-lg" name="facebookLink" placeholder="Facebook Link" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.facebookLink} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-6">
                                        <label>Twitter Link</label>
                                        <input type="text" className="form-control form-control-lg" name="twitterLink" placeholder="Twitter Link" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.twitterLink} />
                                        {this.state.errors.twitterLink && <span className="form-text text-danger pl-2">{this.state.errors.twitterLink}</span>}
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Linkedin Link</label>
                                        <input type="text" className="form-control form-control-lg" name="linkedinLink" placeholder="Linkedin Link" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.linkedinLink} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Youtube Link</label>
                                        <input type="text" className="form-control form-control-lg" name="youtubeLink" placeholder="Youtube Link" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.youtubeLink} />
                                        {this.state.errors.youtubeLink && <span className="form-text text-danger pl-2">{this.state.errors.youtubeLink}</span>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Services</label>
                                        <InputTag onGetTags={this.onGetTags} tags={this.state.services} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Achivements</label>
                                        <textarea rows={2} className="form-control form-control-lg" name="achivements" placeholder="Company achivements" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.achivements} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Description</label>
                                        <textarea rows={4} className="form-control form-control-lg" name="description" placeholder="Company Description" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.description} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        <label>Company Logo</label><br />
                                        <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: 'url(assets/media/users/blank.png)' }}>
                                            {this.state.image_url ?
                                                <div className="image-input-wrapper" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.state.image_url})`, backgroundPosition: 'center' }}></div>
                                                :
                                                this.state.dataLoading ? '....' : this.state.uploadedImage ?
                                                    <div className="image-input-wrapper" style={{ backgroundImage: `url(${Constants.CompanyLogoUrl}${this.state.uploadedImage})`, backgroundPosition: 'center', backgroundSize: 'contain' }}></div>
                                                    :
                                                    <div className="image-input-wrapper" style={{ backgroundImage: 'url(assets/noImage.png)', backgroundPosition: 'center' }}></div>
                                            }
                                            <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                                                <i className="fa fa-pen icon-sm text-muted"></i>
                                                <input type="file" name="profileImage" accept=".png, .jpg, .jpeg" ref={this.myRef} onChange={(e) => this.onCompanyLogoChange(e)} />
                                            </label>
                                            {
                                                this.state.image_url &&

                                                <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-toggle="tooltip" title="Cancel Image" onClick={(e) => this._onRemoveImage(e)} style={{ position: 'absolute', right: '-10px', bottom: '-5px' }}>
                                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">

                                    <label className="col-lg-1 col-form-label">Upload Files:</label>
                                    <div className="col-lg-9">
                                        <div className="dropzone dropzone-multi">
                                            <div className="dropzone-panel mb-lg-0 mb-2">
                                                <button className="dropzone-select btn btn-light-primary font-weight-bold btn-sm cursor-pointer">Attach files</button>
                                                <input type="file" name="documents" multiple id="chooseFile" className="file-upload" onChange={(event) => this._onFileUploadChange(event)} />
                                            </div>
                                            {
                                                this.state.documents.length > 0 &&
                                                <div className="dropzone-items">
                                                    {
                                                        this.documents(this.state.documents)
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.uploaded_docs.length !== 0 ?
                                        <div className="form-group row">
                                            <label className="col-lg-1 col-form-label">Upload Files:</label>
                                            <div className="col-lg-9">
                                                <div className="dropzone dropzone-multi">
                                                    {this.state.uploaded_docs.map((document, index) => {
                                                        return (
                                                            <div key={index} className="dropzone-item dz-processing dz-error dz-complete">
                                                                <div className="dropzone-file">
                                                                    <div className="dropzone-filename" title="some_image_file_name.jpg">
                                                                        <span data-dz-name="">{document.fileName}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="dropzone-toolbar" onClick={() => this._removeDocument(document.name)}>
                                                                    <span className="dropzone-delete">
                                                                        <i className="flaticon2-cross"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-lg-6">
                                        {
                                            this.state.loading ?
                                                <button type="button" className="btn btn-primary mr-2 spinner spinner-white spinner-right">Save</button>

                                                :
                                                <button type="button" className="btn btn-primary mr-2" onClick={() => { this.onAddBusiness() }}>{(this.state.id && this.state.id !== '') ? 'Edit' : 'Save'}</button>
                                        }
                                        <button type="reset" className="btn btn-secondary" onClick={() => { window.location = '/' }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CreateCompany;