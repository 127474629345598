import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MessageAlert from '../Components/MessageAlert';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';

class ForgotPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            success: false,
            successMsg: '',
            errors: {},
            loading: false
        }
    }

    onSendForgetPasswordMail = () => {
        let errors = {};
        if (this.state.email.length != 0) {
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
                errors.email = 'Please enter valid Email.';
            }
        } else if (!this.state.email || this.state.email.trim().length == 0) {
            errors.email = 'Please enter valid Email.';
        }
        this.setState({ errors })
        if (errors && Object.keys(errors).length > 0) {
            this.setState({ errors })
        }
        else {
            this.setState({ errors: {} })
            let errors = {};
            this.setState({ loading: true })
            let body = JSON.stringify({
                email: this.state.email,
            });
            fetch(Constants.url + 'resetPasswordLink',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: body
                }).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.status) {
                        this.setState({ loading: false, success: true, successMsg: responseData.message || 'Reset mail sended successfully.', email: '' });
                    } else {
                        if (Array.isArray(responseData.error) && "message" in responseData.error[0]) {
                            errors.error = responseData.error[0]['message'];
                        } else {
                            errors.error = responseData.error;
                        }
                        this.setState({ errors: errors, loading: false })
                    }
                    setTimeout(() => {
                        this.setState({ errors: {}, successMsg: '', success: false })
                    }, 5000);
                })
                .catch(err => {
                    this.setState({ loading: false })
                    errors.error = "Something went wrong, Please try again after sometime.";
                    this.setState({ errors })
                    console.log(err);
                });
        }
    }
    render() {
        return (
            <>
                <div id="kt_header_mobile" className="header-mobile header-mobile-fixed" style={{ backgroundColor: '#07a398' }}>
                    {/* <!--begin::Logo--> */}
                    <Link to={'/'}>
                        <img alt="Logo" src="/assets/logo/Logo-white.png" className="max-h-25px" />
                    </Link>
                    {/* <!--end::Logo--> */}                    
                </div>
                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-row flex-column-fluid page">
                        <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                            <div id="kt_header" className="header header-fixed">
                                <div className="container d-flex align-items-stretch justify-content-between">
                                    <div className="d-flex align-items-stretch mr-3">
                                        <div className="header-logo">
                                            <Link to={'/'}>
                                                <img alt="Logo" src="/assets/logo/Logo-white.png" className="logo-default max-h-40px" />
                                                <img alt="Logo" src="/assets/logo/Logo.png" className="logo-sticky max-h-30px" />
                                                {/* <ReactSVG src={"assets/logo/Logo.svg"} className="logo-sticky max-h-40px" /> */}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content d-flex flex-column flex-column-fluid mb-20" id="kt_content">
                                <div className="subheader py-2 py-lg-7 subheader-transparent" id="kt_subheader">
                                    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                        <div className="d-flex align-items-center flex-wrap mr-1">
                                            <div className="d-flex flex-column">
                                                <h2 className="text-white font-weight-bold my-2 mr-5">Forgot Password</h2>
                                                <div className="d-flex align-items-center font-weight-bold my-2">
                                                    <a href="#" className="opacity-75 hover-opacity-100">
                                                        <i className="flaticon2-shelter text-white icon-1x"></i>
                                                    </a>
                                                    <span className="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                                                    <span>
                                                        <a href="" className="text-white text-hover-white opacity-75 hover-opacity-100">Forgot Password</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column-fluid">
                                    <div className="container">
                                        <div className="card card-custom gutter-b">
                                            <div className="card-header">
                                                <h3 className="card-title">Reset your password</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="col-md-12">
                                                    {
                                                        this.state.success &&
                                                        <MessageAlert children={this.state.successMsg} variant="success" />
                                                    }
                                                    {this.state.errors.error &&
                                                        <MessageAlert children={this.state.errors.error} variant="danger" />
                                                    }
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-lg-12">
                                                        <label>Email <span className="text-danger">*</span></label>
                                                        <input type="email" className="form-control form-control-lg" name="email" placeholder="Enter Email" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.email} />
                                                        {this.state.errors.email && <span className="form-text text-danger pl-2">{this.state.errors.email}</span>}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card-footer">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        {
                                                            this.state.loading ?
                                                                <button type="button" className="btn btn-primary mr-2 spinner spinner-white spinner-right">Save</button>

                                                                :
                                                                <button type="button" className="btn btn-primary mr-2" onClick={(event) => { this.onSendForgetPasswordMail(event) }}>Submit</button>
                                                        }
                                                        <button type="reset" className="btn btn-secondary" onClick={() => { window.location = '/' }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ForgotPassword;