import React from 'react';
import { useGoogleLogin } from 'react-google-login';
import Constants from '../Utilities/Constants';

// refresh token
import { refreshTokenSetup } from '../Utilities/refreshToken';

const clientId = Constants.ClientId;

function LoginHooks(props) {
    const onSuccess = (res) => {
        console.log('Login Success: currentUser:', res.profileObj);
        refreshTokenSetup(res);
        verifyUser(res);
    };
    
    const onFailure = (res) => {
        console.log('Login failed: res:', res);
        // alert(
        //     `Failed to login.`
        // );
    };

    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId,
        isSignedIn: false,
        accessType: 'offline',
        // responseType: 'code',
        prompt: 'select_account',
    });

    const verifyUser = (res) => {
        
        let body = JSON.stringify({
            token: res.tokenId
        });
        
        fetch(Constants.url + "verify/user",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            })
            .then((response) => response.json())
            .then((responseData) => {
                console.log(responseData)
                if (responseData.status) {
                    // localStorage.setItem('user', JSON.stringify(responseData.body.user));
                    // localStorage.setItem('token', "Bearer " + responseData.body.token);

                    // window.location = '/';
                    props.callBack(responseData)
                } else {
                    console.log("Error")
                }
            }).catch((err) => {
                console.log("err", err);
            });
    }

    return (

        <button onClick={signIn} type="button" className="btn font-weight-bolder px-8 py-4 my-3 font-size-lg shadow-sm" style={{backgroundColor:'#ffffff',borderColor:'#dddddd4a'}}>
            <img src="assets/media/svg/social-icons/google.svg" alt="google login" className="svg-icon svg-icon-md"></img>
            <span>Sign in with Google</span>
        </button>
    );
}

export default LoginHooks;