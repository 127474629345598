import React, { useState, useEffect } from 'react';
import Constants from '../Utilities/Constants';
import AutoSuggestion from '../Components/AutoSuggestion';

function InputTag(props) {
    let [tags, setTags] = useState(props.tags || []);
    let [tagname, setTagName] = useState('');
    let [searchText,setSearchText] = useState('')
    let [searchList,setSearchList] = useState([])
    let [showSuggestions,setShowSuggestions] = useState(false)

    const removeTag = (i) => {
        const newTags = [...tags];
        newTags.splice(i, 1);
        setTags(newTags);
        props.onGetTags(newTags)
    }

    const inputKeyDown = (e) => {
        const val = e.target.value;
        if (e.key === 'Enter' && val) {
            if (tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
                return;
            }
            setTags([...tags, val]);
            setTagName("")
            setSearchText("")
            props.onGetTags([...tags, val])
        } else if (e.key === 'Backspace' && !val) {
            removeTag(tags.length - 1);
        }
    }

    const addTags = () => {
        const val = searchText;

        if (tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
            return;
        }
        setTags([...tags, val]);
        setTagName("")
        setSearchText("")
        props.onGetTags([...tags, val])

    }

    useEffect(() => {
        setTags(props.tags);
    }, [props])

    const setTag = (e) => {
        setTagName(e.target.value)
    }

    const onChangeService = async (event) => {
        await setSearchText(event.target.value)
        
        if (searchText !== '') {
            await getSerachTags();
        } else {
            setSearchList([]);
            setShowSuggestions(false)
        }
    }

    const getSerachTags = () => {
        let errors = {};
        
        fetch(Constants.url + 'tags/' + encodeURIComponent(searchText),
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData)
                if (responseData.status) {
                    setSearchList(responseData.tags)
                    setShowSuggestions(true)
                    
                } 
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                
            });
    }

    const onOptionClick = (e, suggestion, keyName) => {
        // console.log(suggestion, keyName)
        setSearchList([]);
        setShowSuggestions(false)
        setSearchText("")
        let val = e.currentTarget.innerText;
        if (tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
            return;
        }
        console.log(e.currentTarget.innerText)
        setTags([...tags, val]);
        setTagName("")
        props.onGetTags([...tags, val])
    }


    return (
        <>
        <div className="input-tag form-control h-auto py-5 px-6">
            <ul className="input-tag__tags">
                {props.tags.map((tag, i) => (
                    <li key={tag}>
                        {tag}
                        <i className='fas fa-window-close' onClick={() => { removeTag(i); }}></i>
                    </li>
                ))}
                <li className="input-tag__tags__input d-flex flex-center bg-white rounded">
                    <input type="text" value={searchText} onChange={setTag} onKeyDown={inputKeyDown} className="form-control font-weight-bold pl-2" onChange={(event) => onChangeService(event)} />
                    {searchText && <button className="btn btn-light-primary px-6 font-weight-bold ml-5" onClick={addTags}>Add</button>}                    
                </li>
            </ul>
        </div>
        <div className="col-md-12 p-0">
            <AutoSuggestion suggestions={searchList} schema={{ name: 'name' }} onOptionClick={(e, suggestion) => { onOptionClick(e, suggestion, "city") }} />
        </div>
        </>
    )

}

export default InputTag