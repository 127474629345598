import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Redirect ,Switch } from 'react-router-dom';
import Login from '../Pages/Login';

class loginRoute extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/" exact name="login" component={Login}></Route>
                </Switch>
            </Router>
        );
    }
}

export default loginRoute;