import React, { Component } from 'react';
import AllFunction from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import Axios from 'axios';
import Auth from '../Utilities/Auth';
import { saveAs } from 'file-saver'
import Store from './store';
import { Link, Redirect } from 'react-router-dom';
import UserProfileCard from '../Components/UserProfileCard';
class Chat extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            token: '',
            user: '',
            chatList: [],
            messageText: '',
            messageType: 'text',
            disable: true,
            listLoading: false,
            historyLoading: false,
            dataLoading: false,
            chatTitle: '',
            chatHistory: [],
            chatUserId: '',
            fileUrl: '',
            file: '',
            errors: {},
            loading: false,
            imgExtention: ["image/jpg", "image/jpeg", "image/png", "image/gif"],
            searchName: '',
            postId: '',
            postDetail: '',
            connectionList: [],
            redirect: false,
            users: [],
            userId: '',
            chatlsId: '',
            chatType: 'User',
            businessId: ''

        }
        this.store = Store;
    }

    downloadFile(url, name) {
        saveAs(url, name);
    }

    componentDidMount = async () => {

        await this.store.setSubHeader('Chat', ["Chat", "Latest Communication"]);

        const params = new URLSearchParams(this.props.location.search);
        const receiverId = params.get('receiverId');
        const chatListId = params.get('chatListId');
        const postId = params.get('post');
        const name = params.get('Name');
        const chatType = params.get('type');
        const businessId = params.get('businessId');

        await this.setState({ chatUserId: receiverId, postId: postId, chatTitle: name, chatType: chatType, businessId: businessId, chatlsId: chatListId })
        if (this.state.postId && this.state.postId !== '') {
            await this.getPostDetail();
        }
        await this.getUserCredintials();

        const script2 = document.createElement('script');
        script2.src = process.env.PUBLIC_URL + "/assets/js/pages/custom/chat/chat.js"
        document.body.appendChild(script2);

    }

    // get token and user details from local storage
    getUserCredintials = async () => {
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        const token = await localStorage.getItem('token');

        if (!token) {
            Auth.Logout();
        } else {
            const userId = user._id;
            await this.setState({ userId })
            await this.setState({ user, token });
            await this.getChatList()

            if (this.state.chatUserId && this.state.chatUserId !== '') {
                await this.getChatHistory(this.state.chatUserId, this.state.chatTitle,this.state.chatlsId,this.state.businessId,this.state.chatType);
            }
        }
    }

    /** Get Post Detail */
    getPostDetail = () => {
        this.setState({ dataLoading: true });
        let errors = {};
        fetch(Constants.url + 'post/' + this.state.postId,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        dataLoading: false,
                        postDetail: responseData.post,
                        messageText: responseData.post.title,
                        disable: false
                    });
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    scrollToBottom = async () => {
        await this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    onUploadFile = async (e) => {
        let errors = {};

        if (e.target.files[0]['size'] > 2097152) {
            errors.file = "File size exceeded 2 MB";
            this.setState({ errors: errors });
        }
        else {
            const file = this.myRef.current.files[0];
            const reader = new FileReader();

            reader.onloadend = async () => {
                await this.setState({
                    fileUrl: reader.result
                })
            }
            await this.setState({
                file: e.target.files[0],
                messageType: 'image',
                disable: false,
                messageText: ''
            })

            e.target.value = '';
            if (file) {
                reader.readAsDataURL(file);
            }
            else {
                this.setState({
                    fileUrl: ""
                })
            }
        }
    }

    onRemoveImage = async (e) => {
        await this.setState({ fileUrl: '', file: '', messageType: 'text', disable: true })
    }

    onChangeChatMessage = (e) => {
        this.setState({ messageText: e.target.value, messageType: 'text', disable: false })
        if (e.target.value === '') {
            this.setState({ disable: true })
        }
    }

    // Get Chat list
    getChatList = async () => {

        this.setState({ listLoading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);
        let errors = {};
        fetch(Constants.url + 'chat/chatList',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        chatList: responseData.ChatList
                    });
                    if (responseData.ChatList.length <= 0) {
                        this.getAcceptedConnectionList();
                    }
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    getChatHistory = async (chatUserId = '', chatTitle = '', chatlsId = '', businessId = '', chatType = '') => {

        await this.setState({ historyLoading: true, chatTitle, chatUserId, chatlsId: chatlsId, businessId: businessId, chatType: chatType })

        var localIds = JSON.parse(localStorage.getItem('ChatListIds')) || [];
        var chatLsIds = localIds.filter((id) => {
            return id !== chatlsId;
        });
        localStorage.setItem('ChatListIds', JSON.stringify(chatLsIds));
        setTimeout(() => {
            window.blockPage()
        }, 300);
        let errors = {};
        fetch(Constants.url + 'chat/chatHistory/' + this.state.chatlsId,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
            })
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        historyLoading: false,
                        chatHistory: responseData.Chats,
                    });
                    this.scrollToBottom();
                    if (this.state.chatlsId != "") {
                        this.updateChatList();
                    }
                    this.getChatList();
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, historyLoading: false })
                }
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            })
            .catch(err => {
                this.setState({ historyLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    updateChatList = () => {
        let errors = {};
        let body = JSON.stringify({
            _id: this.state.chatlsId
        })
        fetch(Constants.url + 'chatList/updateList',
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body,
            }).then((response) => response.json())
            .then((responseData) => {
                let newchatList = [];
                newchatList = this.state.chatList.map(obj =>
                    obj._id == this.state.chatlsId ? { ...obj, seen: true } : obj
                )
                this.setState({ chatList: newchatList })
            })
            .catch(err => {
                this.setState({ historyLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    onSendMessage = () => {
        this.setState({ loading: true })
        // return false;
        if (this.state.messageText == "" && this.state.selected_file == "") {
            alert("Add message or file");
        } else {
            let message = JSON.stringify({
                type: this.state.messageType,
                message: this.state.messageText,
            })
            let formData = new FormData();
            formData.append('senderId', this.state.user._id);
            formData.append('receiverId', this.state.chatUserId);
            formData.append('businessId', this.state.businessId);
            formData.append('chatType', this.state.chatType);
            formData.append('message', message);
            if (this.state.file) {
                formData.append('chatMedia', this.state.file);
            }

            let errors = {};
            Axios({
                method: "POST",
                url: Constants.url + 'chat/sendMessage',
                data: formData,
                headers: {
                    'Accept': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': this.state.token
                }
            }).then(response => {
                let responseData = response.data;
                if (responseData.status) {
                    this.setState({ loading: false, messageText: '', file: '', fileUrl: '', disable: true });
                    this.getChatHistory(this.state.chatUserId, this.state.chatTitle, responseData.chats.chatListId);
                    this.getChatList();
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    /**
     * Get All Accepted Connected users
     */
    getAcceptedConnectionList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'connection/accepted',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        connectionList: responseData.connections
                    });
                    if (responseData.connections.length <= 0) {
                        this.getUserList();
                    }
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     * Get not connected users same as dashboard User
     */
    getUserList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + '/dashboardUser',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        users: responseData.users,
                        dataLoading: false,
                    });
                    setTimeout(() => {
                        window.loadPopover()
                    }, 300);
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     *
     * @param {*} conectUserId
     * @description This function send conction request to selected user
     */
    sendConnectionRequest = (conectUserId) => {
        this.setState({ loading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);

        let errors = {};
        let body = JSON.stringify({
            conectUserId: conectUserId,
        });

        fetch(Constants.url + 'connect',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({ loading: false, redirect: true })
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                 else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    renderRedirect = () => {

        if (this.state.redirect) {
            return (<Redirect to={'/InvitationManager/?tab=sent'} />
            )
        }
    }

    startCommunication = async (receiverId, name) => {
        await this.setState({ chatUserId: receiverId, chatTitle: name, messageText: "Hello", disable: false })
    }

    render() {
        return (
            <>
                {this.renderRedirect()}
                <div className="d-flex flex-column-fluid">
                    <div className="container">

                        {
                            this.state.chatList.length <= 0 && this.state.chatUserId === null ?
                                <div className="">
                                    <>
                                        {
                                            this.state.connectionList.length <= 0 ?
                                                this.state.dataLoading ? '' : this.state.users.length > 0 &&
                                                    <><div className="mb-2 card card-custom overflow-hidden position-relative "><div className="card-body"><h3>Start Communication</h3></div></div>
                                                    </> : ''
                                        }
                                    </>
                                    <div className="card card-custom overflow-hidden position-relative ">
                                        {
                                            this.state.connectionList.length <= 0 ?
                                                this.state.dataLoading ? '' : this.state.users.length > 0 &&
                                                    <>
                                                        <div className="d-flex justify-content-between align-items-center mb-0 card-header">
                                                            <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Grow Your Network</h2>
                                                            <Link to={'/Search'} className="btn btn-light-primary btn-sm font-weight-bolder">View All</Link>
                                                        </div></> :
                                                <div className="d-flex justify-content-between align-items-center mb-0 card-header">
                                                    <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Start Communication</h2>
                                                </div>
                                        }
                                        <div className="card-body">

                                            {
                                                this.state.connectionList.length <= 0 ?
                                                    this.state.dataLoading ? '' : this.state.users.length > 0 &&
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                {
                                                                    this.state.dataLoading ? '' :
                                                                        <UserProfileCard userList={this.state.users} connectionList={this.state.allconnectionList} onConnectUser={(id) => { this.sendConnectionRequest(id) }} />
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                    <div className="row">
                                                        {
                                                            this.state.connectionList.map((conection, index) => {

                                                                let conect = '';
                                                                if (conection.userId._id === this.state.userId) {
                                                                    conect = conection.conectUserId
                                                                } else {
                                                                    conect = conection.userId;
                                                                }
                                                                let shortName = conect.firstName.charAt(0);
                                                                shortName += conect.lastName ? conect.lastName.charAt(0) : '';

                                                                return (
                                                                    <div key={index} className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                                                        <div className="card card-custom gutter-b card-stretch shadow-sm">
                                                                            <div className="card-body text-center pt-4">
                                                                                <div className="mt-2 pb-2">
                                                                                    {
                                                                                        conect.profileImage ?
                                                                                            <div className="symbol symbol-lg-75 symbol-circle">
                                                                                                <img src={Constants.ProfileImageUrl + conect.profileImage} alt="image" />
                                                                                            </div>
                                                                                            :
                                                                                            <div className="symbol symbol-lg-75 symbol-circle symbol-light-success">
                                                                                                <span className="font-size-h3 symbol-label font-weight-boldest">{shortName}</span>
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                                <div className="my-2">
                                                                                    <span className="text-dark font-weight-bold font-size-h4">{conect.firstName} {conect.lastName}</span>
                                                                                </div>
                                                                                <div className="text-muted" style={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', height: 40, textOverflow: 'ellipsis' }}>
                                                                                    {
                                                                                        conect.occupationType === 'employee' ?
                                                                                            <span>{conect.company ? conect.company.designation + ' at ' + conect.company.companyName : ''}</span>
                                                                                            :
                                                                                            conect.occupationType === 'student' ? <span>{conect.occupationType + ' at ' + conect.collegeName}</span> :
                                                                                                conect.occupationType === 'business' ? <span>{conect.occupationType}</span> : ''

                                                                                    }
                                                                                </div>
                                                                                <div className="text-muted" >
                                                                                    <span>{conect.city}{conect.state ? ',' + conect.state : ''}{conect.country ? ',' + conect.country : ''}</span>
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        <div className="d-flex justify-content-between">
                                                                                            <button onClick={() => { this.startCommunication(conect._id, conect.firstName) }} className="btn btn-light-success btn-block mt-5"><i className="flaticon2-chat-1"></i> Message</button>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                            }


                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="d-flex flex-row">
                                    <div className="flex-row-auto offcanvas-mobile w-350px w-xl-400px" id="kt_chat_aside">
                                        <div className="card card-custom">
                                            <div className="card-body p-3">
                                                <div className="input-group input-group-solid">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <span className="svg-icon svg-icon-lg">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <input type="text" className="form-control py-4 h-auto" placeholder="Name" name='searchName' value={this.state.searchName} onChange={(event) => { AllFunction.onChange(event, this) }} />
                                                </div>
                                                <div className="mt-7 scroll scroll-pull" style={{ height: 578, overflow: 'auto' }}>

                                                    {
                                                        this.state.listLoading ? '...' :
                                                            this.state.chatList.map((chatls, index) => {
                                                                let users = [];
                                                                users = chatls.userIds.filter((user) => {
                                                                    return ((user._id !== this.state.user._id) && user.firstName.toLowerCase().includes(this.state.searchName))
                                                                })

                                                                let time = AllFunction.dateDifference(chatls.date);
                                                                if (chatls.chatType == 'BusinessChat' && chatls.businessId.userId != this.state.user._id) {
                                                                    return (
                                                                        <div key={index} className={`cursor-pointer d-flex align-items-center justify-content-between mb-5 ${chatls.businessId?._id === this.state.chatUserId ? 'chatActive' : ''}`} style={{ padding: '5px 15px' }} onClick={() => { this.getChatHistory(chatls.businessId.userId, chatls.businessId.businessName, chatls._id, chatls.businessId._id, chatls.chatType) }}>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="symbol symbol-circle symbol-50 mr-3">
                                                                                    {
                                                                                        chatls.businessId?.companyLogo ? <img alt="Pic" src={Constants.CompanyLogoUrl + chatls.businessId?.companyLogo} />
                                                                                            :
                                                                                            <img alt="Pic" src="/assets/media/users/default.jpg" />
                                                                                    }
                                                                                </div>
                                                                                <div className="d-flex flex-column">
                                                                                    <div className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">{chatls.businessId?.businessName}</div>
                                                                                    <span className="text-muted font-weight-bold font-size-sm">
                                                                                        {chatls.message.type === 'text' ? chatls.message.message : 'Image'}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex flex-column align-items-end">
                                                                                {chatls.seen == false && chatls.lastSenderID != this.state.user._id && <div className="d-flex flex-column align-items-end"><span className="fas fa-circle text-success"></span></div>}
                                                                                <span className="text-muted font-weight-bold font-size-sm">{time}</span>
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                } else {
                                                                    return (
                                                                        users.length < 1 ? '' :
                                                                            <div key={index} className={`cursor-pointer d-flex align-items-center justify-content-between mb-5 ${users[0]?._id === this.state.chatUserId ? 'chatActive' : ''}`} style={{ padding: '5px 15px' }} onClick={() => { this.getChatHistory(users[0]._id, users[0].firstName, chatls._id, chatls?.businessId?._id, chatls.chatType) }}>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="symbol symbol-circle symbol-50 mr-3">
                                                                                        {
                                                                                            users[0]?.profileImage ? <img alt="Pic" src={Constants.ProfileImageUrl + users[0]?.profileImage} />
                                                                                                :
                                                                                                <img alt="Pic" src="/assets/media/users/default.jpg" />
                                                                                        }
                                                                                    </div>
                                                                                    <div className="d-flex flex-column">
                                                                                        <div className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">{users[0]?.firstName}</div>
                                                                                        <span className="text-muted font-weight-bold font-size-sm">
                                                                                            {chatls.message.type === 'text' ? chatls.message.message : 'Image'}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex flex-column align-items-end">
                                                                                    {chatls.seen == false && chatls.lastSenderID != this.state.user._id && <div className="d-flex flex-column align-items-end"><span className="fas fa-circle text-success"></span></div>}
                                                                                    <span className="text-muted font-weight-bold font-size-sm">{time}</span>
                                                                                </div>
                                                                            </div>
                                                                    )
                                                                }

                                                            })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-row-fluid ml-lg-8" id="kt_chat_content">
                                        <div className="card card-custom">
                                            {
                                                this.state.chatUserId === null ? <div className="card-body" style={{ height: 665, backgroundImage: 'url("assets/EmptyScreen/Communication.png")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 450, objectFit: 'contain' }}>Start Communication</div> :
                                                    this.state.historyLoading ?
                                                        <div className="card-body spinner spinner-center" style={{ height: 665 }}>

                                                        </div>
                                                        :
                                                        <>
                                                            <div className="card-header align-items-center px-4 py-3">

                                                                <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md d-lg-none text-left" id="kt_app_chat_toggle">
                                                                    <span className="svg-icon svg-icon-lg">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24" />
                                                                                <path d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z" fill="#000000" opacity="0.3" />
                                                                                <path d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z" fill="#000000" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </button>

                                                                <div className="text-center flex-grow-1">
                                                                    <div className="text-dark-75 font-weight-bold font-size-h5">{this.state.chatTitle}</div>
                                                                    <div>
                                                                        <span className="label label-sm label-dot label-success"></span>
                                                                        <span className="font-weight-bold text-muted font-size-sm">Active</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="scroll scroll-pull" data-mobile-height="350" style={{ height: 380, overflow: 'auto' }}>
                                                                    <div className="messages">
                                                                        {
                                                                            this.state.chatHistory.map((chat, index) => {

                                                                                let chatTime = AllFunction.dateDifference(chat.date);

                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        {
                                                                                            chat.senderId._id == this.state.user._id ?
                                                                                                // Sender
                                                                                                <div className="d-flex flex-column mb-5 align-items-end">
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <div>
                                                                                                            <span className="text-muted font-size-sm pr-2">{chatTime}</span>
                                                                                                            <span className="text-dark-75 font-weight-bold font-size-h6">You</span>
                                                                                                        </div>
                                                                                                        <div className="symbol symbol-circle symbol-40 ml-3">
                                                                                                            {
                                                                                                                chat.senderId.profileImage ?
                                                                                                                    <img alt="Pic" src={Constants.ProfileImageUrl + chat.senderId.profileImage} />
                                                                                                                    :
                                                                                                                    <img alt="Pic" src="/assets/media/users/default.jpg" />
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        chat.message.type === 'text' ?
                                                                                                            <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">
                                                                                                                {chat.message.message}
                                                                                                            </div>
                                                                                                            :
                                                                                                            <>
                                                                                                                {this.state.imgExtention.includes(chat.message.fileType) ?
                                                                                                                    <div className="mt-2 rounded p-3 bg-light-primary text-right max-w-400px">
                                                                                                                        <div className="symbol symbol-70 symbol-2by3">
                                                                                                                            <div className="symbol-label" style={{ backgroundImage: `url(${Constants.ChatMediaUrl}${chat.message.url})`, backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div className="d-flex mt-2 rounded p-5 bg-light-primary text-right max-w-400px">
                                                                                                                        <div className="symbol symbol-35 mr-2">
                                                                                                                            <span className="symbol-label"><i className="flaticon-file-2 icon-lg"></i></span>
                                                                                                                        </div>
                                                                                                                        <div className="d-flex align-items-center text-left">
                                                                                                                            <span className="font-weight-bold">{chat.message.fileName}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                // Receiver
                                                                                                <div className="d-flex flex-column mb-5 align-items-start">
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <div className="symbol symbol-circle symbol-40 mr-3">
                                                                                                            {
                                                                                                                chat.senderId.profileImage ?
                                                                                                                    <img alt="Pic" src={Constants.ProfileImageUrl + chat.senderId.profileImage} />
                                                                                                                    :
                                                                                                                    <img alt="Pic" src="/assets/media/users/default.jpg" />
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span className="text-dark-75 font-weight-bold font-size-h6">{chat.senderId.firstName}</span>
                                                                                                            <span className="text-muted font-size-sm pl-2">{chatTime}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        chat.message.type === 'text' ?
                                                                                                            <div className="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">
                                                                                                                {chat.message.message}
                                                                                                            </div>
                                                                                                            :
                                                                                                            <>
                                                                                                                {this.state.imgExtention.includes(chat.message.fileType) ?
                                                                                                                    <div className="mt-2 rounded p-3 bg-light-success text-right max-w-400px cursor-pointer" onClick={() => this.downloadFile(Constants.ChatMediaUrl + chat.message.url, chat.message.fileName)}>
                                                                                                                        <div className="symbol symbol-70 symbol-2by3">
                                                                                                                            <div className="symbol-label" style={{ backgroundImage: `url(${Constants.ChatMediaUrl}${chat.message.url})`, backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div className="d-flex mt-2 rounded p-5 bg-light-success text-right max-w-400px cursor-pointer" onClick={() => this.downloadFile(Constants.ChatMediaUrl + chat.message.url, chat.message.fileName)}>
                                                                                                                        <div className="symbol symbol-35 mr-2">
                                                                                                                            <span className="symbol-label"><i className="flaticon-file-2" icon-lg></i></span>
                                                                                                                        </div>
                                                                                                                        <div className="d-flex align-items-center text-left">
                                                                                                                            <span className="font-weight-bold">{chat.message.fileName}</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </>

                                                                                                    }
                                                                                                </div>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                        <div style={{ float: "left", clear: "both" }}
                                                                            ref={(el) => { this.messagesEnd = el; }}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-footer align-items-center">
                                                                {
                                                                    this.state.messageType === 'text' ?
                                                                        <input type="text" className="form-control border-0 p-5 form-control-solid form-control-lg" rows="2" placeholder="Type a message" name="messageText" onChange={(event) => { this.onChangeChatMessage(event) }} value={this.state.messageText} />
                                                                        :
                                                                        this.state.fileUrl ?
                                                                            this.state.imgExtention.includes(this.state.file.type) ?
                                                                                <div className="symbol symbol-60 symbol-2by3 mr-3">
                                                                                    <div className="symbol-label" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.state.fileUrl})`, backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                                                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-toggle="tooltip" title="Cancel Image" onClick={(e) => this.onRemoveImage(e)} style={{ position: 'absolute', right: '-12px', top: '-11px' }}>
                                                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                                    </span>
                                                                                </div>
                                                                                :
                                                                                <div className='d-flex'>
                                                                                    <div className="symbol symbol-35 mr-2">
                                                                                        <span className="symbol-label"><i className="flaticon-file-2 icon-lg"></i></span>
                                                                                    </div>
                                                                                    <div className="d-flex align-items-center text-left">
                                                                                        <span className="text-muted font-weight-bold">{this.state.file.name}</span>
                                                                                    </div>
                                                                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ml-3" data-toggle="tooltip" title="Cancel Image" onClick={(e) => this.onRemoveImage(e)}>
                                                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                                    </span>
                                                                                </div>
                                                                            : ''
                                                                }

                                                                <div className="d-flex align-items-center justify-content-between mt-5">
                                                                    <div className="mr-3">
                                                                        <input type="file" id="file" name="profileImage" ref={this.myRef} onChange={(e) => this.onUploadFile(e)} style={{ display: 'none' }} />
                                                                        <label htmlFor='file'>
                                                                            <div className="btn btn-clean btn-icon btn-md mr-1">
                                                                                <i className="flaticon2-photograph icon-lg"></i>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <button type="button" className="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6" disabled={this.state.disable} onClick={() => { this.onSendMessage() }}>Send</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                            }
                                        </div>
                                    </div>

                                </div>
                        }

                    </div>
                </div>
            </>
        );
    }
}
export default Chat;
