import React, { Component } from 'react';
import Constants from '../Utilities/Constants';
import Auth from '../Utilities/Auth';
import AllFunction from '../Utilities/Functions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Store from './store';
import Axios from 'axios';
class CreatePost extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            userId: '',
            token: '',
            user: '',
            businesses: [],
            dataLoading: false,
            businessId: '',
            title: '',
            description: '',
            loading: false,
            errors: {},
            id: this.props.match.params.id ? this.props.match.params.id : '',
            category: '',
            image_url: '',
            postFile: '',
            uploadedImage: '',
            businessName: 'Select Business'
        }
        this.store = Store;
    }
    componentDidMount = async () => {
        await this.getUserCredintials();
        await this.store.setSubHeader("Post", ["Post", "Create Post"])
        if (this.state.id && this.state.id !== '') {
            await this.getPostDetail();
            await this.store.setSubHeader("Post", ["Post", "Edit Post"])
        }
    }

    getUserCredintials = async () => {
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        const token = await localStorage.getItem('token');
        const userId = user._id;
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ userId, token, user });
            await this.getUserBusiness();
        }
    }

    getPostDetail = () => {
        let errors = {};
        let body = JSON.stringify({
            userId: this.state.userId
        })
        this.setState({ dataLoading: true })
        fetch(Constants.url + 'post/' + this.state.id,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        dataLoading: false,
                        businessId: responseData.post.businessId,
                        title: responseData.post.title,
                        description: responseData.post.description,
                        category: responseData.post.category,
                        uploadedImage: responseData.post.file
                    });
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     * Get All Business of login user
     */
    getUserBusiness = async () => {
        this.setState({ dataLoading: true })
        let errors = {};
        fetch(Constants.url + 'userBusiness',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData)
                if (responseData.status) {
                    this.setState({
                        dataLoading: false, businesses: responseData.Businesses,
                    });
                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    postValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.businessId) {
            formIsValid = false;
            errors.businessId = "Please select Business.";
        }

        if (!this.state.category) {
            formIsValid = false;
            errors.category = "Please select Category.";
        }

        if (!this.state.title) {
            formIsValid = false;
            errors.title = "Please enter title.";
        }

        if (!this.state.description) {
            formIsValid = false;
            errors.description = "Please enter description.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onUploadPost = () => {
        if (this.postValidation()) {
            this.setState({ loading: true })
            let errors = {};
            let method = 'POST';

            let formData = new FormData();

            if (this.state.id && this.state.id !== '') {
                method = 'PUT';
                formData.append('_id', this.state.id);
            }

            formData.append('businessId', this.state.businessId);
            formData.append('category', this.state.category);
            formData.append('title', this.state.title);
            formData.append('description', this.state.description);
            if (this.state.postFile !== '') {
                formData.append('file', this.state.postFile);
            }

            Axios({
                method: method,
                url: Constants.url + 'post',
                data: formData,
                headers: {
                    'Accept': 'application/json,multipart/form-data',
                    'Content-Type': 'application/json,multipart/form-data',
                    'Authorization': this.state.token
                }
            }).then(response => {
                let responseData = response.data;
                // console.log("Add Post", responseData)
                if (responseData.status) {
                    this.setState({ loading: false });
                    window.location = '/';

                }else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                }
                 else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.log(error);
            });

        }
    }

    onUploadFileChange = async (e) => {
        let errors = {};

        if (e.target.files[0]['size'] > 2097152) {
            errors.postFile = "File size exceeded 2 MB";
            this.setState({ errors: errors });
        }
        else {
            const file = this.myRef.current.files[0];
            const reader = new FileReader();

            reader.onloadend = async () => {
                await this.setState({
                    image_url: reader.result
                })
            }
            await this.setState({
                postFile: e.target.files[0]
            })
            e.target.value = '';
            if (file) {
                reader.readAsDataURL(file);
            }
            else {
                this.setState({
                    image_url: ""
                })
            }
        }
    }

    _onRemoveImage = async (e) => {
        await this.setState({ image_url: '', postFile: '' })
    }

    changeBusiness = async (businessId, businessName) => {
        await this.setState({ businessId, businessName })
    }
    render() {
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="card card-custom gutter-b">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    {/* <!--begin::Symbol--> */}
                                    <div className="symbol symbol-30 symbol-lg-40 symbol-circle  mr-5">
                                        {
                                            this.state.user.profileImage ?
                                                <img src={Constants.ProfileImageUrl + this.state.user.profileImage} alt="" />
                                                :
                                                <img src="assets/media/svg/avatars/007-boy-2.svg" alt="" />
                                        }
                                    </div>
                                    {/* <!--end::Symbol--> */}
                                    {/* <!--begin::Description--> */}
                                    <span className="text-muted font-weight-bold font-size-lg">What do you want to talk about?</span>
                                    {/* <!--end::Description--> */}
                                </div>
                                <div className="col-md-12 p-0 pt-5">
                                    <div className="form-group row">
                                        {/* <label className="col-form-label col-lg-2 col-sm-12">Select Business <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <select className="form-control" name="businessId" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.businessId}>
                                                <option value=''>Select Business</option>
                                                {
                                                    this.state.dataLoading ? '...' :
                                                        this.state.businesses.map((business, index) => {
                                                            return (
                                                                <option key={index} value={business._id}>
                                                                    {business.businessName}
                                                                </option>
                                                            )
                                                        })
                                                }
                                            </select>
                                            {this.state.errors.businessId && <span className="text-danger pl-2">{this.state.errors.businessId}</span>}
                                        </div> */}
                                        <label className="col-form-label col-lg-2 col-sm-12">Select Business <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <div class="dropdown mr-1">
                                                <button type="button" class="btn btn-outline-secondary btn-block dropdown-toggle d-flex align-items-center justify-content-between" id="dropdownMenuOffset" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {this.state.businessName}
                                                </button>
                                                <div class="dropdown-menu pb-0 pt-0" aria-labelledby="dropdownMenuOffset" style={{width:'100%'}}>
                                                    {
                                                        this.state.dataLoading ? '...' :
                                                            this.state.businesses.map((business, index) => {
                                                                return (
                                                                    <p class="dropdown-item mb-0 cursor-pointer d-block" onClick={() => { this.changeBusiness(business._id, business.businessName) }}>
                                                                        <div className="row align-items-center">
                                                                            <div className="col-md-1">
                                                                                <div className="symbol symbol-35 symbol-lg-35 mr-5">
                                                                                    {
                                                                                        business.companyLogo ?
                                                                                            <img src={Constants.CompanyLogoUrl + business.companyLogo} alt="" style={{ width: 35 }} />
                                                                                            :
                                                                                            <img src="assets/noImage.png" alt="" style={{ width: 35 }} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-11">
                                                                                {business.businessName}
                                                                            </div>
                                                                        </div>
                                                                    </p>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            </div>
                                            {this.state.errors.businessId && <span className="text-danger pl-2">{this.state.errors.businessId}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-2 col-sm-12">Select Category <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <select className="form-control" name="category" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.category}>
                                                <option value=''>Select Category</option>
                                                {
                                                    Constants.POST_CATEGORIES.map((cat, id) => {
                                                        return (
                                                            <option value={`${cat.value}`}>{cat.title}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {this.state.errors.category && <span className="text-danger pl-2">{this.state.errors.category}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 p-0">
                                    <div className="form-group row mb-3">
                                        <label className="col-xl-2 col-lg-2 col-form-label">Title <span className="text-danger">*</span></label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <div className="input-group input-group-lg">
                                                <input type="text" className="form-control form-control-lg" name="title" placeholder="Post Title" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.title} />
                                            </div>
                                            {this.state.errors.title && <span className="text-danger pl-2">{this.state.errors.title}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 p-0">
                                    <div className="form-group row mb-3">
                                        <label className="col-xl-2 col-lg-2 col-form-label col-form-label">Upload File</label>
                                        <div className="col-lg-6 col-md-9 col-sm-12">
                                            <div className="image-input image-input-outline" id="kt_profile_avatar">
                                                {this.state.image_url ?
                                                    <div className="image-input-wrapper" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.state.image_url})`, backgroundPosition: 'center' }}></div>
                                                    :
                                                    this.state.dataLoading ? '....' : this.state.uploadedImage ?
                                                        <div className="image-input-wrapper" style={{ backgroundImage: `url(${Constants.PostFileUrl}${this.state.uploadedImage})`, backgroundPosition: 'center', backgroundSize: 'contain' }}></div>
                                                        :
                                                        <div className="image-input-wrapper" style={{ backgroundImage: 'url(assets/noImage.png)', backgroundPosition: 'center' }}></div>
                                                }
                                                <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                                    <input type="file" name="postFile" accept=".png, .jpg, .jpeg" ref={this.myRef} onChange={(e) => this.onUploadFileChange(e)} />
                                                </label>
                                                {
                                                    this.state.image_url &&

                                                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-toggle="tooltip" title="Cancel Image" onClick={(e) => this._onRemoveImage(e)} style={{ position: 'absolute', right: '-10px', bottom: '-5px' }}>
                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 p-0">
                                    <div className="form-group row mb-3">
                                        <label className="col-xl-2 col-lg-2 col-form-label col-form-label">Description <span className="text-danger">*</span></label>
                                    </div>

                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={this.state.description}
                                        onReady={editor => {

                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            this.setState({ description: data })
                                        }}
                                        onBlur={(event, editor) => {

                                        }}
                                        onFocus={(event, editor) => {

                                        }}
                                    />
                                    {this.state.errors.description && <span className="text-danger pl-2">{this.state.errors.description}</span>}
                                </div>
                                <div className="d-flex justify-content-end border-top mt-5 pt-10">
                                    <div>
                                        <button type="button" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" onClick={() => this.onUploadPost()}>{(this.state.id && this.state.id !== '') ? "Edit Post" : "Post"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CreatePost;