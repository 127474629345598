import React, { useState, useEffect } from 'react';

function AutoSuggestion(props) {
    let [suggestions, setSuggestions] = useState(props.suggestions || []);
    let [schema, setSchema] = useState(props.schema || '');
    var title = 'name';
    var value = 'value';

    if (schema?.name) {
        title = schema?.name;
    }

    useEffect(() => {
        setSuggestions(props.suggestions);
    }, [props])

    return (
        <ul className="suggestions">
            {suggestions.map((suggestion, index) => {
                return (
                    <li key={index} onClick={(e) => { props.onOptionClick(e, suggestion) }}>
                        <div>{suggestion[title]}</div>
                    </li>
                );
            })}
        </ul>
    )
}

export default AutoSuggestion