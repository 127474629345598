import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Auth from '../Utilities/Auth';
import Constants from '../Utilities/Constants';
import AllFunction from '../Utilities/Functions';
import Store from './store';
import UserProfileCard from '../Components/UserProfileCard';
import PostCard from '../Components/PostCard';
import BusinessCard from '../Components/BusinessCard';
import MessageAlert from '../Components/MessageAlert';
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            dataLoading: false,
            users: [],
            businesses: [],
            posts: [],
            userId: '',
            chatList: [],
            connectionList: [],
            notificationList: [],
            search: '',
            activeTab: 'users',
            successMsg: '',
            success: false,
            Regsuccess: localStorage.getItem('isRegister') || false
        }
        this.store = Store;        
    }

    componentDidMount = async () => {
        await this.getUserCredintials();
        await this.store.setSubHeader("Dashboard", ["Dashboard"])
        setTimeout(() => {
            this.setState({ Regsuccess: false })
            localStorage.removeItem('isRegister');
        }, 5000);
    }

    // This function get the token and user detail from local storage
    getUserCredintials = async () => {
        const token = await localStorage.getItem('token');
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            const userId = user._id;
            await this.setState({ userId })
        }
        if (!token) {
            Auth.Logout();
        } else {
            await this.setState({ token });
            await this.getChatList();
            await this.getConnectionList();
            await this.getLatestPostList();
            await this.getUserList();
            await this.getBusinessList();
            await this.getNotifications();
        }
    }

    // Get the Notifications
    getNotifications = () => {
        this.setState({ notLoading: true })
        let errors = {};
        fetch(Constants.url + 'notification',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        notLoading: false,
                        notificationList: responseData.notifications
                    });
                } else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, notLoading: false })
                }
            })
            .catch(err => {
                this.setState({ notLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    // Get the connected users ( accepted & pending both )
    getConnectionList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'connection',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        connectionList: responseData.connections
                    });
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    // Get the chat list
    getChatList = () => {
        this.setState({ listLoading: true })
        let errors = {};
        fetch(Constants.url + 'chat/chatList',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        chatList: responseData.ChatList
                    });
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    // Get Latest post list
    getLatestPostList = () => {
        this.setState({ listLoading: true })
        setTimeout(() => {
            window.blockPage()
        }, 500);
        let errors = {};
        let urlpath = `post/latest?page=1&limit=6`;
        fetch(Constants.url + urlpath,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        posts: responseData.posts
                    });
                    setTimeout(() => {
                        window.loadPopover()
                        window.unblockPage()
                    }, 500);
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    // Get Users that are not connected
    getUserList = () => {
        this.setState({ dataLoading: true })

        let errors = {};
        fetch(Constants.url + '/dashboardUser?page=1&limit=8',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        users: responseData.users,
                        dataLoading: false,
                    });
                    setTimeout(() => {
                        window.loadPopover()
                    }, 300);
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);                    
                } else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, dataLoading: false })
                }
            })
            .catch(err => {
                this.setState({ dataLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    // Get the Lasted business list
    getBusinessList = async () => {
        this.setState({ listLoading: true })

        let errors = {};
        fetch(Constants.url + '/business?latest=true&page=1&limit=8',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },

            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.setState({
                        listLoading: false,
                        businesses: responseData.Businesses
                    });
                    setTimeout(() => {
                        window.loadPopover()
                    }, 300);
                } else if(!responseData.status && 'auth' in responseData){
                    Auth.Logout();
                } 
                else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, listLoading: false })
                }
            })
            .catch(err => {
                this.setState({ listLoading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
            });
    }

    /**
     * 
     * @param {*} conectUserId 
     * @description This function send the connection request to selected user
     */
    sendConnectionRequest = (conectUserId) => {
        this.setState({ loading: true })
        setTimeout(() => {
            window.blockPage()
        }, 300);

        let errors = {};
        let body = JSON.stringify({
            conectUserId: conectUserId,
        });

        fetch(Constants.url + 'connect',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token
                },
                body: body
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    this.getConnectionList();
                    // this.getUserList();
                    this.setState({ loading: false, success: true, successMsg: 'Request sent successfully.' })
                } else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                }
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            })
            .catch(err => {
                this.setState({ loading: false })
                errors.error = "Something went wrong, Please try again after sometime.";
                this.setState({ errors })
                console.log(err);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
    }

    onChangeTab = async (tab) => {
        await this.setState({ activeTab: tab })
    }

    render() {
        return (
            <>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-custom mb-7">

                                    {
                                        this.state.Regsuccess &&
                                        <div className="col-md-12 pt-4"><MessageAlert children="Thank you for Registration." variant="success" /></div>
                                    }
                                    <div className="card-body rounded bg-light">
                                        <ul className="nav nav-tabs nav-tabs-line nav-bold nav-tabs-line-2x mb-0">
                                            <li className="nav-item">
                                                <a className={`nav-link ${this.state.activeTab === 'users' ? 'active' : ''}`} data-toggle="tab" href="#kt_tab_pane_1" onClick={() => { this.onChangeTab('users') }}>
                                                    <span className="nav-icon">
                                                        <i className="flaticon-users" style={{ fontSize: '1.5rem' }}></i>
                                                    </span>
                                                    <span className="nav-text" style={{ fontSize: '1.2rem', fontWeight: 700 }}>Users and Business</span>
                                                </a>
                                            </li>
                                            {/* <li className="nav-item">
                                                <a className={`nav-link ${this.state.activeTab === 'JobArticles' ? 'active' : ''}`} data-toggle="tab" href="#kt_tab_pane_2" onClick={() => { this.onChangeTab('JobArticles') }}>
                                                    <span className="nav-icon">
                                                        <i className="flaticon-background"></i>
                                                    </span>
                                                    <span className="nav-text" style={{ fontSize: '1.2rem', fontWeight: 700 }}>Job articles</span>
                                                </a>
                                            </li> */}
                                            <li className="nav-item">
                                                <a className={`nav-link ${this.state.activeTab === 'NewsAndPosts' ? 'active' : ''}`} data-toggle="tab" href="#kt_tab_pane_2" onClick={() => { this.onChangeTab('NewsAndPosts') }}>
                                                    <span className="nav-icon">
                                                        <i className="flaticon2-crisp-icons" style={{ fontSize: '1.5rem' }}></i>
                                                    </span>
                                                    <span className="nav-text" style={{ fontSize: '1.2rem', fontWeight: 700 }}>News & Posts</span>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="d-flex flex-column flex-lg-row-auto w-auto w-lg-550px w-xl-650px w-xxl-700px p-10 p-md-15">
                                            <h1 className="font-weight-bolder text-dark mb-8">Search</h1>
                                            {/* <div className="font-size-h4 mb-8">Get users,posts and business</div> */}
                                            <form className="d-flex flex-center py-2 px-6 bg-white rounded">
                                                <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                            <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <input type="text" className="form-control border-0 font-weight-bold pl-2" placeholder={this.state.activeTab == "users" ? "Search by people and business" : this.state.activeTab == "JobArticles" ? "Search job openings" : "Search news and posts"} name='search' onChange={(event) => AllFunction.onChange(event, this)} value={this.state.search} />
                                                <Link to={'/Search?search=' + this.state.search + '&tab=' + this.state.activeTab} className="btn btn-light-primary px-6 font-weight-bold">Search</Link>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card card-custom card-stretch gutter-b" style={{ paddingBottom: '10px' }}>
                                    <div className="card-header border-0">
                                        <h3 className="card-title font-weight-bolder text-dark">Notifications</h3>
                                        {this.state.notificationList.length > 0 && <div className="d-flex justify-content-between align-items-center">
                                            <Link to={'/Notifications'} className="btn btn-light-primary btn-sm font-weight-bolder">View All</Link>
                                        </div>}
                                    </div>
                                    <div className="card-body pt-0" style={{ maxHeight: 270, overflowY: 'scroll' }}>
                                        {
                                            this.state.notificationList.length > 0 ?
                                                this.state.notificationList.map((notification, index) => {
                                                    let time = AllFunction.dateDifference(notification.date);
                                                    return (
                                                        <div className="mb-2" key={index}>
                                                            <div className="d-flex align-items-center flex-grow-1">
                                                                {/* <label className="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
                                                                    <input type="checkbox" value="1" />
                                                                    <span></span>
                                                                </label> */}
                                                                <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                                                                    <div className="d-flex flex-column align-items-cente py-2 w-100">
                                                                        <div className="text-dark-75 font-weight-bold font-size-lg mb-1">{notification.text} </div>
                                                                        <span className="text-muted font-weight-bold text-right">{time}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : ''
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-xl-6">

                                        <div className="card card-custom bgi-no-repeat gutter-b" style={{ height: 175, backgroundColor: '#663259', backgroundPosition: 'calc(100% + 0.5rem) 100%', backgroundSize: '100% auto', backgroundImage: 'url(assets/media/svg/patterns/taieri.svg)' }}>

                                            <div className="card-body d-flex align-items-center">
                                                <div>
                                                    <h3 className="text-white font-weight-bolder line-height-lg mb-5">Create Post
                                                        <br />&nbsp;</h3>
                                                    <Link to={'/CreatePost'} className="btn btn-success font-weight-bold px-6 py-3">Create Post</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="card card-custom bg-primary gutter-b" style={{ height: 150 }}>
                                                    <div className="card-body">
                                                        <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                                    <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                                                    <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"></path>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3">&nbsp;</div>
                                                        <Link to={'MyNetwork'} className="text-inverse-primary font-weight-bold font-size-lg mt-1">Connect</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="card card-custom gutter-b" style={{ height: 150 }}>
                                                    <div className="card-body">
                                                        <span className="svg-icon svg-icon-3x svg-icon-success">
                                                            <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <polygon fill="#000000" opacity="0.3" points="5 15 3 21.5 9.5 19.5" />
                                                                    <path d="M13.5,21 C8.25329488,21 4,16.7467051 4,11.5 C4,6.25329488 8.25329488,2 13.5,2 C18.7467051,2 23,6.25329488 23,11.5 C23,16.7467051 18.7467051,21 13.5,21 Z M9,8 C8.44771525,8 8,8.44771525 8,9 C8,9.55228475 8.44771525,10 9,10 L18,10 C18.5522847,10 19,9.55228475 19,9 C19,8.44771525 18.5522847,8 18,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 C8,13.5522847 8.44771525,14 9,14 L14,14 C14.5522847,14 15,13.5522847 15,13 C15,12.4477153 14.5522847,12 14,12 L9,12 Z" fill="#000000" />
                                                                </g>
                                                            </svg></span>
                                                        </span>
                                                        <div className="text-dark font-weight-bolder font-size-h2 mt-3">&nbsp;</div>
                                                        <Link to={'Chat'} className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">Communicate</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b card-stretch" style={{ backgroundImage: 'url(assets/DashboardImage.jpg)' }}>
                                            <div className="card-body d-flex flex-column align-items-start justify-content-start">
                                                {/* <div className="p-1 flex-grow-1">
                                                    <h3 className="text-white font-weight-bolder line-height-lg mb-5">Create Reports
                                                        <br />With App</h3>
                                                </div> */}
                                                {/* <a href="#" className="btn btn-link btn-link-warning font-weight-bold">Create Report
                                                    <span className="svg-icon svg-icon-lg svg-icon-warning">
                                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                            </g>
                                                        </svg>
                                                    </span></a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {/* Users */}
                                {this.state.dataLoading ? '' : this.state.users.length > 0 && <div className="d-flex justify-content-between align-items-center mb-7">
                                    <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Grow Your Network</h2>
                                    <Link to={'/Search?tab=users&view=all'} className="btn btn-light-primary btn-sm font-weight-bolder">View All</Link>
                                </div>}
                                <div>
                                    {
                                        this.state.success &&
                                        <MessageAlert children={this.state.successMsg} variant="success" />
                                    }
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        {
                                            this.state.dataLoading ? '' :
                                                <UserProfileCard userList={this.state.users} connectionList={this.state.connectionList} onConnectUser={(id) => { this.sendConnectionRequest(id) }} chatList={this.state.chatList} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {/* Business */}
                                {this.state.businesses.length > 0 && <div className="d-flex justify-content-between align-items-center mb-8 mt-8">
                                    <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Business</h2>
                                    <Link to={'/Search?tab=business&view=all'} className="btn btn-light-primary btn-sm font-weight-bolder">View All</Link>
                                </div>}
                                <div className="row">
                                    <BusinessCard businessList={this.state.businesses} userId={this.state.userId} chatList={this.state.chatList} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.posts.length > 0 && <div className="d-flex justify-content-between align-items-center mb-8 mt-8">
                                    <h2 className="font-weight-bolder text-dark font-size-h3 mb-0">Posts</h2>
                                    <Link to={'/Posts?tab=posts&view=all'} className="btn btn-light-primary btn-sm font-weight-bolder">View All</Link>
                                </div>}
                                <div className="row">
                                    {
                                        this.state.dataLoading ? '' :
                                            <PostCard postList={this.state.posts} userId={this.state.userId} chatList={this.state.chatList} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default Dashboard;