import React, { Component } from 'react';
import AllFunction from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import Axios from 'axios';
import MessageAlert from '../Components/MessageAlert';
import { Link } from 'react-router-dom';
import Store from './store';
class Support extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            loading: false,
            errors: {},
            name: '',
            title: '',
            email: '',
            message: '',
            contactNo: '',
            token: ''

        }
        this.store = Store;
    }

    componentDidMount = async () => {
        console.log("Support");
        await this.store.setSubHeader("Support", ["Support", "Support"])
        const token = await localStorage.getItem('token');
        if (token) {
            await this.setState({ token });
        }
    }

    contactValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.name || this.state.name.trim() === '') {
            formIsValid = false;
            errors.name = "Please enter name.";
        }

        if (!this.state.title || this.state.title.trim() === '') {
            formIsValid = false;
            errors.title = "Please enter title.";
        }

        if (this.state.email.length != 0) {
            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
                errors.email = 'Please enter valid email.';
                formIsValid = false;
            }
        } else if (!this.state.email || this.state.email.trim().length == 0) {
            errors.email = 'Please enter email.';
            formIsValid = false;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    onSendContactMail = () => {
        if (this.contactValidation()) {
            this.setState({ loading: true })
            setTimeout(() => {
                window.blockPage()
            }, 300);

            const body = JSON.stringify({
                name: this.state.name,
                title: this.state.title,
                contactNo: this.state.contactNo,
                email: this.state.email,
                message: this.state.message
            })

            let errors = {};
            let method = 'POST';
            let successMsg = 'Contact Created Successfully.';

            Axios({
                method: method,
                url: Constants.url + 'sendContactMail',
                data: body,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                let responseData = response.data;
                // console.log("Add Business", responseData)
                if (responseData.status) {
                    this.setState({ loading: false, success: true, successMsg: successMsg });
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);

                } else {
                    errors.error = responseData.error;
                    this.setState({ errors: errors, loading: false })
                    setTimeout(() => {
                        window.unblockPage()
                    }, 300);
                }
            }).catch((error) => {
                this.setState({ loading: false })
                console.log(error);
                setTimeout(() => {
                    window.unblockPage()
                }, 300);
            });
        }
    }

    render() {
        return (
            <>
                {
                    !this.state.token ??
                    <>
                        <div id="kt_header_mobile" className="header-mobile header-mobile-fixed" style={{ backgroundColor: '#07a398' }}>
                            {/* <!--begin::Logo--> */}
                            <Link to={'/'}>
                                <img alt="Logo" src="/assets/logo/Logo-white.png" className="max-h-25px" />
                            </Link>

                            {/* <!--end::Logo--> */}
                            {/* <!--begin::Toolbar--> */}
                            <div className="d-flex align-items-center">
                                <button className="btn p-0 burger-icon burger-icon-left ml-4" id="kt_header_mobile_toggle">
                                    <span></span>
                                </button>
                                <button className="btn p-0 ml-2" id="kt_header_mobile_topbar_toggle">
                                </button>
                            </div>
                            {/* <!--end::Toolbar--> */}
                        </div>
                    </>
                }

                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-row flex-column-fluid page">
                        <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                            {
                                !this.state.token &&
                                <div id="kt_header" className="header header-fixed">
                                    <div className="container d-flex align-items-stretch justify-content-between">
                                        <div className="d-flex align-items-stretch mr-3">
                                            <div className="header-logo">
                                                <Link to={'/'}>
                                                    <img alt="Logo" src="/assets/logo/Logo-white.png" className="logo-default max-h-40px" />
                                                    <img alt="Logo" src="/assets/logo/Logo.png" className="logo-sticky max-h-30px" />
                                                    {/* <ReactSVG src={"assets/logo/Logo.svg"} className="logo-sticky max-h-40px" /> */}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="content d-flex flex-column flex-column-fluid mb-20" id="kt_content">
                                {
                                    !this.state.token &&
                                    <div className="subheader py-2 py-lg-7 subheader-transparent" id="kt_subheader">
                                        <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                            <div className="d-flex align-items-center flex-wrap mr-1">
                                                <div className="d-flex flex-column">
                                                    <h2 className="text-white font-weight-bold my-2 mr-5">Support</h2>
                                                    <div className="d-flex align-items-center font-weight-bold my-2">
                                                        <a href="#" className="opacity-75 hover-opacity-100">
                                                            <i className="flaticon2-shelter text-white icon-1x"></i>
                                                        </a>
                                                        <span className="label label-dot label-sm bg-white opacity-75 mx-3"></span>
                                                        <span>
                                                            <a href="" className="text-white text-hover-white opacity-75 hover-opacity-100">Support</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="d-flex flex-column-fluid">
                                    <div className="container">
                                        <div className="card card-custom gutter-b">
                                            <div className="card-header d-block">
                                                <h3 className="card-title">Contact Details</h3>
                                                <p>Welcome! To get the quickest help, fill the details in this form. we will contact you.</p>
                                            </div>
                                            <div className="card-body">
                                                <div className="col-md-12">
                                                    {
                                                        this.state.success &&
                                                        <MessageAlert children={this.state.successMsg} variant="success" />
                                                    }
                                                    {this.state.errors.error &&
                                                        <MessageAlert children={this.state.errors.error} variant="danger" />
                                                    }
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-lg-6">
                                                        <label>Name <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control form-control-lg" name="name" placeholder="Enter Name" onChange={(event) => AllFunction.onChange(event, this, "String")} value={this.state.name} />
                                                        {this.state.errors.name && <span className="form-text text-danger pl-2">{this.state.errors.name}</span>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>Email <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control form-control-lg" name="email" placeholder="Enter Email" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.email} />
                                                        {this.state.errors.name && <span className="form-text text-danger pl-2">{this.state.errors.email}</span>}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-lg-6">
                                                        <label>Contact No </label>
                                                        <input type="text" className="form-control form-control-lg" name="contactNo" placeholder="Enter Contact No" onChange={(event) => AllFunction.onChange(event, this, "Number")} value={this.state.contactNo} />
                                                        {this.state.errors.contactNo && <span className="form-text text-danger pl-2">{this.state.errors.contactNo}</span>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>Title <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control form-control-lg" name="title" placeholder="Enter Title" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.title} />
                                                        {this.state.errors.title && <span className="form-text text-danger pl-2">{this.state.errors.title}</span>}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-lg-12">
                                                        <label>Message <span className="text-danger">*</span></label>
                                                        <textarea rows={7} className="form-control form-control-lg" name="message" placeholder="Enter Message" onChange={(event) => AllFunction.onChange(event, this)} value={this.state.message} />
                                                        {this.state.errors.message && <span className="form-text text-danger pl-2">{this.state.errors.message}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        {
                                                            this.state.loading ?
                                                                <button type="button" className="btn btn-primary mr-2 spinner spinner-white spinner-right">Save</button>

                                                                :
                                                                <button type="button" className="btn btn-primary mr-2" onClick={() => { this.onSendContactMail() }}>Submit</button>
                                                        }
                                                        <button type="reset" className="btn btn-secondary" onClick={() => { window.location = '/' }}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Support;